import { Link } from "react-router-dom";

export default function UnstyledLink(props) {
  const { to, children } = props;
  return (
    <Link to={to} style={{ textDecoration: "none", color: "inherit" }}>
      {children}
    </Link>
  );
}
