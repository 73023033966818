import { useQuery } from "@tanstack/react-query";
import useAxiosConfig from "../Admin/Shared/Helpers/axios/useAxiosConfig";
export default function useCustomQuery({
  url,
  key,
  page,
  method = "get",
  lang,
  onSuccess = () => {
    return;
  },
  data = {},
  ...options
}) {
  const axios = useAxiosConfig(lang);
  return useQuery({
    queryKey: page ? [key, page] : [key],
    queryFn: () => axios[method](url, data),
    select: (data) => data?.data,
    ...options,
  });
}
