import { COLORS } from "@/constants/Colors/colors";
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  Typography,
  Checkbox,
} from "@mui/material";
import useCustomMutation from "@/hooks/useCustomMutation";
import Modal from "@shared/Modal/Modal";
import { notifySuccess } from "@shared/Toast/toast";
import { useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { HashLoader } from "react-spinners";
import { useSelector } from "react-redux";

export const AddModule = ({ price }) => {
  const language = useSelector((state) => state?.global);
  const globalState = useSelector((state) => state?.global);
  const lang = language?.lang;
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [module, setModule] = useState({
    name: "",
    overview: "",
    releaseLater: false,
    releaseLaterDate: "",
    price: 0,
  });
  const queryClient = useQueryClient();
  const { mutate, isPending } = useCustomMutation({
    url: `module/create`,
    lang,
    onSuccess: (data) => {
      notifySuccess(lang == "ar" ? "تم إضافة الحصة بنجاح" : "Lesson created");
      setOpen(false);
      queryClient.invalidateQueries(["course modules"]);
      setModule({
        name: "",
        overview: "",
        releaseLater: false,
        releaseLaterDate: "",
        price: 0,
      });
    },
  });
  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = `${(now.getMonth() + 1).toString().padStart(2, "0")}`;
    const day = `${now.getDate().toString().padStart(2, "0")}`;
    const hours = `${now.getHours().toString().padStart(2, "0")}`;
    const minutes = `${now.getMinutes().toString().padStart(2, "0")}`;
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };
  const submit = () => {
    let moduleData = module;
    if (!moduleData.name) return;
    if (!moduleData.overview) return;
    if (moduleData.price > 10 && +price <= 50) return;
    if (module.price <= 10 && module.price != 0 && +price > 50) return;
    if (moduleData.releaseLater) {
      if (!moduleData.releaseLaterDate) return;
      else {
        moduleData.releaseLaterDate = dayjs(moduleData.releaseLaterDate).format(
          "YYYY-MM-DD hh:mm:ss A"
        );
      }
    } else {
      delete moduleData.releaseLaterDate;
    }
    mutate({
      ...moduleData,
      course_id: id,
      isAvailableFile: "0",
    });
  };
  return (
    <>
      <Box sx={{ width: "100%", marginBottom: 5 }}>
        <Button
          onClick={() => setOpen(true)}
          sx={{
            bgcolor: COLORS.PRIMARY.MAIN,
            color: COLORS.WHITE,
            "&:hover": { bgcolor: COLORS.WHITE, color: COLORS.PRIMARY.MAIN },
          }}
        >
          {lang == "ar" ? " اضف حصة" : "Add Lesson"}
        </Button>
      </Box>
      <Modal maxWidth={"sm"} open={open} closeFn={() => setOpen(false)}>
        <Typography fontSize={30} fontFamily={"Noor-bold"} marginBottom={5}>
          {lang == "ar" ? "إضافة حصة للكورس" : "Add lesson for course"}
        </Typography>
        <Box
          sx={{
            display: "flex",
            width: "70%",
            flexDirection: "column",
            gap: 2,
            marginTop: 2,
          }}
        >
          <TextField
            label={lang == "ar" ? "إسم الحصة" : "Lesson name"}
            fullWidth
            type="text"
            value={module.name}
            onChange={(e) => {
              setModule({ ...module, name: e.target.value });
            }}
            error={!module.name && submitted}
            helperText={!module.name && submitted && "يجب إضافة إسم للحصة"}
            sx={{
              "& .MuiFormLabel-root.Mui-focused": {
                color: "#2f8bc8",
              },
              "& label": {
                left: lang == "ar" ? "unset" : "0",
                right: lang == "ar" ? "1.75rem" : "unset",
                transformOrigin: lang == "ar" ? "right" : "left",
                fontSize: "0.8rem",
                color: globalState?.isDarkMode
                  ? COLORS?.WHITE
                  : COLORS?.DARK_GRAY,
                bgcolor: globalState?.isDarkMode
                  ? COLORS?.SOFT_DARK
                  : COLORS?.WHITE,
              },
              "& legend": {
                textAlign: lang == "ar" ? "right" : "left",
                fontSize: "0.6rem",
              },
              direction: "rtl",
            }}
            InputProps={{
              startAdornment: <InputAdornment position="end"></InputAdornment>,
            }}
          />
          <TextField
            label={lang == "ar" ? "وصف الحصة" : "Lesson overview"}
            fullWidth
            type="text"
            value={module.overview}
            onChange={(e) => {
              setModule({ ...module, overview: e.target.value });
            }}
            error={!module.overview && submitted}
            helperText={!module.overview && submitted && "يجب إضافة وصف للحصة"}
            sx={{
              "& .MuiFormLabel-root.Mui-focused": {
                color: "#2f8bc8",
              },
              "& label": {
                left: lang == "ar" ? "unset" : "0",
                right: lang == "ar" ? "1.75rem" : "unset",
                transformOrigin: lang == "ar" ? "right" : "left",
                fontSize: "0.8rem",
                color: globalState?.isDarkMode
                  ? COLORS?.WHITE
                  : COLORS?.DARK_GRAY,
                bgcolor: globalState?.isDarkMode
                  ? COLORS?.SOFT_DARK
                  : COLORS?.WHITE,
              },
              "& legend": {
                textAlign: lang == "ar" ? "right" : "left",
                fontSize: "0.6rem",
              },
              direction: "rtl",
            }}
            InputProps={{
              startAdornment: <InputAdornment position="end"></InputAdornment>,
            }}
          />
          {price > 0 && (
            <TextField
              label={lang == "ar" ? "السعر" : "Price"}
              fullWidth
              type="number"
              value={module.price}
              onChange={(e) => {
                if (e.target.value >= 0) {
                  setModule({ ...module, price: e.target.value });
                }
              }}
              error={
                (module.price <= 10 &&
                  module.price != 0 &&
                  +price > 50 &&
                  submitted) ||
                (module.price > 10 && +price <= 50 && submitted)
              }
              helperText={(() => {
                if (
                  module.price <= 10 &&
                  module.price != 0 &&
                  +price > 50 &&
                  submitted
                )
                  return "يجب اختيار سعر اكثر من 10 جنيهات";
                if (module.price > 10 && +price <= 50 && submitted)
                  return "يجب اختيار سعر علي الاقل 10 جنيهات";
              })()}
              sx={{
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "#2f8bc8",
                },
                "& label": {
                  left: lang == "ar" ? "unset" : "0",
                  right: lang == "ar" ? "1.75rem" : "unset",
                  transformOrigin: lang == "ar" ? "right" : "left",
                  fontSize: "0.8rem",
                  color: globalState?.isDarkMode
                    ? COLORS?.WHITE
                    : COLORS?.DARK_GRAY,
                  bgcolor: globalState?.isDarkMode
                    ? COLORS?.SOFT_DARK
                    : COLORS?.WHITE,
                },
                "& legend": {
                  textAlign: lang == "ar" ? "right" : "left",
                  fontSize: "0.6rem",
                },
                direction: "rtl",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end"></InputAdornment>
                ),
              }}
            />
          )}
          <Box sx={{ display: "flex", alignItems: "center", gap: 1.55 }}>
            <Checkbox
              checked={module?.releaseLater}
              onChange={() =>
                setModule({ ...module, releaseLater: !module.releaseLater })
              }
              id={`pub-later-${id}`}
            />
            <label style={{ cursor: "pointer" }} htmlFor={`pub-later-${id}`}>
              {lang == "ar" ? "نشر لاحقا" : "Release later"}
            </label>
            <TextField
              disabled={!module.releaseLater}
              inputProps={{ min: getCurrentDateTime() }}
              type="datetime-local"
              label={lang == "ar" ? "تاريخ النشر" : "Release date"}
              sx={{
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "#2f8bc8",
                },
                "& label": {
                  left: lang == "ar" ? "unset" : "0",
                  right: lang == "ar" ? "1.75rem" : "unset",
                  transformOrigin: lang == "ar" ? "right" : "left",
                  fontSize: "0.8rem",
                  color: globalState?.isDarkMode
                    ? COLORS?.WHITE
                    : COLORS?.DARK_GRAY,
                  bgcolor: globalState?.isDarkMode
                    ? COLORS?.SOFT_DARK
                    : COLORS?.WHITE,
                },
                "& legend": {
                  textAlign: lang == "ar" ? "right" : "left",
                  fontSize: "0.6rem",
                },
                direction: "rtl",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end"></InputAdornment>
                ),
              }}
              error={
                !module.releaseLaterDate && submitted && module.releaseLater
              }
              helperText={(() => {
                if (
                  !module.releaseLaterDate &&
                  submitted &&
                  module.releaseLater
                ) {
                  return lang == "ar"
                    ? "يجب إضافة تاريخ النشر"
                    : "you should add release date";
                } else {
                  return;
                }
              })()}
              value={module.releaseLaterDate}
              onChange={(e) => {
                setModule({ ...module, releaseLaterDate: e.target.value });
              }}
            />
          </Box>
          <Button
            disabled={isPending}
            sx={{
              bgcolor: COLORS.PRIMARY.MAIN,
              color: COLORS.WHITE,
              "&:hover": { bgcolor: COLORS.PRIMARY.MAIN },
            }}
            onClick={() => {
              setSubmitted(true);
              submit();
            }}
          >
            {isPending ? (
              <HashLoader size={20} color={COLORS.WHITE} />
            ) : lang == "ar" ? (
              "أنشاء"
            ) : (
              "Create"
            )}
          </Button>
        </Box>
      </Modal>
    </>
  );
};
