import { COLORS } from "@/constants/Colors/colors";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

export default function Header({ text, sx }) {
  return (
    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
      <Typography
        sx={{
          fontFamily: "Noor-bold",
          fontSize: "45px",
          color: "white",
          backgroundColor: `${COLORS.PRIMARY.MAIN}`,
          textAlign: "center",
          // boxShadow: `0px 6px 0px white, 0px 12px 0px #2f8bc8`,
          ...sx,
        }}
        // width={300}
        px={6}
        py={1}
        mx={"auto"}
        borderRadius={2}
      >
        {text}
      </Typography>
    </Box>
  );
}
Header.propTypes = {
  text: PropTypes.string,
  parent: PropTypes.object,
};
