import { z } from "zod";

export const bookReservationScheme = z.object({
    name: z.string().min(1,'هذا الحقل مطلوب').refine((value) => value.split(' ').length === 4, {
      message: 'يجب كتابة الاسم رباعي'
    }),
  
    phone: z.string().min(1,'هذا الحقل مطلوب').refine((value) => /^\d{11}$/.test(value) && value.startsWith('01'), {
      message: 'يجب أن يحتوي الرقم على 11 رقمًا ويبدأ بـ 01'
    }),
  
    secondary_phone: z.string().min(1,'هذا الحقل مطلوب').refine((value) => /^\d{11}$/.test(value) && value.startsWith('01'), {
      message: 'يجب أن يحتوي الرقم على 11 رقمًا ويبدأ بـ 01'
    }),
  
    // reservation_phone: z.string().min(1,'هذا الحقل مطلوب').refine((value) => /^\d{11}$/.test(value) && value.startsWith('01'), {
    //   message: 'يجب أن يحتوي الرقم على 11 رقمًا ويبدأ بـ 01'
    // }),
  
    city: z.string().min(1, 'هذا الحقل مطلوب'),
  
    address: z.string().min(1, 'هذا الحقل مطلوب'),
    quantity: z.string().min(1, 'هذا الحقل مطلوب'),
    payment_method: z.string().min(1, 'هذا الحقل مطلوب'),
  
    // state: z.string().min(1, 'هذا الحقل مطلوب'),
  
    // station: z.string().min(1, 'هذا الحقل مطلوب'),
  
    // street: z.string().min(1, 'هذا الحقل مطلوب'),
  
    // building_number: z.string().min(1, 'هذا الحقل مطلوب'),
  
    reservation_date: z.string().refine((value) => new Date(value) < new Date(), {
      message: 'تاريخ الحجز يجب أن يكون في الماضي'
    }),
  
    // reservation_image: z.any(),  
    // quantity: z.number('هذا الحقل مطلوب').refine((value) => value >= 1 ,{
    //   message:"يجب ان تكون الكمية علي الأقل 1"
    // })
  });
