import Modal from "@shared/Modal/Modal";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { TextField, InputAdornment, Box } from "@mui/material";
import { COLORS } from "@/constants/Colors/colors";
import { Search } from "@mui/icons-material";
import Select from "@mui/material/Select";
import LoadingButton from "@mui/lab/LoadingButton";
import MenuItem from "@mui/material/MenuItem";
import { useMutate } from "@/hooks/useMutate";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from 'react-redux';

export const AddStudent = ({ open, setOpen, closeParent, refetch }) => {
  const lang = useSelector((state) => state?.global?.lang);
  const { handleSubmit, register } = useForm({
    defaultValues: {
      days: null,
    },
  });
  const [searchData, setSearchData] = useState({
    phoneQuery: "",
    page: 1,
  });
  const [loading, setLoading] = useState({
    search: false,
  });
  const [users, setUsers] = useState(null);

  const [userId, setUserId] = useState("");
  const { mutateAsync, isPending } = useMutate();

  const { id } = useParams();

  const onSubmit = (data) => {
    mutateAsync({
      url: `subscription/AdminSubscription`,
      body: { ...data, course_id: id, user_id: userId },
      method: "POST",
      lang,
    })
      .then(() => {
        closeParent();
        setOpen(false);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => refetch());
  };

  return (
    <Modal open={open} closeFn={() => setOpen(false)} zIndex={1}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setLoading((prev) => ({ ...prev, search: true }));
          mutateAsync({
            url: `users/search?phone=${searchData?.phoneQuery}&page=${searchData?.page}`,
            method: "get",
          })
            .then((res) => {
              setUsers(res?.data?.data);
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              setLoading((prev) => ({ ...prev, search: false }));
            });
        }}
        style={{
          marginTop: 5,
          width: "60%",
          display: "flex",
          flexDirection: "column",
          gap: 10,
        }}
      >
        <TextField
          type="text"
          label={lang == "ar" ? "رقم الطالب" : "Student number"}
          value={searchData?.phoneQuery}
          onChange={(e) =>
            setSearchData((prev) => {
              return { ...prev, phoneQuery: e.target.value };
            })
          }
          // error={!exam.title && submitted}
          // helperText={!exam.title && submitted && "يجب إضافة عنوان للإمتحان"}
          sx={{
            "& .MuiFormLabel-root.Mui-focused": {
              color: "#2f8bc8",
            },
            "& label": {
              left: lang == "ar" ? "unset" : "0",
              right: lang == "ar" ? "1.75rem" : "unset",
              transformOrigin: lang == "ar" ? "right" : "left",
              fontSize: "0.8rem",
              color: "#2f8bc8",
              bgcolor: COLORS.DARK_GRAY,
            },
            "& legend": {
              textAlign: lang == "ar" ? "right" : "left",
              fontSize: "0.6rem",
            },
            direction: "rtl",
          }}
          InputProps={{
            startAdornment: <InputAdornment position="end"></InputAdornment>,
          }}
        />
        <LoadingButton
          sx={{
            bgcolor: COLORS.PRIMARY.MAIN,
            width: "80px",
            color: COLORS.WHITE,
            "&:hover": {
              bgcolor: COLORS.PRIMARY.MAIN,
            },
            "&:disabled": {
              bgcolor: COLORS.SECONDARY.MAIN,
              color: COLORS.WHITE,
            },
          }}
          loading={loading?.search}
          // startIcon={<Search />}
          type="submit"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              opacity: loading?.search ? 0 : 1,
            }}
          >
            <Search />
          </Box>
        </LoadingButton>
      </form>
      {users === null ? null : !users?.length ? (
        <p>
          {lang == "ar"
            ? "لا يوجد طالب بهذا الرقم!"
            : "There is no student with this number!"}
        </p>
      ) : (
        <form
          style={{
            marginTop: 20,
            width: "60%",
            display: "flex",
            flexDirection: "column",
            gap: 10,
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            type="number"
            label={lang == "ar" ? "عدد الايام" : "The number of days"}
            {...register("days")}
            // error={!exam.title && submitted}
            // helperText={!exam.title && submitted && "يجب إضافة عنوان للإمتحان"}
            sx={{
              "& .MuiFormLabel-root.Mui-focused": {
                color: "#2f8bc8",
              },
              "& label": {
                left: lang == "ar" ? "unset" : "0",
                right: lang == "ar" ? "1.75rem" : "unset",
                transformOrigin: lang == "ar" ? "right" : "left",
                fontSize: "0.8rem",
                color: "#2f8bc8",
                bgcolor: COLORS.DARK_GRAY,
              },
              "& legend": {
                textAlign: lang == "ar" ? "right" : "left",
                fontSize: "0.6rem",
              },
              direction: "rtl",
            }}
            InputProps={{
              startAdornment: <InputAdornment position="end"></InputAdornment>,
            }}
          />

          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={userId}
            label="name"
            sx={{
              position: "relative",
              zIndex: 3,
              "& .MuiFormLabel-root.Mui-focused": {
                color: "#2f8bc8",
              },
              "& label": {
                left: "unset",
                right: "1.75rem",
                transformOrigin: "right",
                fontSize: "0.8rem",
                color: "#2f8bc8",
              },
              "& legend": {
                textAlign: "right",
                fontSize: "0.6rem",
              },
              direction: "rtl",
            }}
            onChange={(e) => setUserId(e.target.value)}
          >
            {users?.map((user) => (
              <MenuItem key={user?.id} value={user?.id}>
                {user?.name}
              </MenuItem>
            ))}
          </Select>
          <LoadingButton
            sx={{
              mt: 5,
              bgcolor: COLORS.PRIMARY.MAIN,
              color: COLORS.WHITE,
              "&:hover": { bgcolor: COLORS.PRIMARY.MAIN },
            }}
            type="submit"
            loading={isPending}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                opacity: isPending ? 0 : 1,
              }}
            >
              {lang == "ar" ? "اشتراك" : "subscription"}
            </Box>
          </LoadingButton>
        </form>
      )}
    </Modal>
  );
};

AddStudent.propTypes = {
  open: PropTypes.any,
  setOpen: PropTypes.any,
  closeParent: PropTypes.any,
  refetch: PropTypes.any,
};
