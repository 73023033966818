import useCustomMutation from "@/hooks/useCustomMutation";

export default function useUpdateCourse({ refetch, courseId, onSettled }) {
  const { mutate, isPending } = useCustomMutation({
    url: "course/update",
    invalidate: [["course", courseId], "courses"],
    method: "post",
    successMsg: "تم تعديل الكورس بنجاح",
    onSettled,
    onSuccess: () => {
      refetch();
    },
  });

  function updateCourse(data) {
    const formData = new FormData();
    formData.append("id", courseId);
    Object.entries(data).forEach((key) => {
      if (
        key[0] === "subscription_days" &&
        data.subscription_days === undefined
      ) {
        formData.append("subscription_days", 0);
      } else {
        formData.append(key[0], data[key[0]]);
      }
    });
    if (data.image) {
      formData.set("image", data.image[0]);
    }
    console.log(refetch, "refetch from update");
    mutate(formData);
  }

  return { updateCourse, isPending };
}
