import useCustomMutation from "@/hooks/useCustomMutation";
import { queryClient } from "@config/queryClient.config";
import { notifyError, notifySuccess } from "@shared/Toast/toast";

export default function useDeleteModule({ id, onSettled, lang }) {
  const { mutate: deleteModuleMutation, isPending: isModuleLoading } =
    useCustomMutation({
      url: `module/delete?id=${id}`,
      invalidate: ["course modules", id],
      method: "get",
      onSettled,
      lang,
      onSuccess: () => {
        notifySuccess(
          lang == "ar"
            ? "تم حذف الحصة بنجاح"
            : "The Lesson has been successfully deleted"
        );
        queryClient.invalidateQueries(["course modules"]);
      },
      onError: (error) => {
        notifyError(error?.response?.data?.message);
      },
    });

  function deleteModule() {
    deleteModuleMutation();
  }

  return { deleteModule, isModuleLoading };
}
