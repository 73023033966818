import { Box } from "@mui/material";
import eye from "@assets/adminImages/eye-icon.png";
import useAxiosConfig from "@shared/Helpers/axios/useAxiosConfig";
import useCustomQuery from "@/hooks/useCustomQuery";

export default function ViewCount({ lessonId, enabled }) {
  const instance = useAxiosConfig();
  const { data } = useCustomQuery({
    url: `lesson/views?id=${lessonId?.id}`,
    key: ["views", lessonId],
    enabled: enabled,
    retry: false,
  });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
          color: "#2a2a2a",
          bgcolor: "#39c3db",
          borderRadius: 10,
          px: 1,
          ml: 1,
        }}
      >
        <img
          src={eye}
          style={{
            width: "25px",
          }}
        />
        {data?.data?.views}
      </Box>
    </>
  );
}
