import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

export default function EmptyDiv() {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    width: "20%",
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <>
      <Item sx={{ marginX: "10px", height: "86vh" }}></Item>
    </>
  );
}
