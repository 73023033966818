import { atom } from "recoil";

export const courseTypeAtom = atom({
  key: "courseType",
  default: "",
});

export const activeCodeAtom = atom({
  key: "activeCode",
  default: null,
});
