import CloseIcon from "@mui/icons-material/Close";
import { COLORS } from "@/constants/Colors/colors";

export default function CloseButton(props) {
  return (
    <CloseIcon
      {...props}
      fontSize="large"
      sx={{ color: COLORS.DARK_GRAY, mt: -1, mr: -1, cursor: "pointer" }}
    />
  );
}
