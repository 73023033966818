import { Box } from "@mui/material";

export default function Column(props) {
  const {
    children,
    spacing = "center",
    align = "center",
    gap = 0,
    sx,
    ...rest
  } = props;

  return (
    <Box
      {...rest}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: align,
        alignItems: spacing,
        gap: gap,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
