import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import CloseIcon from "@mui/icons-material/Close";
import useCustomMutation from "@/hooks/useCustomMutation";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import { useQueryClient } from "@tanstack/react-query";
import { useSelector } from 'react-redux';

export const DeleteCourseFile = ({
  id,
  setIsConfirmOpen,
  isConfirmOpen,
  confirmMessage,
  deleteName,
  filename,
}) => {
  const language = useSelector((state) => state?.global);
  const lang = language?.lang;
  const queryClient = useQueryClient();
  const { mutate, isPending } = useCustomMutation({
    url: `drive/${id}`,
    method: "delete",
    lang,
    onSuccess: () => {
      notifySuccess(
        lang == "ar" ? "تم حذف الملف" : "The file has been deleted"
      );
      queryClient.invalidateQueries(["course files", lang]);
    },
    onError: (err) => {
      console.log(err);
      notifyError(
        lang == "ar"
          ? "حدث خطأ اثناء تنفيذ المهمة"
          : "An error occurred while executing the task"
      );
    },
  });

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      onClose={() => setIsConfirmOpen(false)}
      open={isConfirmOpen}
    >
      <DialogTitle sx={{ borderTop: "#2f8bc8 5px solid" }}>
        <Button onClick={() => setIsConfirmOpen(false)}>
          <CloseIcon />
        </Button>
      </DialogTitle>{" "}
      <DialogContent
        sx={{
          borderBottom: "#2f8bc8 5px solid",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography my={4} fontSize={"24px"}>
          {confirmMessage || ""} {deleteName || ""}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
          <ColorButton
            sx={{
              bgcolor: "#db3a3c",
              transition: "all 0.3s",
            }}
            variant="contained"
            onClick={() => {
              setIsConfirmOpen(false);
              mutate({ type: "Course", id: id, file_name: filename });
            }}
          >
            تأكيد
          </ColorButton>
          <ColorButton
            variant="contained"
            onClick={() => setIsConfirmOpen(false)}
          >
            {" "}
            رجوع
          </ColorButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
