import CourseCard from "../atoms/CourseCard";
import Header from "../atoms/Header";
import { Box, Typography } from "@mui/material";
import { api_url } from "@/constants/base_url";
// import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { ClipLoader } from "react-spinners";
import useCustomQuery from "@/hooks/useCustomQuery";
import { COLORS } from "@/constants/Colors/colors";
import { useSelector } from "react-redux";

export default function TopCourses() {
  const globalState = useSelector((state) => state?.global);
  const lang = useSelector((state) => state?.global?.lang);
  const { data, isLoading } = useCustomQuery({
    url: `${api_url}course/all?page=1`,
    key: ["topCourses", lang],
    lang,
    select: (data) => data,
  });

  const empty = (
    <Typography
      fontFamily="Noor-bold"
      fontSize={30}
      color="black"
      sx={{
        textShadow: `0px 3px 0px ${COLORS.PRIMARY.MAIN}`,
        textAlign: "center",
      }}
    >
      {lang == "ar" ? "لا يوجد كورسات" : "There is no courses"}
    </Typography>
  );

  const spinner = (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {/* <LoadingSpinner /> */}
      <ClipLoader color={COLORS.PRIMARY.MAIN} size={40} />
    </div>
  );

  const content = (
    <Box display={"flex"} justifyContent={"center"} gap={4} px={4}>
      {data?.data?.data
        ?.slice(0, 4)
        .map(({ id, name, price, teacher, isPayByModule, image }, i) => (
          <CourseCard
            width={"350px"}
            key={id}
            img={`${api_url.slice(0, -7)}${image.slice(1)}`}
            name={name}
            price={price}
            type={
              isPayByModule
                ? lang == "ar"
                  ? "بالحصة"
                  : "Lesson"
                : lang == "ar"
                ? "بالكورس"
                : "Course"
            }
            teacher={teacher}
            link={`/login`}
            id={id}
          />
        ))}
    </Box>
  );

  return (
    <>
      <Box
        className={"landing"}
        backgroundColor={globalState?.isDarkMode ? COLORS.Black : "white"}
        id="top-courses"
        py={10}
        px={6}
      >
        <Header
          text={lang == "ar" ? "كورسات متميزة" : "Popular courses"}
          sx={{ mb: "100px", width: "500px" }}
        />
        {isLoading ? spinner : data?.data?.data?.length === 0 ? empty : content}
      </Box>
    </>
  );
}
