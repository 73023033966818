import useAxiosConfig from "@shared/Helpers/axios/useAxiosConfig";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export default function useAddCourseFile({ lang }) {
  const instance = useAxiosConfig(lang);

  function addCourseFile(data) {
    return instance.post("drive", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        lang: lang,
      },
    });
  }
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addCourseFile,
    onSuccess: (res) => {
      console.log("resFile", res);
      queryClient.invalidateQueries(["course files", lang]);
      queryClient.invalidateQueries(["module files", lang]);
      notifySuccess(
        lang == "ar" ? "تم اضافة ملف بنجاح" : "File added successfully"
      );
    },
    onError: (error) => {
      notifyError(error?.response?.data?.message);
      console.log(error);
    },
    onSettled: () => {},
  });
}
