import React, { useState, useEffect } from "react";
import { FormControl, Select, MenuItem, Box } from "@mui/material";
import { styled } from "@mui/system";
import { useDispatch } from "react-redux";
import { setLang } from "@/store/reducers/globalReducer";

export default function LangTuggle() {
  const StyledFormControl = styled(FormControl)(({ theme }) => ({
    margin: theme.spacing(1),
    minWidth: 90,
  }));

  const dispatch = useDispatch();

  const [language, setLanguage] = useState("en");
  useEffect(() => {
    const savedLanguage = localStorage.getItem("selectedLanguage");
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, []);

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);
    dispatch(setLang(selectedLanguage));
  };

  return (
    <>
      <Box
        sx={{
          // color: "text.primary",
          borderRadius: 1,
          fontSize: 15,
        }}
      >
        <StyledFormControl>
          <Select
            value={language}
            onChange={handleLanguageChange}
            displayEmpty
            inputProps={{ "aria-label": "Language" }}
            sx={{ fontSize: "0.8rem" }}
          >
            <MenuItem value="en">English</MenuItem>
            <MenuItem value="ar">Arabic</MenuItem>
          </Select>
        </StyledFormControl>
      </Box>
    </>
  );
}
