import { Box, Typography } from "@mui/material";
import { useLogout } from "./hooks/logout.hook";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import Row from "@shared/Layout/Row/Row";
import Modal from "@shared/Modal/Modal";

function AdminLogout({ adminLogout, closeAdminLogout }) {
  const { logout, isLoading } = useLogout();

  return (
    <Box>
      <Modal maxWidth={"sm"} open={adminLogout} closeFn={closeAdminLogout}>
        <Typography
          fontFamily={"Noor-bold"}
          fontSize={30}
          sx={{ textShadow: "0px 3px 0px #2f8bc8", color: "black" }}
        >
          هل تريد تسجيل الخروج
        </Typography>

        <Row mt={5} gap={1} width={"60%"}>
          <ColorButton
            onClick={() => logout()}
            width={"49%"}
            isLoading={isLoading}
          >
            أوافق
          </ColorButton>
          <ColorButton
            onClick={closeAdminLogout}
            width={"49%"}
            sx={{
              bgcolor: "gray",
            }}
          >
            الغاء
          </ColorButton>
        </Row>
      </Modal>
    </Box>
  );
}

export default AdminLogout;
