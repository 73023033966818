import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAxiosConfig from "@shared/Helpers/axios/useAxiosConfig";
import { notifySuccess, notifyError } from "@shared/Toast/toast";
import { initialSwapState } from "../Admin/Components/swapOrder/utils";

export default function useSwapCourseOrder(setSwapState) {
  const instance = useAxiosConfig();

  function swapOrder({ first_course_id, second_course_id }) {
    return instance.get(
      `course/swaporder?first_course_id=${first_course_id}&second_course_id=${second_course_id}`
    );
  }

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: swapOrder,
    onSuccess: () => {
      notifySuccess("تم تغيير الترتيب بنجاح");
      queryClient.invalidateQueries(["allCourses"]);
    },
    onError: (error) => {
      notifyError(error?.response?.data?.message);
    },
    onSettled: () => {
      setSwapState(initialSwapState);
    },
  });
}
