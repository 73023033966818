import { useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { COLORS } from "@/constants/Colors/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import DeleteDialog from "../../DeleteDialog";
import EditHomeWork from "../Edit/EditHomeWork";
import { useSelector } from "react-redux";

export const ModuleHomework = ({ homework }) => {
  const lang = useSelector((state) => state?.global?.lang);
  const globalState = useSelector((state) => state?.global);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [deleteName, setDeleteName] = useState();
  const [confirmMessage, setConfirmMessage] = useState("");
  const handleDeleteClick = () => {
    setIsConfirmOpen(true);
    setConfirmMessage(
      lang == "ar"
        ? "هل انت متأكد من حذف الواجب"
        : "Are you sure you want to delete the Homework?"
    );
    setDeleteData(homework);
    setDeleteName(homework?.title);
  };
  // console.log(`homework`, homework);
  return (
    <Box
      sx={{
        width: "100%",
        paddingX: 2,
        color: COLORS.WHITE,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <HomeWorkIcon sx={{ color: COLORS.PRIMARY.MAIN }} />
        <Typography color={globalState?.isDarkMode ? COLORS.WHITE : "#777"}>
          {homework?.title?.length > 20
            ? homework?.title?.slice(0, 20) + "..."
            : homework?.title}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", gap: 1 }}>
        <EditHomeWork homeWork={homework} />
        {new Date(homework?.starts_at) - new Date() > 0 && (
          <IconButton
            sx={{
              bgcolor: COLORS.DANGER,
              "&:hover": { bgcolor: COLORS.DANGER },
              width: 28,
              height: 28,
            }}
            onClick={handleDeleteClick}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        )}
        <DeleteDialog
          isConfirmOpen={isConfirmOpen}
          setIsConfirmOpen={setIsConfirmOpen}
          deleteName={deleteName}
          deleteData={deleteData}
          confirmMessage={confirmMessage}
        />
      </Box>
    </Box>
  );
};
