import { zodResolver } from "@hookform/resolvers/zod";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  FormHelperText,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { ClipLoader } from "react-spinners";
import EditIcon from "@mui/icons-material/Edit";
import { COLORS } from "@/constants/Colors/colors";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import { editVideoSchema } from "../../schema/editVideoSchema";
import useUpdateVideoData from "../hook/useUpdateVideoData";
import { useSelector } from "react-redux";

export default function EditVideo({ video }) {
  const [open, setOpen] = useState(false);
  const language = useSelector((state) => state?.global);
  const lang = language?.lang;

  const methods = useForm({
    zodResolver: zodResolver(editVideoSchema),
    defaultValues: {
      name: video?.name || "",
      link: video?.link || "",
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    getValues,
    control,
    setValue,
    setError,
    clearErrors,
  } = methods;

  const { mutate, isLoading } = useUpdateVideoData({
    lang,
    closeModal: () => setOpen(false),
  });

  function submit(data) {
    if (video && getValues(name) === "") {
      setValue("name", video?.name);
    }
    if (!data?.link || data?.link == "") {
      delete data?.link;
    }
    mutate({
      ...data,
      id: video?.id,
    });
  }

  return (
    <>
      <IconButton
        sx={{
          bgcolor: "#01c46e",
          "&:hover": { bgcolor: "#01c46e" },
          width: 28,
          height: 28,
        }}
        onClick={() => setOpen(true)}
      >
        <EditIcon fontSize="small" />
      </IconButton>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle sx={{ borderTop: "#2f8bc8 5px solid" }}>
          <Button onClick={() => setOpen(false)}>
            <CloseIcon />
          </Button>
        </DialogTitle>

        <DialogContent sx={{}}>
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(submit)}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "20px",
                width: "60%",
                margin: "auto",
              }}
            >
              <Typography fontFamily={"Noor-bold"} fontSize={"30px"} mb={2}>
                {lang == "ar" ? "تعديل الفيديو " : "Edit video"}
                {video?.name}
              </Typography>
              <TextField
                registername={"name"}
                {...register("name")}
                placeholder={
                  lang == "ar" ? "ادخل اسم الفيديو" : "Enter video name"
                }
                label={lang == "ar" ? "اسم الفيديو" : "Video name"}
                fullWidth
                sx={{
                  direction: "rtl",
                }}
              />
              <FormHelperText sx={{ color: COLORS.DANGER }}>
                {errors?.name && errors?.name?.message}
              </FormHelperText>
              <TextField
                {...register("link")}
                registername={"link"}
                placeholder={
                  lang == "ar" ? "ادخل لينك يوتيوب " : "Enter YouTube link"
                }
                label={lang == "ar" ? " لينك الفيديو" : "YouTube link"}
                fullWidth
                sx={{
                  direction: "rtl",
                }}
              />
              <FormHelperText sx={{ color: COLORS.DANGER }}>
                {errors?.link && errors?.link?.message}
              </FormHelperText>
              <Box
                sx={{
                  marginTop: "50px",
                  marginBottom: "30px",
                  textAlign: "center",
                }}
              >
                <ColorButton
                  disabled={isLoading}
                  type="submit"
                  variant="contained"
                  sx={{ bgcolor: "#2f8bc8", marginLeft: "20px" }}
                >
                  {isLoading ? (
                    <ClipLoader size={20} color="#000" />
                  ) : lang == "ar" ? (
                    "تعديل "
                  ) : (
                    "Edit"
                  )}
                </ColorButton>
              </Box>
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </>
  );
}
