import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { useDispatch } from "react-redux";
import { setDarkMode } from "@/store/reducers/globalReducer";

const ModeTuggle = ({ p = 1 }) => {
  // ----------- hooks ------------
  const [mode, setMode] = React.useState("light");
  const dispatch = useDispatch();

  //   ----------- functions ------------
  const colorMode = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
    dispatch(setDarkMode(mode === "light" ? false : true));
  };

  return (
    <Box
      sx={{
        // color: "text.primary",
        borderRadius: 1,
        fontSize: 25,
        p: p,
      }}
    >
      <IconButton onClick={colorMode} color="inherit">
        {mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
      {/* {mode === "light" ? "الوضع الليلي" : "الوضع النهاري"} */}
    </Box>
  );
};

export default ModeTuggle;
