import Empty from "@shared/Layout/Empty/Empty";
import { Typography, FormHelperText, Checkbox } from "@mui/material";
import Column from "@shared/Layout/Column/Column";
import Row from "@shared/Layout/Row/Row";
import { useRecoilState } from "recoil";
import { activeCourseAtom } from "@/modules/courses/services/RecoilAtoms/ActiveCourse.atom";
import CourseStateIconSmall from "@shared/Course/CourseStateIconSmall/CourseStateIconSmall";
import student from "@assets/adminImages/student.png";
import course from "@assets/adminImages/course.png";
import getImageUrl from "@shared/Helpers/image/getImageUrl";
import money from "@assets/adminImages/money.png";
import book from "@assets/adminImages/book.png";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import EditButton from "@/components/Buttons/EditButton/EditButton";
import { FONTS } from "@/constants/Fonts/fonts";
import TextButton from "@shared/Buttons/TextButton/TextButton";
import { useState } from "react";
import { FONT_SIZES } from "@/constants/Fonts/font_sizes";
import { COLORS } from "@/constants/Colors/colors";
import Modal from "@shared/Modal/Modal";
import ColorButton from "@/components/Buttons/ColorButton/ColorButton";
import { FormProvider, useForm } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import BorderedInput from "@/components/Form/BorderedInput/BorderedInput";
import emptyImage from "@assets/adminImages/emptyImg.png";
import CloseButton from "@/components/Buttons/CloseButton/CloseButton";
import { dropzoneConfig } from "@/hooks/useDropZoneConfig";
import useAddCourse from "../../../hooks/useAddCourse.hook";
import { addCourseAtom } from "@/modules/courses/services/RecoilAtoms/addCourseAtom";
import useUpdateCourse from "../../../hooks/useUpdateCourse";
import useDeleteCourse from "../../../hooks/useDeleteCourse";
import { zodResolver } from "@hookform/resolvers/zod";
import { updateCourseSchema } from "../../../Admin/Components/Course/schema/updateCourse.schema";
import { createCourseSchema } from "../../../Admin/Components/Course/schema/createCourse.schema";
import { SharedTextField } from "@shared/SharedTextField/SharedTextField";
import { useSelector } from "react-redux";

export default function CourseDetailsSideBar({ refetch }) {
  const lang = useSelector((state) => state?.global?.lang);
  const [activeCourse, setActiveCourse] = useRecoilState(activeCourseAtom);
  const [addCourse, setAddCourse] = useRecoilState(addCourseAtom);
  const [isEditing, setIsEditing] = useState(false);
  const globalState = useSelector((state) => state?.global);

  function close() {
    setActiveCourse(null);
    setAddCourse(false);
    setIsEditing(false);
  }

  return (
    <Empty
      sx={{
        p: 2,
        overflowY: "auto",
        bgcolor: globalState?.isDarkMode ? COLORS.SOFT_DARK : COLORS.WHITE,
      }}
    >
      {(activeCourse || addCourse) && <CloseButton onClick={close} />}

      {(() => {
        if (isEditing)
          return <AddOrEditCourseUI close={close} refetch={refetch} />;
        else if (activeCourse)
          return (
            <CourseDataUI
              setIsEditing={setIsEditing}
              activeCourse={activeCourse}
              refetch={refetch}
            />
          );
        else if (addCourse)
          return <AddOrEditCourseUI close={close} refetch={refetch} />;
        else if (!activeCourse) return null;
      })()}
    </Empty>
  );
}

function AddOrEditCourseUI({ close, refetch }) {
  const lang = useSelector((state) => state?.global?.lang);
  const globalState = useSelector((state) => state?.global);
  const [add] = useRecoilState(addCourseAtom);
  const [activeCourse] = useRecoilState(activeCourseAtom);
  const [availabeSubscribtionDays, setAvailabeSubscriptionDays] =
    useState(false);
  const methods = useForm({
    resolver: zodResolver(add ? createCourseSchema : updateCourseSchema),
  });
  const {
    setValue,
    handleSubmit,
    formState,
    clearErrors,
    errors,
    register,
    getValues,
  } = methods;
  const [imageSrc, setImageSrc] = useState(null);

  const { getRootProps, getInputProps } = useDropzone(
    dropzoneConfig({
      setValue,
      setImageSrc,
      clearErrors,
    })
  );

  function initData() {
    setImageSrc({ url: getImageUrl(activeCourse.image) });
    setValue("name", activeCourse.name);
    setValue("description", activeCourse.description);
    setValue("duration", activeCourse.duration);
    setValue("price", activeCourse.price);
    setValue("subscription_days", activeCourse.subscription_days);
  }

  if (activeCourse && !imageSrc && !add) {
    initData();
  }
  console.log(refetch, "from parent");
  const { updateCourse, isPending: updatePending } = useUpdateCourse({
    refetch,
    courseId: activeCourse?.id,
    onSettled: close,
  });
  const { addCourse, isPending: addPending } = useAddCourse({
    refetch,
    onSettled: close,
  });

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(add ? addCourse : updateCourse)}>
          <div
            // data-testid="image-container"
            {...getRootProps()}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <img
              data-testid="image"
              src={
                imageSrc?.url || getImageUrl(activeCourse?.image) || emptyImage
              }
              style={{ width: "80%", cursor: "pointer", marginX: "auto" }}
              alt=""
            />
            <input data-testid="image-input" {...getInputProps()} />
          </div>
          <FormHelperText sx={{ color: COLORS.DANGER }}>
            {formState.errors?.image && formState.errors?.image?.message}
          </FormHelperText>
          <Column gap={2}>
            <BorderedInput
              registerName={"name"}
              placeholder={
                lang == "ar" ? "ادخل اسم الكورس" : "Enter course name"
              }
            />
            <BorderedInput
              registerName={"description"}
              placeholder={
                lang == "ar" ? "ادخل الوصف " : "Enter course description"
              }
            />
            <Row spacing="space-between" width={"100%"} gap={1}>
              <BorderedInput
                registerName={"duration"}
                placeholder={
                  lang == "ar" ? "ادخل المده " : "Enter course duration"
                }
                type="number"
              />
              <Typography>{lang == "ar" ? "ساعة" : "Hour"}</Typography>
            </Row>

            <BorderedInput
              registerName={"price"}
              placeholder={lang == "ar" ? "ادخل السعر " : "Enter Price"}
              type="number"
            />

            <Row spacing="space-between" width={"100%"} gap={1}>
              <Checkbox
                sx={{
                  color: "#22a770",
                  "&.Mui-checked": {
                    color: "#22a770",
                  },
                }}
                defaultChecked={false}
                checked={availabeSubscribtionDays}
                onChange={() => {
                  if (availabeSubscribtionDays) {
                    setValue("subscription_days", 0);
                  }
                  setAvailabeSubscriptionDays(!availabeSubscribtionDays);
                }}
              />
              <SharedTextField
                placeholder={
                  lang == "ar" ? "ايام الاشتراك " : "Subscription days"
                }
                label={lang == "ar" ? "ايام الاشتراك " : "Subscription days"}
                register={register("subscription_days", {
                  validate: (value) => {
                    return !availabeSubscribtionDays ||
                      value > 0 ||
                      lang == "ar"
                      ? "ايام الاشتراك يجب ان تكون اكبر من صفر او صفر"
                      : "Subscription days must be greater than zero";
                  },
                })}
                dark={globalState?.isDarkMode}
                extra={{
                  disabled: !availabeSubscribtionDays,
                  type: "number",
                  fullWidth: true,
                  // error: errors.subscription_days,
                  // helperText: errors.subscription_days?.message,
                }}
              />
              <FormHelperText sx={{ color: COLORS.DANGER }}>
                {formState.errors?.subscription_days &&
                  formState.errors?.subscription_days?.message}
              </FormHelperText>
            </Row>

            <Row spacing="space-between" width={"100%"} gap={1} mt={4}>
              <ColorButton
                type="submit"
                isLoading={addPending || updatePending}
              >
                {lang === "ar"
                  ? add
                    ? "اضافة"
                    : "تعديل"
                  : add
                  ? "Add"
                  : "Edit"}
              </ColorButton>
              {!add && (
                <ColorButton
                  onClick={initData}
                  isLoading={addPending || updatePending}
                >
                  {lang == "ar" ? "استعادة" : "Reset"}
                </ColorButton>
              )}
              <ColorButton onClick={close} colorType="secondary">
                {lang == "ar" ? "اغلاق" : "Close"}
              </ColorButton>
            </Row>
          </Column>
        </form>
      </FormProvider>
    </>
  );
}

function CourseDataUI({ setIsEditing, activeCourse, refetch }) {
  const lang = useSelector((state) => state?.global?.lang);
  return (
    <>
      <Row>
        <Column>
          <Row>
            <CourseStateIconSmall
              image={student}
              number={activeCourse?.subscriptionsCount}
              name={lang == "ar" ? "طالب" : "Student"}
              color={"blue"}
            />
            <CourseStateIconSmall
              image={course}
              number={activeCourse?.modulesCount}
              name={lang == "ar" ? "حصة" : "Lesson"}
              color={"#2f8bc8"}
            />
          </Row>
          <Row>
            <CourseStateIconSmall
              image={money}
              number={activeCourse?.paymentCodesCount}
              name={lang == "ar" ? "كود" : "Code"}
              color={"blue"}
            />
            <CourseStateIconSmall
              image={book}
              number={activeCourse?.examsCount}
              name={lang == "ar" ? "امتحان" : "Exam"}
              color={"#2f8bc8"}
            />
          </Row>
        </Column>
        <img
          src={getImageUrl(activeCourse?.image)}
          style={{ width: "70%" }}
          alt=""
        />
      </Row>

      <Column>
        <Typography fontFamily={FONTS.BOLD} fontSize={FONT_SIZES.LARGE} py={2}>
          {activeCourse?.name}
          {currentUser()?.role !== "Supervisor" && (
            <EditButton onClick={() => setIsEditing(true)} />
          )}
        </Typography>
        <Typography
          fontFamily={FONTS.BOLD}
          fontSize={FONT_SIZES.REGULAR}
          pb={2}
          sx={{ textWrap: "wrap" }}
        >
          {activeCourse?.description}
        </Typography>
        {activeCourse?.price == 0 ? (
          <Typography
            fontFamily={FONTS.BOLD}
            fontSize={FONT_SIZES.LARGE}
            color={COLORS.PRIMARY.MAIN}
          >
            {lang == "ar" ? " مجاني" : "Free"}
          </Typography>
        ) : (
          <Row>
            <Typography
              fontFamily={FONTS.BOLD}
              color={COLORS.PRIMARY.MAIN}
              fontSize={FONT_SIZES.LARGE}
            >
              {activeCourse?.price}
            </Typography>
            <Typography
              fontFamily={FONTS.REGULAR}
              color={COLORS.PRIMARY.MAIN}
              fontSize={FONT_SIZES.MEDIUM}
            >
              &nbsp;{lang == "ar" ? "جنيه" : "EGP"}
            </Typography>
          </Row>
        )}
        <Row>
          <Typography
            fontFamily={FONTS.BOLD}
            color={COLORS.PRIMARY.MAIN}
            fontSize={FONT_SIZES.LARGE}
          >
            {activeCourse?.subscription_days}
          </Typography>
          <Typography
            fontFamily={FONTS.REGULAR}
            color={COLORS.PRIMARY.MAIN}
            fontSize={FONT_SIZES.MEDIUM}
          >
            &nbsp;{lang == "ar" ? "يوم" : "day"}
          </Typography>
        </Row>
      </Column>
      <DeleteCourse courseId={activeCourse?.id} refetch={refetch} />
    </>
  );
}

function DeleteCourse({ courseId, refetch }) {
  const lang = useSelector((state) => state?.global?.lang);
  const [open, setOpen] = useState(false);
  const [_, setActiveCourse] = useRecoilState(activeCourseAtom);

  const { deleteCourse, isPending } = useDeleteCourse({
    id: courseId,
    onSettled: () => {
      setOpen(false);
      setActiveCourse(null);
    },
    refetch,
  });

  return (
    <>
      {currentUser()?.role !== "Supervisor" && (
        <Row>
          <TextButton
            color={COLORS.DANGER}
            sx={{ mt: 10 }}
            onClick={() => {
              setOpen(true);
            }}
          >
            {lang == "ar" ? " حذف الكورس" : "Delete Course"}
          </TextButton>
        </Row>
      )}
      <Modal open={open} closeFn={() => setOpen(false)} maxWidth={"sm"}>
        <Typography fontFamily={FONTS.BOLD} fontSize={FONT_SIZES.LARGE}>
          {lang == "ar"
            ? "  هل انت متأكد من حذف الكورس؟"
            : "Are you sure about Delete this course"}
        </Typography>
        <Row mt={2} gap={2}>
          <ColorButton
            onClick={() => deleteCourse()}
            isLoading={isPending}
            colorType="danger"
            width="100px"
          >
            {lang == "ar" ? " حذف" : "Delete"}
          </ColorButton>
          <ColorButton
            colorType="secondary"
            width="100px"
            onClick={() => setOpen(false)}
          >
            {lang == "ar" ? "الغاء" : "Cancel"}
          </ColorButton>
        </Row>
      </Modal>
    </>
  );
}

// function LoadingUI() {
//   return (
//     <>
//       <Row align={"start"}>
//         <Column ml={1}>
//           <Row gap={1}>
//             <Typography width={"30px"} variant="h3">
//               <Skeleton sx={{ backgroundColor: "#bbb" }} />
//             </Typography>
//             <Typography width={"30px"} variant="h3">
//               <Skeleton sx={{ backgroundColor: "#bbb" }} />
//             </Typography>
//           </Row>
//           <Row gap={1}>
//             <Typography width={"30px"} variant="h3">
//               <Skeleton sx={{ backgroundColor: "#bbb" }} />
//             </Typography>
//             <Typography width={"30px"} variant="h3">
//               <Skeleton sx={{ backgroundColor: "#bbb" }} />
//             </Typography>
//           </Row>
//         </Column>
//         <Skeleton
//           sx={{ backgroundColor: "#bbb", width: "100%" }}
//           variant="rectangular"
//           height={220}
//         />
//       </Row>
//       <Column mt={4}>
//         <Skeleton
//           sx={{ backgroundColor: "#bbb", width: "300px", height: "60px" }}
//         />
//         <Skeleton
//           sx={{ backgroundColor: "#bbb", width: "300px", height: "40px" }}
//         />
//         <Skeleton
//           sx={{ backgroundColor: "#bbb", width: "300px", height: "60px" }}
//         />
//       </Column>
//     </>
//   );
// }
