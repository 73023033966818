import useCustomMutation from "@/hooks/useCustomMutation";

export default function useDeleteCourse({ id, onSettled, refetch }) {
  const { mutate, isPending } = useCustomMutation({
    url: `course/delete/${id}`,
    invalidate: [["course", id], "courses"],
    method: "post",
    successMsg: "تم حذف الكورس بنجاح",
    onSuccess: () => {
      refetch();
    },
    onSettled,
  });

  function deleteCourse() {
    mutate();
  }

  return { deleteCourse, isPending };
}
