import useCustomMutation from "@/hooks/useCustomMutation";
import { useSelector } from 'react-redux';

export function useRemoveBanStudent({ onSettled }) {
 const lang = useSelector((state) => state?.global?.lang);
  const { mutate, isPending } = useCustomMutation({
    url: "users/removeBan",
    invalidate: ["removeBan"],
    method: "post",
    successMsg: lang == "ar" ? "تم فك حظر الطالب" : "Student has been unbanned",
    onSettled,
    lang,
  });

  function removeBanStudent(data) {
    const formData = new FormData();
    Object.entries(data).forEach((key) => {
      formData.append(key[0], data[key[0]]);
    });
    mutate(formData);
  }

  return { removeBanStudent, isPending };
}
