import React from "react";
import "./SocialMediaIcon.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { COLORS } from "@/constants/Colors/colors";

const SocialMediaIcon = ({ icon, link }) => {
  const globalState = useSelector((state) => state?.global);
  return (
    <Link
      to={`//${link}`}
      target="_blank"
      // rel="noopener noreferrer"
      className="social-media-icon"
      style={{
        color: globalState?.isDarkMode ? COLORS.WHITE : COLORS.WHITE,
        border: `2px solid ${
          globalState?.isDarkMode ? COLORS.WHITE : COLORS.WHITE
        }`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {icon}
    </Link>
  );
};

export default SocialMediaIcon;
