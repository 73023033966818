import dayjs from "dayjs";
import { z } from "zod";

export const createCourseCodeSchema = z.object({
  activationDuration: z
    .string()
    .refine((data) => Number(data) > 0, "عدد الايام يجب ان يكون اكبر من الصفر"),
  numberOfCodes: z
    .string()
    .refine(
      (data) => Number(data) > 0,
      "عدد الاكواد يجب ان يكون اكبر من الصفر"
    ),
  expires_at: z.any().transform((val) => {
    return dayjs(val).format("YYYY-MM-DD hh:mm:ss A");
  }),
  isExcel: z.enum(["0", "1"]),
  course_id: z.string().optional(),
  instructor_id: z.string().optional(),
});
