import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAxiosConfig from "@shared/Helpers/axios/useAxiosConfig";
import { notifySuccess, notifyError } from "@shared/Toast/toast";
import { initialSwapState } from "../Admin/Components/swapOrder/utils";

export default function useSwapOrder(setSwapState) {
  const instance = useAxiosConfig();

  function swapOrder({ first_lesson_module_id, second_lesson_module_id }) {
    return instance.get(
      `module/swaporder?first_lesson_module_id=${first_lesson_module_id}&second_lesson_module_id=${second_lesson_module_id}`
    );
  }

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: swapOrder,
    onSuccess: () => {
      notifySuccess("تم تغيير الترتيب بنجاح");
      queryClient.invalidateQueries(["course modules"]);
      queryClient.invalidateQueries(["course modules"]);
      setSwapState && setSwapState(initialSwapState);
    },
    onError: (error) => {
      notifyError(error?.response?.data?.message);
    },
  });
}
