import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
} from "@mui/material";
import { COLORS } from "@/constants/Colors/colors";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const TopSubscriptions = ({ data }) => {
  const navigate = useNavigate();
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  return (
    <Box
      sx={{
        width: "100%",
        padding: "20px",
        bgcolor: globalState?.isDarkMode ? COLORS.SOFT_DARK : COLORS.WHITE,
        fontFamily: "Noor-regular",
        borderRadius: 2,
        boxShadow: "0 0 8px rgba(0,0,0,0.1)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          mb: 2,
        }}
      >
        <Typography
          sx={{
            fontSize: "28px",
            fontFamily: "Noor-regular",

            color: COLORS.PRIMARY.MAIN,
          }}
        >
          {lang == "ar"
            ? "الدروس الاعلي اشتراكاً"
            : "The most subscribed lessons"}
        </Typography>

        <Button
          onClick={() => {
            navigate("courses");
          }}
          sx={{
            fontFamily: "Noor-regular",
            color: "white",
            bgcolor: COLORS.PRIMARY.MAIN,
            fontSize: "18px",
            "&:hover": { bgcolor: COLORS.SECONDARY.MAIN },
          }}
        >
          {lang == "ar" ? "عرض المزيد" : "Show more"}
        </Button>
      </Box>
      <Table>
        <TableHead>
          <TableRow
            sx={{
              display: "flex",

              justifyContent: "space-evenly",
              width: "100%",
            }}
          >
            <TableCell sx={{ flex: 0.1, textAlign: "right" }}>#</TableCell>
            <TableCell sx={{ flex: 2, textAlign: "right" }}>
              {lang == "ar" ? "اسم الدرس" : "Lesson name"}
            </TableCell>
            <TableCell sx={{ flex: 1, textAlign: "right" }}>
              {lang == "ar" ? "السعر" : "Price"}
            </TableCell>
            <TableCell sx={{ flex: 1, textAlign: "right" }}>
              {lang == "ar" ? "عدد الاشتراكات" : "Number of subscriptions"}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item, index) => {
            return (
              <TableRow
                key={item?.id}
                sx={{
                  display: "flex",

                  justifyContent: "space-evenly",
                  width: "100%",
                }}
              >
                <TableCell sx={{ flex: 0.1, textAlign: "right" }}>
                  {index + 1}
                </TableCell>
                <TableCell sx={{ flex: 2, textAlign: "right" }}>
                  {item?.name}
                </TableCell>
                <TableCell sx={{ flex: 1, textAlign: "right" }}>
                  {item?.price}
                </TableCell>
                <TableCell sx={{ flex: 1, textAlign: "right" }}>
                  {item?.subscriptions_count}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

TopSubscriptions.propTypes = {
  data: PropTypes.array,
};
