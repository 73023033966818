import { Box, Typography } from "@mui/material";
import { ClipLoader } from "react-spinners";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { COLORS } from "@/constants/Colors/colors";
export default function Number({ name, value, hex, icon: Icon = null }) {
  const globalState = useSelector((state) => state?.global);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row-reverse",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "10px",
        padding: "30px",
        borderRadius: "15px",
        bgcolor: globalState?.isDarkMode ? COLORS.SOFT_DARK : COLORS.WHITE,
      }}
    >
      {Icon && <Icon sx={{ color: hex, fontSize: "60px" }} />}
      <Box>
        {value === undefined && <ClipLoader color={hex} size={60} />}
        {value !== undefined && (
          <Typography
            sx={{
              fontSize: "60px",
              fontWeight: "bold",
              color: hex,
              fontFamily: "Noor-regular",
            }}
          >
            {value}
          </Typography>
        )}
        <Typography sx={{ fontSize: "24px", fontFamily: "Noor-regular" }}>
          {name}
        </Typography>
      </Box>
    </Box>
  );
}

Number.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number,
  hex: PropTypes.string,
  icon: PropTypes.any,
};
