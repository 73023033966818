import { Box, Button, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { HashLoader } from "react-spinners";
import { useForm } from "react-hook-form";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import { COLORS } from "@/constants/Colors/colors";
import { useMutate } from "@/hooks/useMutate";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import AndroidSharpIcon from "@mui/icons-material/AndroidSharp";
import IconTiktok from "@shared/Icons/TiktokIcon";
import IconApple from "@shared/Icons/AppleIcon";
import IconHuawei from "@shared/Icons/HuaweiIcon";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import YouTubeIcon from "@mui/icons-material/YouTube";

export default function EditLink({
  openModal,
  handleClose,
  refetch,
  editedData,
}) {
  const lang = useSelector((state) => state?.global?.lang);
  const globalState = useSelector((state) => state?.global);
  // form validation
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type: "",
      link: "",
    },
  });

  const { mutateAsync, isPending } = useMutate();

  const onSubmit = (data) => {
    console.log("Data", data);
    mutateAsync({
      url: `socials/update`,
      method: "POST",
      body: { ...data, id: editedData?.id },
    })
      .then(() => {
        notifySuccess(
          lang == "ar" ? "تم التعديل بنجاح" : "Modified successfully"
        );
        refetch();
        handleClose();
        reset();
      })
      .catch(() => {
        notifyError(
          lang == "ar"
            ? "حدث خطأ اثناء تنفيذ المهمة"
            : "An error occurred while executing the task"
        );
      });
  };

  useEffect(() => {
    setValue("type", editedData?.type);
    setValue("link", editedData?.link);
  }, [editedData]);

  return (
    <div
      className=""
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        background: "#000000ab",
        width: "100%",
        height: "100vh",
        zIndex: "1500",
        display: "flex",
        padding: "80px 0px",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        transition: "0.3s",
        scrollbarColor: COLORS.PRIMARY.MAIN,
        scrollbarWidth: "thin",
        overflow: "hidden",
        opacity: openModal ? 1 : 0,
        visibility: openModal ? "visible" : "hidden",
      }}
    >
      <Box
        className="device-box"
        px={3}
        sx={{
          padding: "20px 30px",
          background: globalState?.isDarkMode ? COLORS.DARK_GRAY : "#F5F5F5",
          borderRadius: "10px",
          width: "70%",
          height: "auto",
          // overflowY: 'auto',
          display: "flex",
          flexDirection: "column",
          transition: "0.3s",
          opacity: openModal ? 1 : 0,
          transform: openModal ? "scale(1)" : "scale(0)",
        }}
      >
        <div
          className="close-btn"
          style={{
            position: "absolute",
            top: 5,
            right: 5,
            zIndex: 100,
          }}
        >
          <Close
            onClick={() => {
              handleClose();
              refetch();
              reset();
            }}
            sx={{
              color: "white",
              padding: "8px 10px",
              width: "40px",
              height: "40px",
              borderRadius: "5px",
              transition: "0.4s",
              bgcolor: "primary.main",
              cursor: "pointer",
              "&:hover": {
                bgcolor: "white",
                color: COLORS.PRIMARY.MAIN,
              },
            }}
          />
        </div>

        <Typography
          fontFamily={"Noor-bold"}
          mb={6}
          mt={4}
          fontSize={32}
          sx={{
            textShadow: `0px 2px 0px ${COLORS.PRIMARY.MAIN}`,
            textAlign: "center",
          }}
        >
          {lang == "ar" ? "تعديل الرابط" : "Edit link"}
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "100%",
                marginRight: "30px",
                display: "flex",
                flexDirection: "row",
                gap: 2,
              }}
            >
              <Box sx={{ width: "17%" }}>
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    marginBottom: "30px",
                    textAlign: "center",
                  }}
                >
                  {/* <span
                    style={{
                      position: "absolute",
                      top: "-15px",
                      right: lang == "ar" ? "40px" : "unset",
                      left: lang == "ar" ? "unset" : "40px",
                      fontSize: "15px",
                      color: COLORS.PRIMARY.MAIN,
                      background: "#F5F5F5",
                      padding: "0px 20px",
                    }}
                  >
                    {lang == "ar" ? "نوع الرابط" : "Link type"}
                  </span>
                  <input
                    type="text"
                    disabled
                    placeholder={lang == "ar" ? "نوع الرابط" : "Link type"}
                    style={{
                      border: `1px solid ${COLORS.PRIMARY.MAIN}`,
                      padding: "20px 15px",
                      borderRadius: "5px",
                      outline: "none",
                      fontSize: "16px",
                      width: "100%",
                      color: globalState?.isDarkMode ? COLORS.WHITE : "black",
                      transition: "0.4s",
                      backgroundColor: globalState?.isDarkMode
                        ? COLORS.DARK_GRAY
                        : "#F5F5F5",
                    }}
                    value={editedData?.type}
                  />
                  {errors.type ? (
                    <Typography
                      fontSize={15}
                      fontFamily={"Noor-bold"}
                      color={"red"}
                      mt={1}
                      mr={1}
                    >
                      {errors.type?.message}
                    </Typography>
                  ) : (
                    ""
                  )} */}
                  <Typography sx={{ marginBottom: "10px" }}>
                    {lang == "ar" ? "نوع الرابط" : "Link type"}
                  </Typography>
                  {editedData?.type == "instagram" && <InstagramIcon />}
                  {editedData?.type == "facebook" && <FacebookIcon />}
                  {editedData?.type == "twitter" && <XIcon />}
                  {editedData?.type == "youtube" && <YouTubeIcon />}
                  {editedData?.type == "telegram" && <TelegramIcon />}
                  {editedData?.type == "whatsapp" && <WhatsAppIcon />}
                  {editedData?.type == "tiktok" && <IconTiktok />}
                  {editedData?.type == "android" && <AndroidSharpIcon />}
                  {editedData?.type == "iphone" && <IconApple />}
                  {editedData?.type == "huawei" && <IconHuawei />}
                </Box>
              </Box>
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    marginBottom: "30px",
                  }}
                >
                  <span
                    style={{
                      position: "absolute",
                      top: "-15px",
                      right: lang == "ar" ? "40px" : "unset",
                      left: lang == "ar" ? "unset" : "40px",
                      fontSize: "15px",
                      color: COLORS.PRIMARY.MAIN,
                      background: "#F5F5F5",
                      padding: "0px 20px",
                    }}
                  >
                    {lang == "ar" ? "اكتب الرابط" : "Type the link"}
                  </span>
                  <input
                    type="text"
                    placeholder={lang == "ar" ? "اكتب الرابط" : "Type the link"}
                    style={{
                      background: globalState?.isDarkMode
                        ? COLORS.DARK_GRAY
                        : "#F5F5F5",
                      border: `1px solid ${COLORS.PRIMARY.MAIN}`,
                      padding: "20px 15px",
                      borderRadius: "5px",
                      outline: "none",
                      fontSize: "16px",
                      width: "100%",
                      color: globalState?.isDarkMode ? COLORS.WHITE : "black",
                      transition: "0.4s",
                    }}
                    {...register("link", {
                      required:
                        lang == "ar"
                          ? `يجب ادخال اكتب الرابط`
                          : `You must enter, type the link`,
                    })}
                  />
                  {errors.link ? (
                    <Typography
                      fontSize={15}
                      fontFamily={"Noor-bold"}
                      color={"red"}
                      mt={1}
                      mr={1}
                    >
                      {errors.link?.message}
                    </Typography>
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            mt={5}
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              type="submit"
              onClick={""}
              sx={{
                background: COLORS.PRIMARY.MAIN,
                width: "250px",
                padding: "10px 25px",
                color: "white",
                cursor: "pointer",
                transition: "0.4s",
                "&:hover": {
                  background: "white",
                  color: COLORS.PRIMARY.MAIN,
                },
              }}
            >
              {isPending ? (
                <HashLoader color="#ffffff" size={24} />
              ) : lang == "ar" ? (
                "تعديل"
              ) : (
                "Edit"
              )}
              {/* <HashLoader color="#ffffff" size={24} /> */}
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  );
}

EditLink.propTypes = {
  openModal: PropTypes.bool,
  handleClose: PropTypes.func,
  editedData: PropTypes.object,
  refetch: PropTypes.func,
};
