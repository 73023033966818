import React, { useState } from "react";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { Box, Typography, IconButton } from "@mui/material";
import { COLORS } from "@/constants/Colors/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteDialog from "../../DeleteDialog";
import EditVideo from "../Edit/EditVideo";
import ViewCount from "./ViewCount";
import { useSelector } from "react-redux";

export const ModuleVideo = ({ video, show }) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [deleteName, setDeleteName] = useState();
  const [confirmMessage, setConfirmMessage] = useState("");
  const lang = useSelector((state) => state?.global?.lang);
  const globalState = useSelector((state) => state?.global);
  const handleDeleteClick = () => {
    setIsConfirmOpen(true);
    if (lang == "ar") {
      setConfirmMessage("هل انت متأكد من حذف الفيديو");
    } else {
      setConfirmMessage("Are you sure you want to delete the video?");
    }
    setDeleteData(video);
    setDeleteName(video?.title);
  };
  return (
    <Box
      sx={{
        width: "100%",
        paddingX: 2,
        color: COLORS.WHITE,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <PlayCircleIcon sx={{ color: COLORS.PRIMARY.MAIN }} />
        <Typography color={globalState?.isDarkMode ? COLORS.WHITE : "#777"}>
          {video?.name?.length > 20
            ? video?.name?.slice(0, 20) + "..."
            : video?.name}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", gap: 1 }}>
        <ViewCount lessonId={video} enabled={show} />
        <EditVideo video={video} />
        <IconButton
          sx={{
            bgcolor: COLORS.DANGER,
            "&:hover": { bgcolor: COLORS.DANGER },
            width: 28,
            height: 28,
          }}
          onClick={handleDeleteClick}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>

        <DeleteDialog
          isConfirmOpen={isConfirmOpen}
          setIsConfirmOpen={setIsConfirmOpen}
          deleteName={deleteName}
          deleteData={deleteData}
          confirmMessage={confirmMessage}
        />
      </Box>
    </Box>
  );
};
