import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import VideocamIcon from "@mui/icons-material/Videocam";
import { COLORS } from "@/constants/Colors/colors";
import { useState } from "react";
import Modal from "@shared/Modal/Modal";
import { FormProvider, useForm } from "react-hook-form";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import { HashLoader } from "react-spinners";
import { newVideoSchema } from "../../../schema/newVedio.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import useAddVideo from "../../hook/useAddVideo";
import { useSelector } from 'react-redux';

export const AddVideo = ({ module, close }) => {
  const language = useSelector((state) => state?.global);
  const lang = language?.lang;
  const [open, setOpen] = useState(false);

  const methods = useForm({
    resolver: zodResolver(newVideoSchema),
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      name: "",
      link: "",
    },
  });

  const { addModuleVideo, isPending } = useAddVideo({
    lang,
    onSuccess: () => {
      setOpen(false);
      close();
    },
  });

  function submit(data) {
    console.log("test", data);
    !data.name && delete data.name;
    addModuleVideo({
      ...data,
      module_id: module?.id,
    });
    // setOpen(false);
  }
  return (
    <>
      <Button
        sx={{
          bgcolor: "#b93232",
          display: "block",
          color: COLORS.WHITE,
          width: 150,
          height: 150,
          "&:hover": { bgcolor: "#b93232", opacity: "0.9" },
        }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <VideocamIcon fontSize="large" />
        <Typography>{lang == "ar" ? "فيديو" : "Video"}</Typography>
      </Button>
      <Modal open={open} closeFn={() => setOpen(false)} maxWidth={"sm"}>
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(submit)}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "20px",
              width: "60%",
              margin: "auto",
            }}
          >
            <Typography mb={2} fontSize={"30px"} fontFamily={"Noor-bold"}>
              {lang == "ar" ? " اضف فيديو" : "Add video"}
            </Typography>
            <TextField
              registername={"name"}
              placeholder={
                lang == "ar" ? "ادخل اسم الفيديو (اختياري)" : "Enter video name"
              }
              type="textt"
              {...register("name")}
              fullWidth
              sx={{
                direction: "rtl",
              }}
            />
            <FormHelperText sx={{ color: COLORS.DANGER }}>
              {errors?.name && errors?.name?.message}
            </FormHelperText>
            <TextField
              registername={"link"}
              {...register("link", {
                required:
                  lang == "ar" ? `هذا الحقل مطلوب` : "this field is required",
              })}
              placeholder={
                lang == "ar" ? "ادخل لينك اليوتيوب" : "Enter youtube link"
              }
              type="text"
              fullWidth
              sx={{
                direction: "rtl",
              }}
            />
            <FormHelperText sx={{ color: COLORS.DANGER }}>
              {errors?.link && errors?.link?.message}
            </FormHelperText>

            <Box
              sx={{
                marginTop: "50px",
                marginBottom: "30px",
                textAlign: "center",
              }}
            >
              <ColorButton
                disabled={isPending}
                type="submit"
                variant="contained"
                sx={{ bgcolor: "#2f8bc8", marginLeft: "20px" }}
                //   onClick={addRowInSession}
              >
                {isPending ? (
                  <HashLoader color={COLORS.WHITE} size={20} />
                ) : lang == "ar" ? (
                  "حفظ"
                ) : (
                  "Save"
                )}
              </ColorButton>
            </Box>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};
