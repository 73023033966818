import { Pagination } from "@mui/material";
export default function Paginator({ page, totalPages, setPage, sx }) {
  return (
    <Pagination
      sx={sx}
      count={totalPages}
      page={page}
      color="primary"
      shape="rounded"
      onChange={(_, value) => setPage(value)}
    />
  );
}
