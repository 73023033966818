import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  Close,
  RemoveRedEyeOutlined,
  VisibilityOffOutlined,
} from "@mui/icons-material";
import { useEffect } from "react";
import { HashLoader } from "react-spinners";
import { useForm } from "react-hook-form";
import { governorates } from "../../../hooks/governorates";
import useCreateStudent from "../../../hooks/useCreateStudent";
import PropTypes from "prop-types";
import { COLORS } from "@/constants/Colors/colors";
import { useSelector } from "react-redux";

export default function AddStudent({ openAddStudent, closeAddStundet }) {
 const lang = useSelector((state) => state?.global?.lang);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      parent_phone: "",
      city: "",
      password: "",
      password_confirmation: "",
    },
  });

  const password = watch("password", "");
  const selectedCity = watch("city");

  // create function
  const { mutate, isLoading, isSuccess, error } = useCreateStudent();
  const globalState = useSelector((state) => state?.global);

  const onSubmit = (data) => {
    mutate(data);
  };

  const [passType, setPassType] = useState("password");
  const togglePassword = () => {
    if (passType === "password") {
      setPassType("text");
      return;
    }
    setPassType("password");
  };

  useEffect(() => {
    if (isSuccess) {
      closeAddStundet();
      reset();
    }
  }, [isSuccess]);
  return (
    <div
      className=""
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        background: "#000000ab",
        width: "100%",
        height: "100%",
        zIndex: "1500",
        display: "flex",
        // justifyContent: 'center',
        padding: "80px 0px",
        alignItems: "center",
        flexDirection: "column",
        transition: "0.3s",
        scrollbarColor: "#2f8bc8",
        scrollbarWidth: "thin",
        overflowY: "auto",
        opacity: openAddStudent ? 1 : 0,
        visibility: openAddStudent ? "visible" : "hidden",
      }}
    >
      <Box
        className="device-box"
        px={3}
        sx={{
          padding: "20px 30px",
          background: globalState?.isDarkMode
            ? COLORS?.SOFT_DARK
            : COLORS?.WHITE,
          borderRadius: "10px",
          width: "70%",
          height: "auto",
          // overflowY: 'auto',
          display: "flex",
          flexDirection: "column",
          transition: "0.3s",
          opacity: openAddStudent ? 1 : 0,
          transform: openAddStudent ? "scale(1)" : "scale(0)",
        }}
      >
        <div
          className="close-btn"
          style={{
            position: "absolute",
            top: 5,
            right: 5,
            zIndex: 100,
          }}
        >
          <Close
            onClick={() => {
              closeAddStundet();
              reset();
            }}
            sx={{
              color: "white",
              padding: "8px 10px",
              width: "40px",
              height: "40px",
              borderRadius: "5px",
              transition: "0.4s",
              bgcolor: "secondary.main",
              cursor: "pointer",
              "&:hover": {
                bgcolor: "#2f8bc8",
              },
            }}
          />
        </div>

        <Typography
          fontFamily={"Noor-bold"}
          mb={6}
          mt={4}
          fontSize={32}
          sx={{ textShadow: "0px 2px 0px #2f8bc8", textAlign: "center" }}
        >
          {lang == "ar" ? "انشاء طالب جديد" : "Create Student"}
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              // alignItems: 'center',
              marginBottom: "40px",
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: "100%",
                // marginLeft: "10px",
              }}
            >
              <span
                style={{
                  position: "absolute",
                  top: "-24px",
                  right: lang == "ar" ? "40px" : "unset",
                  left: lang == "ar" ? "unset" : "40px",
                  fontSize: "15px",
                  color: "#2f8bc8",
                  background: globalState?.isDarkMode
                    ? COLORS?.SOFT_DARK
                    : COLORS?.WHITE,
                  padding: "10px 20px",
                }}
              >
                {lang == "ar" ? "الاسم بالكامل" : "Full name"}
              </span>
              <input
                type="text"
                placeholder={lang == "ar" ? "الاسم بالكامل" : "Full name"}
                style={{
                  background: globalState?.isDarkMode
                    ? COLORS?.SOFT_DARK
                    : COLORS?.WHITE,
                  border: "1px solid #2f8bc8",
                  padding: "20px 15px",
                  borderRadius: "5px",
                  outline: "none",
                  fontSize: "16px",
                  width: "100%",
                  color: globalState?.isDarkMode ? "White" : "black",
                  transition: "0.4s",
                }}
                {...register("name", {
                  required: lang == "ar" ? `يجب ادخال الاسم` : "Required",
                  validate: (name) => {
                    // Split the input name into words based on spaces
                    const words = name.split(" ");

                    // Filter out empty words (consecutive spaces)
                    const validWords = words.filter(
                      (word) => word.trim() !== ""
                    );

                    return validWords.length == 3 || lang == "ar"
                      ? "لا يمكن أن يكون الاسم أقل أو أكثر من 3 كلمات"
                      : "The name cannot be less or more than 3 words";
                  },
                })}
              />
              {errors.name ? (
                <Typography
                  fontSize={15}
                  fontFamily={"Noor-bold"}
                  color={"red"}
                  mt={1}
                  mr={1}
                >
                  {errors.name?.message}
                </Typography>
              ) : (
                ""
              )}
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              // alignItems: 'center',
              marginBottom: "40px",
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <span
                style={{
                  position: "absolute",
                  top: "-24px",
                  right: lang == "ar" ? "40px" : "unset",
                  left: lang == "ar" ? "unset" : "40px",
                  fontSize: "15px",
                  color: "#2f8bc8",
                  background: globalState?.isDarkMode
                    ? COLORS?.SOFT_DARK
                    : COLORS?.WHITE,
                  padding: "10px 20px",
                }}
              >
                {lang == "ar" ? " رقم الطالب" : "Student number"}
              </span>
              <input
                type="text"
                placeholder={lang == "ar" ? "رقم الطالب" : "Student number"}
                style={{
                  background: globalState?.isDarkMode
                    ? COLORS?.SOFT_DARK
                    : COLORS?.WHITE,
                  border: "1px solid #2f8bc8",
                  padding: "20px 15px",
                  borderRadius: "5px",
                  outline: "none",
                  fontSize: "16px",
                  width: "100%",
                  color: globalState?.isDarkMode ? "White" : "black",
                  transition: "0.4s",
                }}
                {...register("phone", {
                  required: lang == "ar" ? `يجب ادخال رقم الطالب` : "required",
                  maxLength: {
                    value: 11,
                    message:
                      lang == "ar"
                        ? "يجب ان يكون 11 رقم فقط"
                        : "It should only be 11 numbers",
                  },
                  minLength: {
                    value: 11,
                    message:
                      lang == "ar"
                        ? "يجب ان يكون 11 رقم"
                        : "It must be 11 numbers",
                  },
                  pattern: {
                    value: /^(011|010|012|015)\d{8}$/,
                    message:
                      lang == "ar"
                        ? "الرقم غير صحيح"
                        : "The number is incorrect",
                  },
                })}
              />
              {errors.phone ? (
                <Typography
                  fontSize={15}
                  fontFamily={"Noor-bold"}
                  color={"red"}
                  mt={1}
                  mr={1}
                >
                  {errors.phone?.message}
                </Typography>
              ) : (
                ""
              )}
              {error?.response?.data?.errors?.phone?.[0] ===
              "The phone has already been taken." ? (
                <Typography
                  fontSize={15}
                  fontFamily={"Noor-bold"}
                  color={"red"}
                  mt={1}
                  mr={1}
                >
                  {lang == "ar"
                    ? "هذا الرقم مستخدم من قبل"
                    : "This number is already in use"}
                </Typography>
              ) : (
                ""
              )}
            </Box>
            <Box
              sx={{
                position: "relative",
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <span
                style={{
                  position: "absolute",
                  top: "-24px",
                  right: lang == "ar" ? "40px" : "unset",
                  left: lang == "ar" ? "unset" : "40px",
                  fontSize: "15px",
                  color: "#2f8bc8",
                  background: globalState?.isDarkMode
                    ? COLORS?.SOFT_DARK
                    : COLORS?.WHITE,
                  padding: "10px 20px",
                }}
              >
                {lang == "ar" ? "رقم ولي الامر" : "Parent phone number"}
              </span>
              <input
                type="text"
                placeholder={
                  lang == "ar" ? " رقم ولي الامر" : "Parent phone number"
                }
                style={{
                  background: globalState?.isDarkMode
                    ? COLORS?.SOFT_DARK
                    : COLORS?.WHITE,
                  border: "1px solid #2f8bc8",
                  padding: "20px 15px",
                  borderRadius: "5px",
                  outline: "none",
                  fontSize: "16px",
                  width: "100%",
                  color: globalState?.isDarkMode ? "White" : "black",
                  transition: "0.4s",
                }}
                {...register("parent_phone", {
                  required:
                    lang == "ar"
                      ? `يجب ادخال رقم ولي الامر`
                      : `You must enter the parent's number.`,
                  maxLength: {
                    value: 11,
                    message:
                      lang == "ar"
                        ? "يجب ان يكون 11 رقم فقط"
                        : "It should only be 11 numbers",
                  },
                  minLength: {
                    value: 11,
                    message:
                      lang == "ar"
                        ? "يجب ان يكون 11 رقم"
                        : "It must be 11 numbers",
                  },
                  pattern: {
                    value: /^(011|010|012|015)\d{8}$/,
                    message:
                      lang == "ar"
                        ? "الرقم غير صحيح"
                        : "The number is incorrect",
                  },
                  validate: (value, data) => {
                    if (value === data.phone) {
                      return lang == "ar"
                        ? "رقم ولي الامر لا يمكن ان يساوي رقم الطالب"
                        : "The parent's number cannot be equal to the student's number.";
                    } else {
                      return true;
                    }
                  },
                })}
              />
              {errors.parent_phone ? (
                <Typography
                  fontSize={15}
                  fontFamily={"Noor-bold"}
                  color={"red"}
                  mt={1}
                  mr={1}
                >
                  {errors.parent_phone?.message}
                </Typography>
              ) : (
                ""
              )}
              {error?.response?.data?.errors?.phone?.[0] ===
              "The phone has already been taken." ? (
                <Typography
                  fontSize={15}
                  fontFamily={"Noor-bold"}
                  color={"red"}
                  mt={1}
                  mr={1}
                >
                  {lang == "ar"
                    ? " هذا الرقم مستخدم من قبل"
                    : "This number is already in use"}
                </Typography>
              ) : (
                ""
              )}
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                position: "relative",
                width: "100%",
                marginBottom: "40px",
              }}
            >
              <span
                style={{
                  position: "absolute",
                  top: "-24px",
                  right: lang == "ar" ? "40px" : "unset",
                  left: lang == "ar" ? "unset" : "40px",
                  fontSize: "15px",
                  color: "#2f8bc8",
                  background: globalState?.isDarkMode
                    ? COLORS?.SOFT_DARK
                    : COLORS?.WHITE,
                  padding: "10px 20px",
                }}
              >
                {lang == "ar" ? "المحافظة" : "City"}
              </span>

              <select
                name=""
                id=""
                placeholder={lang == "ar" ? "المحافظة" : "City"}
                style={{
                  background: globalState?.isDarkMode
                    ? COLORS?.SOFT_DARK
                    : COLORS?.WHITE,
                  border: "1px solid #2f8bc8",
                  padding: "20px 15px",
                  borderRadius: "5px",
                  outline: "none",
                  fontSize: "16px",
                  width: "100%",
                  color: globalState?.isDarkMode ? "White" : "black",
                  transition: "0.4s",
                }}
                {...register("city", {
                  required:
                    lang == "ar"
                      ? `يجب اختيار المحافظة`
                      : `You must select the city`,
                  validate: () =>
                    (selectedCity != lang) == "ar"
                      ? "اختر المحافظة"
                      : "Choose city" || lang == "ar"
                      ? "يجب اختيار المحافظة"
                      : "You must choose the city",
                })}
              >
                <option value="" disabled selected>
                  {lang == "ar" ? "اختر المحافظة" : "Choose city"}
                </option>
                {governorates?.map((i, index) => {
                  return (
                    <>
                      <option key={index} value={i.value}>
                        {i.label}
                      </option>
                    </>
                  );
                })}
              </select>
              {errors.city ? (
                <Typography
                  fontSize={15}
                  fontFamily={"Noor-bold"}
                  color={"red"}
                  mt={1}
                  mr={1}
                >
                  {errors.city?.message}
                </Typography>
              ) : (
                ""
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "40px",
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: "100%",
                // marginRight: "10px",
              }}
            >
              <span
                style={{
                  position: "absolute",
                  top: "-24px",
                  right: lang == "ar" ? "40px" : "unset",
                  left: lang == "ar" ? "unset" : "40px",
                  fontSize: "15px",
                  color: "#2f8bc8",
                  background: globalState?.isDarkMode
                    ? COLORS?.SOFT_DARK
                    : COLORS?.WHITE,
                  padding: "10px 20px",
                }}
              >
                {lang == "ar" ? "البريد الالكتروني" : "Email"}
              </span>
              <input
                type="email"
                placeholder={lang == "ar" ? "البريد الالكتروني" : "Email"}
                style={{
                  background: globalState?.isDarkMode
                    ? COLORS?.SOFT_DARK
                    : COLORS?.WHITE,
                  border: "1px solid #2f8bc8",
                  padding: "20px 15px",
                  borderRadius: "5px",
                  outline: "none",
                  fontSize: "16px",
                  width: "100%",
                  color: globalState?.isDarkMode ? "White" : "black",
                  transition: "0.4s",
                }}
                {...register("email", {
                  required: `يجب ادخال البريد الالكتروني`,
                  pattern: {
                    value:
                      /^[a-zA-Z0-9._%+-]*[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]*[a-zA-Z][a-zA-Z0-9.-]*\.[a-zA-Z]{2,}$/i,
                    message:
                      lang == "ar"
                        ? "البريد الاكتروني غير صالح"
                        : "Email is incorrect",
                  },
                })}
              />

              {errors.email ? (
                <Typography
                  fontSize={15}
                  fontFamily={"Noor-bold"}
                  color={"red"}
                  mt={1}
                  mr={1}
                >
                  {errors.email?.message}
                </Typography>
              ) : (
                ""
              )}
              {error?.response?.data?.errors?.email?.[0] ===
                "The email has already been taken." ||
              error?.response?.status === 400 ? (
                <Typography
                  fontSize={15}
                  fontFamily={"Noor-bold"}
                  color={"red"}
                  mt={1}
                  mr={1}
                >
                  {lang == "ar"
                    ? " هذا الايميل مستخدم من قبل"
                    : "This email has already been used"}
                </Typography>
              ) : (
                ""
              )}
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "40px",
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: "100%",
                marginLeft: "10px",
                height: "100%",
              }}
            >
              <Box>
                <span
                  style={{
                    position: "absolute",
                    top: "-24px",
                    right: lang == "ar" ? "40px" : "unset",
                    left: lang == "ar" ? "unset" : "40px",
                    fontSize: "15px",
                    color: "#2f8bc8",
                    background: globalState?.isDarkMode
                      ? COLORS?.SOFT_DARK
                      : COLORS?.WHITE,
                    padding: "10px 20px",
                  }}
                >
                  {lang == "ar" ? "كلمة السر" : "Password"}
                </span>
                <input
                  type={passType}
                  placeholder={lang == "ar" ? "كلمة السر" : "Password"}
                  style={{
                    background: globalState?.isDarkMode
                      ? COLORS?.SOFT_DARK
                      : COLORS?.WHITE,
                    border: "1px solid #2f8bc8",
                    padding: "20px 15px",
                    borderRadius: "5px",
                    outline: "none",
                    fontSize: "16px",
                    width: "100%",
                    color: globalState?.isDarkMode ? "White" : "black",
                    transition: "0.4s",
                  }}
                  {...register("password", {
                    required:
                      lang == "ar" ? `يجب ادخال كلمة السر` : "Enter Password",
                    minLength: {
                      value: 8,
                      message:
                        lang == "ar"
                          ? "يجب ادخال علي الاقل 8 قيم"
                          : "You should enter 8 values",
                    },
                  })}
                />
                {passType === "password" ? (
                  <RemoveRedEyeOutlined
                    onClick={togglePassword}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: lang == "ar" ? "15px" : "unset",
                      right: lang == "ar" ? "unset" : "15px",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                      opacity: ".7",
                    }}
                  />
                ) : (
                  <VisibilityOffOutlined
                    onClick={togglePassword}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: lang == "ar" ? "15px" : "unset",
                      right: lang == "ar" ? "unset" : "15px",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                      opacity: ".7",
                    }}
                  />
                )}
              </Box>

              {errors.password ? (
                <Typography
                  fontSize={15}
                  fontFamily={"Noor-bold"}
                  color={"red"}
                  mt={1}
                  mr={1}
                >
                  {errors.password?.message}
                </Typography>
              ) : (
                ""
              )}
            </Box>
            <Box
              sx={{
                position: "relative",
                width: "100%",
              }}
            >
              <span
                style={{
                  position: "absolute",
                  top: "-24px",
                  right: lang == "ar" ? "40px" : "unset",
                  left: lang == "ar" ? "unset" : "40px",
                  fontSize: "15px",
                  color: "#2f8bc8",
                  background: globalState?.isDarkMode
                    ? COLORS?.SOFT_DARK
                    : COLORS?.WHITE,
                  padding: "10px 20px",
                }}
              >
                {lang == "ar" ? " تأكيد كلمة السر" : "password confirmation"}
              </span>
              <input
                type={passType}
                placeholder={
                  lang == "ar" ? "تأكيد كلمة السر" : "password confirmation"
                }
                style={{
                  background: globalState?.isDarkMode
                    ? COLORS?.SOFT_DARK
                    : COLORS?.WHITE,
                  border: "1px solid #2f8bc8",
                  padding: "20px 15px",
                  borderRadius: "5px",
                  outline: "none",
                  fontSize: "16px",
                  width: "100%",
                  color: globalState?.isDarkMode ? "White" : "black",
                  transition: "0.4s",
                }}
                {...register("password_confirmation", {
                  required:
                    lang == "ar"
                      ? `يجب ادخال تأكيد كلمة السر`
                      : `You must enter a confirmation password`,
                  minLength: {
                    value: 8,
                    message:
                      lang == "ar"
                        ? "يجب ادخال علي الاقل 8 قيم"
                        : "You must enter at least 8 values",
                  },
                  validate: (value) =>
                    value === password || lang == "ar"
                      ? "يجب أن تكون كلمة السر متطابقة"
                      : "Password must match",
                })}
              />
              {errors.password_confirmation ? (
                <Typography
                  fontSize={15}
                  fontFamily={"Noor-bold"}
                  color={"red"}
                  mt={1}
                  mr={1}
                >
                  {errors.password_confirmation?.message}
                </Typography>
              ) : (
                ""
              )}
            </Box>
          </Box>

          <Box
            mt={5}
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              type="submit"
              onClick={""}
              sx={{
                background: "#2f8bc8",
                width: "250px",
                padding: "10px 25px",
                color: "white",
                cursor: "pointer",
                transition: "0.4s",
                "&:hover": {
                  background: "#2f8bc8",
                },
              }}
            >
              {isLoading ? (
                <HashLoader color="#ffffff" size={24} />
              ) : lang == "ar" ? (
                "انشاء"
              ) : (
                "Create"
              )}
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  );
}
AddStudent.propTypes = {
  openAddStudent: PropTypes.bool,
  closeAddStundet: PropTypes.func,
};
