import { COLORS } from "@/constants/Colors/colors";
import { api_url } from "@/constants/base_url";
import { Box, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { HashLoader } from "react-spinners";
import EditIcon from "@mui/icons-material/Edit";
import { EditCourseHeader } from "../../../Admin/Pages/Course/CourseHeader/EditCourseHeader/EditCourseHeader";
import { HeaderInfo } from "../../../Admin/Pages/Course/CourseHeader/HeaderInfo/HeaderInfo";
import student from "@assets/adminImages/student.png";
import course from "@assets/adminImages/course.png";
import session from "@assets/adminImages/session.png";
import money from "@assets/adminImages/money.png";
import lesson from "@assets/adminImages/lessons.png";
import { useSelector } from 'react-redux';

export const CourseHeader = ({ data, isPending }) => {
 const lang = useSelector((state) => state?.global?.lang);
  const [editing, setEditing] = useState(false);
  const { id } = useParams();
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: 500,
        bgcolor: COLORS.SOFT_DARK,
        color: COLORS.WHITE,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {editing ? (
        <EditCourseHeader course={data} setEditing={setEditing} id={id} />
      ) : isPending ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: 500,
          }}
        >
          <HashLoader color={COLORS.PRIMARY.MAIN} />
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <img
            src={`${api_url.slice(0, -7)}${data?.image}`}
            // height={250}
            width={450}
            style={{ objectFit: "cover" }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              flexWrap: "wrap",
              width: "100%",
              wordBreak: "break-word",
            }}
          >
            <Typography sx={{ fontSize: 30 }}>{data?.name}</Typography>
            <Typography
              sx={{
                fontSize: 20,
              }}
            >
              {lang == "ar" ? "الوصف:" : "Description:"} {data?.description}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <HeaderInfo
                color={"#4dcf89"}
                name={lang == "ar" ? "إشتراكات" : "Subscription"}
                value={data?.subscriptionsCount}
                icon={money}
              />
              <HeaderInfo
                color={"#6e61b5"}
                name={lang == "ar" ? "أكواد" : "Codes"}
                value={data?.paymentCodesCount}
                icon={session}
              />
              <HeaderInfo
                color={"#f08639"}
                name={lang == "ar" ? "حصص" : "Lessons"}
                value={data?.modulesCount}
                icon={course}
              />
              <HeaderInfo
                color={"#ea2a3d"}
                name={lang == "ar" ? "واجبات" : "Homeworks"}
                value={data?.homeworkCount}
                icon={lesson}
              />
              <HeaderInfo
                color={"#4884df"}
                name={lang == "ar" ? "إمتحانات" : "Exams"}
                value={data?.examsCount}
                icon={student}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 5,
            }}
          >
            <Typography sx={{ fontSize: 20 }}>
              {+data?.price
                ? `${+data?.price} ${lang == "ar" ? "جنيها" : "EGP"}`
                : lang == "ar"
                ? "مجاني"
                : "Free"}
            </Typography>
            <IconButton
              onClick={() => {
                setEditing(true);
              }}
              sx={{ color: COLORS.WHITE, bgcolor: COLORS.PRIMARY.MAIN }}
            >
              <EditIcon />
            </IconButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};
