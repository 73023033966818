import { TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";

export default function CustomTableHead({ tableHeaders, headBackground }) {
  return (
    <TableHead sx={{ bgcolor: headBackground, color: "white", width:"100%" }}>
      <TableRow>
        {tableHeaders != 0 ? (
          <>
            {tableHeaders?.map((head, index) => (
              <TableCell key={index} align="center">
                <Typography color={"white"}>{head}</Typography>
              </TableCell>
            ))}
          </>
        ) : (
          ""
        )}
      </TableRow>
    </TableHead>
  );
}
