import { FONTS } from "@/constants/Fonts/fonts";
import Column from "../../Layout/Column/Column";
import { Typography } from "@mui/material";
import { FONT_SIZES } from "@/constants/Fonts/font_sizes";

export default function CourseStateIconSmall({ image, number, name, color }) {
  return (
    <Column>
      <img src={image} alt="" style={{ width: "40px" }} />
      <Typography sx={{ color: color }}>{number}</Typography>
      <Typography fontFamily={FONTS.REGULAR} fontSize={FONT_SIZES.SMALL}>
        {name}
      </Typography>
    </Column>
  );
}
