import { COLORS } from "@/constants/Colors/colors";
import { Typography, Box, Button, Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import SocialMediaIcon from "../SocialMediaIcon/SocialMediaIcon";
import useCustomQuery from "@/hooks/useCustomQuery";
import YouTubeIcon from "@mui/icons-material/YouTube";
import XIcon from "@mui/icons-material/X";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";
import AppleIcon from "@mui/icons-material/Apple";
import { useSelector } from "react-redux";
import logo from "@assets/images/logo.png";
import IconHuawei from "@shared/Icons/HuaweiIcon";
import AndroidSharpIcon from "@mui/icons-material/AndroidSharp";
import { features } from "@config/features";
import { Link as ScrollLink } from 'react-scroll';
export default function Footer() {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const navigate = useNavigate();
  const { data: version } = useCustomQuery({
    url: `version`,
    key: ["version", lang],
    lang,
  });
  const { data: socials } = useCustomQuery({
    url: `socials`,
    key: ["socials", lang],
    lang,
  });

  const Item = styled("Paper")(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
    ...theme.typography.body2,
    color: "#d1d1d1",
    border: "none",
  }));
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };


  return (
    <>
      <Grid
        container
        spacing={3}
        width={"100%"}
        margin={"auto"}
        backgroundColor={
          globalState?.isDarkMode ? COLORS.DARK_GRAY : COLORS.PRIMARY.MAIN
        }
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={20}
        sx={{ direction: lang == "ar" ? "rtk" : "ltr" }}
      >
        <Grid item xs={6} md={3}>
          <Item sx={{ fontWeight: "bold", fontSize: "1rem" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "2rem",
                color: "white",
                padding: "20px",
              }}
            >
              <Typography variant="h4">
                {lang == "ar" ? "روابط مهمة" : "Important links"}
              </Typography>
              <Link
                to={"/privacy"}
                style={{
                  textDecoration: "none",
                  color: "white",
                }}
                onClick={handleClick}
              >
                <Typography sx={{ fontSize: "1.4rem" }}>
                  {lang == "ar" ? "شروط الخصوصية" : "Privacy Terms"}
                </Typography>
              </Link>
              <Link
                to={"/terms-of-user-page"}
                onClick={handleClick}
                style={{
                  textDecoration: "none",
                  color: "white",
                }}
              >
                <Typography sx={{ fontSize: "1.4rem" }}>
                  {lang == "ar" ? "شروط الاستخدام" : "terms of use"}
                </Typography>
              </Link>
              <ScrollLink
              to="top-courses"
              smooth={true}
              duration={500}
              style={{
                  textDecoration: "none",
                  color: "white",
                  cursor :"pointer"
                }}
              >
                <Typography sx={{ fontSize: "1.4rem" }}>
                  {lang == "ar" ? "الكورسات" : "Courses"}
                </Typography>
              </ScrollLink>
              {features.student&&(
              <Link
                to={"/book-store"}
                style={{
                  textDecoration: "none",
                  color: "white",
                }}
              >
                <Typography sx={{ fontSize: "1.4rem" }}>
                  {lang == "ar" ? "متجر الكتب" : "Book Store"}
                </Typography>
              </Link>
              )}
            </Box>
          </Item>
        </Grid>
        <Grid item xs={6} md={3}>
          <Item sx={{ fontWeight: "bold", fontSize: "1rem", color: "white" }}>
            <Typography variant="h4"> Trucks Diagnosis</Typography>
            <Typography
              sx={{
                marginTop: "0.8rem",
                fontSize: "1.2rem",
                lineHeight: "60px",
              }}
            >
              {lang == "ar"
                ? "شرح بأسلوب علمى و عملى عن كيفية استخدام الاجهزة المتطورة فى كشف اعطال و برمجة الشاحنات الحديثة و ذلك بأسلوب سهل و بسيط يناسب جميع الفئات المهتمه بهذا المجال"
                :"An explanation in a scientific and practical manner about how to use advanced devices to detect malfunctions and program modern trucks, in an easy and simple manner that suits all groups interested in this field." }
              </Typography>
          </Item>
        </Grid>
        <Grid item xs={6} md={3}>
          <Item
            sx={{
              fontWeight: "bold",
              fontSize: "1rem",
              color: "white",
            }}
          >
           <ScrollLink 
           to="nav"
           smooth={true}
           duration={500}
           style={{ cursor: 'pointer' }}
           >
           <img
              style={{
                cursor: "pointer",
              }}
              src={logo}
              width={100}
            />
           </ScrollLink>
           
          </Item>
        </Grid>
      </Grid>
      {/* socials */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          backgroundColor: globalState?.isDarkMode
            ? COLORS.DARK_GRAY
            : COLORS.PRIMARY.MAIN,
          padding: "20px",
        }}
      >
        {socials?.data.map((social) =>
          social.type == "facebook" ? (
            <SocialMediaIcon
              icon={<FacebookOutlinedIcon />}
              link={social.link}
            />
          ) : social.type == "instagram" ? (
            <SocialMediaIcon icon={<InstagramIcon />} link={social.link} />
          ) : social.type == "youtube" ? (
            <SocialMediaIcon icon={<YouTubeIcon />} link={social.link} />
          ) : social.type == "x" ? (
            <SocialMediaIcon icon={<XIcon />} link={social.link} />
          ) : social.type == "whatsapp" ? (
            <SocialMediaIcon icon={<WhatsAppIcon />} link={social.link} />
          ) : social.type == "telegram" ? (
            <SocialMediaIcon icon={<TelegramIcon />} link={social.link} />
          ) : social.type == "tiktok" ? (
            <SocialMediaIcon
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M11 2v6.414a6.85 6.85 0 1 0 5.6 6.736v-3.736a8.603 8.603 0 0 0 3.4.686h1V6.5h-1c-1.903 0-3.4-1.537-3.4-3.5V2zm2 2h1.688c.394 2.22 2.08 3.996 4.312 4.41v1.618c-1.038-.152-1.975-.542-2.843-1.123L14.6 7.863v7.287a4.85 4.85 0 1 1-4.6-4.844v1.604a3.25 3.25 0 1 0 3 3.24zM8.5 15.15a1.25 1.25 0 1 1 2.5 0a1.25 1.25 0 0 1-2.5 0"
                  ></path>
                </svg>
              }
              link={social.link}
            />
          ) : social.type == "apple" ? (
            <SocialMediaIcon icon={<AppleIcon />} link={social.link} />
          ) : social.type == "huawei" ? (
            <SocialMediaIcon icon={<IconHuawei />} link={social.link} />
          ) : (
            social.type == "android" && (
              <SocialMediaIcon icon={<AndroidSharpIcon />} link={social.link} />
            )
          )
        )}
      </Box>
      {/* terms && version */}
      <Grid
        spacing={2}
        width={"100%"}
        margin={"auto"}
        paddingX={10}
        backgroundColor={
          globalState?.isDarkMode ? COLORS.SOFT_DARK : COLORS.WHITE
        }
      >
        <Item
          sx={{
            fontWeight: "bold",
            fontSize: "1rem",
            color: globalState?.isDarkMode ? COLORS.WHITE : COLORS.BLACK,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            bgcolor: globalState?.isDarkMode ? COLORS.SOFT_DARK : COLORS.WHITE,
          }}
        >
          {" "}
          <a
            href="http://firststepacademy.online/"
            style={{
              color: globalState?.isDarkMode ? COLORS.WHITE : COLORS.BLACK,
              textDecoration: "none",
              fontFamily: "sans-serif",
              width: "90%",
              display: "flex",
              alignItems: "center",
              flexDirection : `${lang == "ar"? "row" : "row-reverse"}` ,
              justifyContent : `${lang == "ar"? "flex-start":"flex-end"}`,
              gap: "5px",
            }}
            target="_blank"
          >
          
            {lang == "ar"
              ? "جميع الحقوق محفوظة لشركة "
              : "All rights reserved to the company"}
              <Typography sx={{ fontSize: "1.3rem", color: COLORS.GREEN }}>
              First Step
            </Typography>
          </a>
          <Box
            sx={{
              marginTop: "1rem",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography paragraph>
              {lang == "ar" ? "الاصدار الحالي :" : " version:"}{" "}
              {version?.version}
            </Typography>
          </Box>
        </Item>
      </Grid>
    </>
  );
}
