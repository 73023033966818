import useAxiosConfig from "@shared/Helpers/axios/useAxiosConfig";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export default function useUpdateVideoData({ lang, closeModal }) {
  const instance = useAxiosConfig(lang);

  function updateVideo(data) {
    return instance.post("lesson/update", data);
  }

  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateVideo,
    onSuccess: () => {
      queryClient.invalidateQueries(["module"]);
      notifySuccess("تم تعديل الفيديو بنجاح");
    },
    onError: (error) => {
      notifyError(error.response.data.message);
    },
    onSettled: () => {
      closeModal();
    },
  });
}
