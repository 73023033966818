import { COLORS } from "@/constants/Colors/colors";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";

export default function NoItemsText({ text, sx }) {
  const globalState = useSelector((state) => state?.global);
  return (
    <Typography
      sx={{
        fontFamily: "Noor-bold",
        fontSize: "30px",
        textShadow: `0px 2px 0px ${COLORS.PRIMARY.MAIN}`,
        textAlign: "center",
        color: globalState?.isDarkMode ? COLORS.WHITE : COLORS.BLACK,
        ...sx,
      }}
    >
      {text}
    </Typography>
  );
}
