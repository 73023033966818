import { Close } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import HashLoader from "react-spinners/HashLoader";
import { useForm } from "react-hook-form";
import useBanStudent from "../../../hooks/useBanStudent";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { COLORS } from "@/constants/Colors/colors";

function BanStudent({ noteBook, closeNote, id, refetch }) {
 const lang = useSelector((state) => state?.global?.lang);
  const globalState = useSelector((state) => state?.global);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: "",
      date: "",
      time: "",
      banned_until: "",
    },
  });

  const date = watch("date");
  const time = watch("time");
  const combinedDateTime = date && time ? `${date} ${time}` : "";

  const { banStudent, isPending } = useBanStudent({ onSettled: refetch });

  const onSubmit = (data) => {
    data.banned_until = combinedDateTime;
    data.id = id;
    banStudent(data);
    refetch();
    closeNote();
    reset();
  };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          bgcolor: "#000000ab",
          zIndex: "1501",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "0.3s",
          opacity: noteBook === "ban" ? "1" : "0",
          visibility: noteBook === "ban" ? "visible" : "hidden",
        }}
      >
        <Box
          sx={{
            bgcolor: globalState?.isDarkMode ? COLORS.DARK_GRAY : "white",
            padding: "30px",
            borderRadius: "10px",
            width: "400px",
            height: "auto",
            textAlign: "center",
            position: "relative",
            transition: "0.3s",
            opacity: noteBook === "ban" ? "1" : "0",
            transform: noteBook === "ban" ? "scale(1)" : "scale(0)",
          }}
        >
          <div
            className="close-btn"
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              zIndex: 100,
            }}
          >
            <Close
              onClick={closeNote}
              sx={{
                color: "black",
                width: 30,
                height: 30,
                transition: "0.4s",
                cursor: "pointer",
                "&:hover": {
                  color: "#2f8bc8",
                },
              }}
            />
          </div>

          <Typography
            fontFamily={"Noor-bold"}
            fontSize={30}
            sx={{ textShadow: "0px 1.5px 0px #2f8bc8" }}
          >
            {lang == "ar"
              ? "هل تريد حظر هذا الطالب"
              : "Do you want to block this student?"}
          </Typography>

          <form action="" onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  marginTop: "40px",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    top: "-24px",
                    right: lang == "ar" ? "20px" : "unset",
                    left: lang == "ar" ? "unset" : "20px",
                    fontSize: "15px",
                    // color: "#2f8bc8",
                    // background: "white",
                    padding: "10px 20px",
                  }}
                >
                  {lang == "ar" ? "التاريخ" : "Date"}
                </span>
                <input
                  className={"panInput"}
                  type="date"
                  placeholder={lang == "ar" ? "التاريخ" : "Date"}
                  style={{
                    background: globalState?.isDarkMode
                      ? COLORS.DARK_GRAY
                      : "white",
                    border: "1px solid #2f8bc8",
                    padding: "20px 15px",
                    borderRadius: "5px",
                    outline: "none",
                    fontSize: "16px",
                    width: "100%",
                    color: globalState?.isDarkMode ? COLORS.WHITE : "black",
                    transition: "0.4s",
                  }}
                  {...register("date", {
                    required:
                      lang == "ar"
                        ? `يجب ادخال التاربخ`
                        : `You must enter a date`,
                  })}
                  min={new Date().toISOString().split("T")[0]} // Set min attribute to present date
                />
                {errors.date ? (
                  <Typography
                    fontSize={15}
                    fontFamily={"Noor-bold"}
                    color={"red"}
                    mt={1}
                  >
                    {errors.date?.message}
                  </Typography>
                ) : (
                  ""
                )}
              </Box>

              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  marginTop: "40px",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    top: "-24px",
                    right: lang == "ar" ? "20px" : "unset",
                    left: lang == "ar" ? "unset" : "20px",
                    fontSize: "15px",
                    // color: "#2f8bc8",
                    // background: "white",
                    padding: "10px 20px",
                  }}
                >
                  {lang == "ar" ? "الوقت" : "Time"}
                </span>
                <input
                  className={"panInput"}
                  type="time"
                  placeholder={lang == "ar" ? "الوقت" : "Time"}
                  style={{
                    background: globalState?.isDarkMode
                      ? COLORS.DARK_GRAY
                      : "white",
                    border: "1px solid #2f8bc8",
                    padding: "20px 15px",
                    borderRadius: "5px",
                    outline: "none",
                    fontSize: "16px",
                    width: "100%",
                    color: globalState?.isDarkMode ? COLORS.WHITE : "black",
                    transition: "0.4s",
                  }}
                  {...register("time", {
                    required: lang == "ar" ? `يجب ادخال الوقت` : "Enter time",
                  })}
                />
                {errors.time ? (
                  <Typography
                    fontSize={15}
                    fontFamily={"Noor-bold"}
                    color={"red"}
                    mt={1}
                  >
                    {errors.time?.message}
                  </Typography>
                ) : (
                  ""
                )}
              </Box>
            </Box>

            <Box
              mt={5}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alidngItems: "center",
              }}
            >
              <Button
                type="submit"
                sx={{
                  padding: "7px 20px",
                  width: "49%",
                  bgcolor: "darkred",
                  color: "white",
                  trnsition: "0.4s",
                  "&:hover": {
                    bgcolor: "red",
                  },
                }}
              >
                {isPending ? (
                  <HashLoader color="#ffffff" size={24} />
                ) : lang == "ar" ? (
                  "أوافق"
                ) : (
                  "Accept"
                )}
              </Button>
              <Button
                onClick={closeNote}
                sx={{
                  padding: "7px 20px",
                  width: "49%",
                  bgcolor: "gray",
                  color: "white",
                  trnsition: "0.4s",
                  "&:hover": {
                    bgcolor: "lightgray",
                  },
                }}
              >
                {lang == "ar" ? "الغاء" : "Cancel"}
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
}

BanStudent.propTypes = {
  noteBook: PropTypes.bool,
  closeNote: PropTypes.func,
  id: PropTypes.number,
  refetch: PropTypes.func,
};

export default BanStudent;
