import useCustomMutation from "@/hooks/useCustomMutation";
import { useSelector } from "react-redux";
export default function useBanStudent({ onSettled }) {
  const lang = useSelector((state) => state?.global?.lang);
  const { mutate, isPending } = useCustomMutation({
    url: "users/ban",
    invalidate: ["banStudent"],
    method: "post",
    successMsg: lang == "ar" ? "تم حظر الطالب" : "The student has been blocked",
    onSettled,
    lang,
  });

  function banStudent(data) {
    const formData = new FormData();
    Object.entries(data).forEach((key) => {
      formData.append(key[0], data[key[0]]);
    });
    mutate(formData);
  }

  return { banStudent, isPending };
}
