export const governorates = [
    { value: "Alexandria", label: "الإسكندرية" },
    { value: "Aswan", label: "أسوان" },
    { value: "Asyut", label: "أسيوط" },
    { value: "Beheira", label: "البحيرة" },
    { value: "Beni Suef", label: "بني سويف" },
    { value: "Cairo", label: "القاهرة" },
    { value: "Dakahlia", label: "الدقهلية" },
    { value: "Damietta", label: "دمياط" },
    { value: "Faiyum", label: "الفيوم" },
    { value: "Gharbia", label: "الغربية" },
    { value: "Giza", label: "الجيزة" },
    { value: "Ismailia", label: "الإسماعيلية" },
    { value: "Kafr El Sheikh", label: "كفر الشيخ" },
    { value: "Luxor", label: "الأقصر" },
    { value: "Matrouh", label: "مطروح" },
    { value: "Minya", label: "المنيا" },
    { value: "Monufia", label: "المنوفية" },
    // { value: "New Valley", label: "الوادي الجديد" },
    { value: "North Sinai", label: "شمال سيناء" },
    { value: "Port Said", label: "بورسعيد" },
    { value: "Qalyubia", label: "القليوبية" },
    { value: "Qena", label: "قنا" },
    { value: "Red Sea", label: "البحر الأحمر" },
    { value: "Sharqia", label: "الشرقية" },
    { value: "Sohag", label: "سوهاج" },
    { value: "South Sinai", label: "جنوب سيناء" },
  ];
  const governoratesObject = governorates.reduce((obj, item) => {
    obj[item.value] = item.label;
    return obj;
  }, {});

  export const getGovernorate = (key)=>{
    return governoratesObject[key]
  }
