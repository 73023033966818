import { api_url } from "@/constants/base_url";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";

export const ExamImagePicker = ({ exam, setExam, index, defaultImage }) => {
  const [imageSrc, setImageSrc] = useState({
    url: defaultImage
      ? `${api_url.slice(0, -7)}${defaultImage}`
      : "https://www.svgrepo.com/show/508699/landscape-placeholder.svg",
  });
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg"],
    },
    onDrop: (acceptedFile) => {
      setExam((prevExam) => {
        const temp = [...prevExam.questions];
        temp[index].image = acceptedFile;
        return { ...prevExam, questions: temp };
      });
      setImageSrc({
        url: URL.createObjectURL(acceptedFile[0]),
        file: acceptedFile[0],
      });
    },
  });
  return (
    <>
      <div
        style={{
          width: "250px",
          height: "250px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
        {...getRootProps()}
      >
        <img
          src={imageSrc?.url}
          style={{ width: 250, cursor: "pointer", objectFit: "cover" }}
          alt=""
        />
      </div>
      <input {...getInputProps()} />
    </>
  );
};
