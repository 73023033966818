import { COLORS } from "@/constants/Colors/colors";
import { ROLES } from "@/constants/roles";
import { Close } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import useCustomQuery from "@/hooks/useCustomQuery";
import CustomNoData from "@shared/Layout/NoDataTableRow/CustomNoData";
import CustomTableHead from "@shared/Layout/TableHead/CustomTableHead";
import CustomTableLoading from "@shared/Layout/TableRowLoading/CustomTableLoading";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import useLocalStorageToken from "@shared/Helpers/axios/useLocalStorageToken";
import DeleteSubscribtion from "./DeleteSubscribtion";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useMutate } from "@/hooks/useMutate";
import { notifySuccess, notifyError } from "@shared/Toast/toast";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

export default function StudentSubscribtions({
  id,
  closeNote,
  noteBook,
  //   refetch,
}) {
 const lang = useSelector((state) => state?.global?.lang);
  const globalState = useSelector((state) => state?.global);
  const User = useLocalStorageToken();

  const tableHeader = [
    lang == "ar" ? "الرقم" : "Number",
    lang == "ar" ? "اسم الكورس" : "Course name",
    lang == "ar" ? "حذف" : "Delete",
  ];

  const {
    data: tableData,
    isLoading,
    refetch,
  } = useCustomQuery({
    url: `subscription/getUserSubscriptionsAsAdmin?type=Course&user_id=${id}`,
    key: ["getUserSubscriptionsAsAdmin", id],
    enabled: !isNaN(+id),
    lang,
    // page: page,
  });

  const { mutateAsync } = useMutate();

  const [noteSub, setNoteSub] = useState(false);
  const [subscribtionId, setSubscribtionId] = useState();
  const noteSubscribe = (note) => {
    setNoteSub(note);
  };

  const closeSubNote = () => {
    setNoteSub("");
  };

  const getStudentId = (id) => {
    setSubscribtionId(id);
    // console.log(id);
  };
  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          bgcolor: "#000000ab",
          zIndex: "1501",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "0.3s",
          opacity: noteBook === "courses" ? "1" : "0",
          visibility: noteBook === "courses" ? "visible" : "hidden",
        }}
      >
        <Box
          sx={{
            bgcolor: globalState?.isDarkMode ? COLORS.DARK_GRAY : "white",
            padding: "30px",
            borderRadius: "10px",
            width: "90%",
            height: "auto",
            textAlign: "center",
            position: "relative",
            transition: "0.3s",
            opacity: noteBook === "courses" ? "1" : "0",
            transform: noteBook === "courses" ? "scale(1)" : "scale(0)",
          }}
        >
          <div
            className="close-btn"
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              zIndex: 100,
            }}
          >
            <Close
              onClick={closeNote}
              sx={{
                color: "black",
                width: 30,
                height: 30,
                transition: "0.4s",
                cursor: "pointer",
                "&:hover": {
                  color: "#2f8bc8",
                },
              }}
            />
          </div>

          <DeleteSubscribtion
            subscribtionId={subscribtionId}
            closeSubNote={closeSubNote}
            noteSubscribe={noteSub}
            refetch={refetch}
          />
          <TableContainer>
            <Table>
              <CustomTableHead
                headBackground={COLORS.PRIMARY.MAIN}
                tableHeaders={tableHeader}
              />

              {isLoading ? (
                <CustomTableLoading width={"100%"} height={60} numOfCells={7} />
              ) : (
                <>
                  {tableData?.data?.data.length > 0 ? (
                    <>
                      {tableData?.data?.data.map((student, i) => (
                        <TableBody key={student?.id}>
                          <TableRow>
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              {i + 1}
                            </TableCell>
                            <TableCell align="center">
                              {student?.course?.name}
                            </TableCell>
                            <TableCell align="center">
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {ROLES.ADMIN === User?.role && (
                                  <>
                                    <IconButton
                                      onClick={() => {
                                        mutateAsync({
                                          url: `subscription/${student?.id}/${
                                            student?.deleted_at
                                              ? "restore"
                                              : "suspend"
                                          }`,
                                          method: student?.deleted_at
                                            ? "Patch"
                                            : "Delete",
                                        })
                                          .then(() => {
                                            !student?.deleted_at
                                              ? notifySuccess(
                                                  lang == "ar"
                                                    ? "تم تعطيل الاشتراك بنجاح"
                                                    : "Subscription has been successfully disabled"
                                                )
                                              : notifySuccess(
                                                  lang == "ar"
                                                    ? "تم تفعيل الاشتراك بنجاح"
                                                    : "Subscription has been activated successfully"
                                                );
                                            refetch();
                                          })
                                          .catch(() => {
                                            notifyError(
                                              lang == "ar"
                                                ? "حدث خطأ اثناء تنفيذ المهمة"
                                                : "An error occurred while executing the task"
                                            );
                                          })
                                          .finally(() => {
                                            refetch();
                                          });
                                        // student?.id
                                      }}
                                      className="settingIcon"
                                      aria-label="suspend"
                                      size="small"
                                      sx={{
                                        bgcolor: "#34aadc",
                                        mx: 0.5,
                                        position: "relative",
                                        "&:hover": {
                                          bgcolor: "#5ac8fa",
                                        },
                                      }}
                                    >
                                      <span className="settingHover">
                                        {student?.deleted_at
                                          ? lang == "ar"
                                            ? "تفعيل"
                                            : "activation"
                                          : lang == "ar"
                                          ? "تعطيل"
                                          : "Disable"}
                                      </span>
                                      {student?.deleted_at ? (
                                        <LockOpenIcon
                                          fontSize="inherit"
                                          sx={{ color: "#fff" }}
                                        />
                                      ) : (
                                        <LockOutlinedIcon
                                          fontSize="inherit"
                                          sx={{ color: "#fff" }}
                                        />
                                      )}
                                    </IconButton>
                                    <IconButton
                                      onClick={() => {
                                        noteSubscribe("subscribtion"),
                                          getStudentId(student?.id);
                                      }}
                                      className="settingIcon"
                                      aria-label="delete"
                                      size="small"
                                      sx={{
                                        bgcolor: "#db3a3c",
                                        mx: 0.5,
                                        position: "relative",
                                        "&:hover": {
                                          bgcolor: "#db3a3c",
                                        },
                                      }}
                                    >
                                      <span className="settingHover">
                                        {lang == "ar" ? "حذف" : "Delete"}
                                      </span>
                                      <DeleteIcon
                                        fontSize="inherit"
                                        sx={{ color: "#fff" }}
                                      />
                                    </IconButton>
                                  </>
                                )}
                              </Box>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ))}
                    </>
                  ) : (
                    <CustomNoData
                      rowText={
                        lang == "ar" ? "لا يوجد كورسات" : "There are no courses"
                      }
                      numOfCells={7}
                    />
                  )}
                </>
              )}
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
}

StudentSubscribtions.propTypes = {
  id: PropTypes.any,
  closeNote: PropTypes.any,
  noteBook: PropTypes.any,
};
