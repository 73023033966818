import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ClipLoader } from "react-spinners";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { notifySuccess } from "@shared/Toast/toast";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import { SharedTextField } from "@shared/SharedTextField/SharedTextField";

export default function DeleteAccountFake() {
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm();

  console.log(errors);

  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          console.log(data);
        })}
        style={{
          display: "flex",
          height: "60vh",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          padding: 2,
          width: "40%",
          margin: "auto",
          gap: "1.5rem",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Noor-Bold",
            fontSize: "36px",
            color: "#2a2a2a",
            textShadow: "0px 3px 0px #22A770",
            textAlign: "center",
          }}
        >
          حذف الطالب
        </Typography>
        <SharedTextField
          register={register("phone", {
            required: "هذا الحقل مطلوب",
            minLength: {
              value: 11,
              message: "يجب ان يتكون رقم الهاتف من 11 رقم",
            },
            maxLength: {
              value: 11,
              message: "يجب ان يتكون رقم الهاتف من 11 رقم",
            },
            pattern: {
              value: /^01[0-2,5]{1}[0-9]{8}$/,
              message: "رقم الهاتف غير صحيح",
            },
          })}
          extra={{
            error: !!errors.phone,
            helperText: errors.phone?.message,
            fullWidth: true,
            label: "رقم الطالب",
            placeholder: "ادخل رقم الطالب",
            type: "number",
          }}
        />
        <DeleteStudent data={watch("phone")} />
      </form>
    </>
  );
}

function DeleteStudent({ data }) {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const timerRef = useRef(null);

  useEffect(() => {
    const timer = timerRef.current;
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  });

  const error = data?.length !== 11 || data?.[0] !== "0" || data?.[1] !== "1";

  return (
    <>
      <ColorButton
        type="submit"
        disabled={isLoading}
        onClick={() => {
          if (!error) {
            setIsConfirmOpen(true);
          }
        }}
        sx={{
          width: "20%",
          color: "#fff",
          bgcolor: "#d32f2f",
          "&:hover": {
            bgcolor: "#b71c1c",
            color: "#fff",
          },
        }}
      >
        {isLoading ? <ClipLoader color="#fff" size={20} /> : "حذف"}
      </ColorButton>
      <Dialog
        maxWidth="sm"
        fullWidth
        onClose={() => setIsConfirmOpen(false)}
        open={isConfirmOpen}
      >
        <DialogTitle sx={{ borderTop: "#22a770 5px solid" }}>
          <Button onClick={() => setIsConfirmOpen(false)}>
            <CloseIcon />
          </Button>
        </DialogTitle>{" "}
        <DialogContent
          sx={{
            borderBottom: "#22a770 5px solid",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              mt: 2,
              fontSize: "28px",
              fontFamily: "Noor-bold",
              mb: 2,
            }}
          >
            هل تريد حذف هذا الطالب؟
          </Typography>
          <Box
            sx={{
              my: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <ColorButton
              disabled={isLoading}
              onClick={() => {
                setIsLoading(true);
                timerRef.current = setTimeout(() => {
                  setIsLoading(false);
                  setIsConfirmOpen(false);
                  notifySuccess("تم حذف الطالب بنجاح");
                }, 1000);
              }}
              sx={{
                color: "#fff",
                bgcolor: "#d32f2f",
                "&:hover": {
                  bgcolor: "#b71c1c",
                  color: "#fff",
                },
              }}
            >
              {isLoading ? <ClipLoader color="#fff" size={20} /> : "حذف"}
            </ColorButton>
            <ColorButton onClick={() => setIsConfirmOpen(false)}>
              رجوع
            </ColorButton>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
