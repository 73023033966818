// import useGetSystemNumbers from "../../shared/axios/Home/hooks/useGetSystemNumbers";
import { Box } from "@mui/material";
import { useState } from "react";
import AreaChartComp from "./atoms/AreaChartComp";
import useCustomQuery from "@/hooks/useCustomQuery";
import { COLORS } from "@/constants/Colors/colors";

const monthMap = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

export default function Chart() {
  const { data: systemData, isLoading: systemLoading } = useCustomQuery({
    url: "system/numbers",
    key: "systemNumbers",
    select: (data) => data?.data?.data,
  });
  const [chartCreatedData, setChartCreatedData] = useState();
  const [chartActivatedData, setChartActivatedData] = useState();
  const [chartCoursesData, setChartCoursesData] = useState();
  const [selectedLabel, setSelectedLabel] = useState("الأكواد المنشأة");
  const [selectedColor, setSelectedColor] = useState("#2f8bc8");
  const [chartCourseData, setChartCourseData] = useState([]);
  if (systemData && !chartCreatedData) {
    const newCreatedData = [];
    const newActivatedData = [];

    for (let i = 0; i < systemData?.created_stats?.length; i++) {
      newCreatedData.push({
        name: monthMap[systemData?.created_stats?.[i]?.month],
        label: "الأكواد المنشأة",
        value: systemData?.created_stats?.[i]?.count || 0,
      });

      newActivatedData.push({
        name: monthMap[systemData?.created_stats?.[i]?.month],
        label: "الأكواد المفعلة",
        value: systemData?.activated_stats?.[i]?.count || 0,
      });
    }

    const newCoursesData = [];
    systemData?.created_courses?.forEach((element) => {
      newCoursesData.push({
        name: monthMap[element?.month],
        label: "الكورسات المنشأة",
        value: element?.count,
      });
    });
    setChartCourseData((prevData) => [...prevData, ...newCreatedData]);
    setChartCourseData((prevData) => [...prevData, ...newActivatedData]);
    setChartCourseData((prevData) => [...prevData, ...newCoursesData]);

    setChartCreatedData(newCreatedData);
    setChartActivatedData(newActivatedData);
    setChartCoursesData(newCoursesData);
  }
  return (
    <Box sx={{ fontFamily: "Noor-regular" }}>
      {systemLoading && (
        <AreaChartComp data={[]} label={""} color={COLORS.PRIMARY.MAIN} />
      )}
      <AreaChartComp data={chartCourseData} color={COLORS.PRIMARY.MAIN} />
    </Box>
  );
}
