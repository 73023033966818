import Numbers from "../components/Numbers/Numbers";
import Notifications from "../components/Notifications/Notifications";
import { Box } from "@mui/material";
import { Bars } from "../components/Bars/Bars";
import { TopSubscriptions } from "../components/TopNumbers/TopSubscriptions";
import { TopStudents } from "../components/TopNumbers/TopStudents";
import { useGetData } from "@/hooks/useGetData";
import { features } from "@config/features";

export const DashboardHome = () => {
  return (
    <Box sx={{ fontFamily: "Noor-regular" }}>
      <Numbers />
      <TopNumbers />
      <Box sx={{ display: "flex", gap: 2 }}>
        <Bars />
        {features.notifications&&(
        <Notifications />
        )}
      </Box>
    </Box>
  );
};

const TopNumbers = () => {
  const { data } = useGetData("system/newest", "newest");

  return (
    <Box sx={{ display: "flex", gap: 2, mb: 5 }}>
      <TopSubscriptions data={data?.data?.mostSubscribedCourses} />
      <TopStudents data={data?.data?.newestStudent} />
    </Box>
  );
};
