import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";

import { Provider } from "react-redux";
import { store } from "@/store/store";
// worker.start();
const Root = () => {
  // Using useSelector to access the global state and extract the language
  return (
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  );
};

// Rendering the Root component
ReactDOM.createRoot(document.getElementById("root")).render(<Root />);
