import ColorButton from "@/components/Buttons/ColorButton/ColorButton";
import Row from "@/components/Layout/Row/Row";
import Paginator from "@/components/Pagination/Paginator";
import Column from "@/components/Layout/Column/Column";
import useCustomQuery from "@/hooks/useCustomQuery";
import { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableContainer,
  TextField,
  Typography,
} from "@mui/material";
import CustomTableHead from "@/components/Layout/TableHead/CustomTableHead";
import CustomTableRow from "@/components/Layout/TableRow/CustomTableRow";
import CustomNoData from "@/components/Layout/NoDataTableRow/CustomNoData";
import CustomTableLoading from "@/components/Layout/TableRowLoading/CustomTableLoading";
import { COLORS } from "@/constants/Colors/colors";
import { FONT_SIZES } from "@/constants/Fonts/font_sizes";
import SearchIcon from "@mui/icons-material/Search";
import AddStudent from "../components/AddStudent";
import { useSelector } from "react-redux";

export default function StudentsHomePage() {
  const lang = useSelector((state) => state?.global?.lang);
  const [page, setPage] = useState("");

  const [keyQuery, setKeyQuery] = useState(0);
  const [inputValue, setInputValue] = useState(0);

  const {
    data: tableData,
    isPending,
    refetch,
  } = useCustomQuery({
    url: `users/search?phone=${keyQuery}&page=${page}`,
    key: ["students", keyQuery],
    page: page,
    lang,
  });

  const tableHeader = [
    lang == "ar" ? "الرقم" : "Number",
    lang == "ar" ? "اسم الطالب" : "Student name",
    lang == "ar" ? "رقم الطالب" : "Student number",
    lang == "ar" ? "رقم ولي الأمر" : "Parent phone",
    lang == "ar" ? "المحافظة" : "city",
    lang == "ar" ? "البريد الالكتروني" : "Email",
    lang == "ar" ? "الإعدادات" : "Settings",
  ];

  const searchHandler = () => {
    setKeyQuery(inputValue);
  };

  const [openAddStudent, setOpenAddStudent] = useState(false);
  const addStudent = () => {
    setOpenAddStudent(true);
  };

  const closeAddStudent = () => {
    setOpenAddStudent(false);
  };

  return (
    <>
      <Row spacing={"space-between"} align={"flex-start"}>
        <Column
          gap={1}
          spacing={"space-between"}
          align={"flex-start"}
          width={"100%"}
        >
          <Row
            spacing={"space-between"}
            mb={2}
            sx={{
              pt: "30px",
            }}
          >
            <Typography
              fontFamily={"Noor-bold"}
              fontSize={32}
              sx={{ textShadow: "0px 2px 0px #2f8bc8" }}
            >
              {lang == "ar" ? "الطلاب" : "Students"}
            </Typography>
            <Box
              sx={{
                width: "300px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: lang == "ar" ? "unset" : "auto",
              }}
            >
              <TextField
                sx={{
                  direction: lang == "ar" ? "rtl" : "ltr",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "flex-end",
                  color: "white",
                  backgroundColor: "white",
                  "& .MuiInputBase-input": {
                    padding: "7px",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                placeholder={
                  lang == "ar"
                    ? "ابحث عن الطالب برقم الهاتف"
                    : "Find the student by phone number"
                }
                InputProps={{
                  style: { fontSize: FONT_SIZES.REGULAR, color: "black" },
                  endAdornment: (
                    <InputAdornment position={lang == "ar" ? "start" : "end"}>
                      <IconButton></IconButton>
                      <Button
                        sx={{
                          color: "white",
                          backgroundColor: COLORS.PRIMARY.MAIN,
                          padding: "8px",
                          "&:hover": {
                            backgroundColor: "green",
                          },
                        }}
                      >
                        <SearchIcon />
                      </Button>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setInputValue(e.target.value);
                  if (!e.target.value) {
                    setKeyQuery(0);
                    setInputValue(0);
                  }
                }}
                onClick={searchHandler}
              />
            </Box>
          </Row>

          <Row
            spacing={"space-between"}
            mb={2}
            sx={{
              pt: "20px",
              pb: "20px",
            }}
          >
            <ColorButton width={"auto"} onClick={() => setOpenAddStudent(true)}>
              {lang == "ar" ? "انشاء طالب جديد" : "Create Student"}
            </ColorButton>

            <AddStudent
              addStudent={addStudent}
              openAddStudent={openAddStudent}
              closeAddStundet={closeAddStudent}
            />

            <Paginator
              page={tableData?.data?.current_page}
              totalPages={tableData?.data?.last_page}
              setPage={setPage}
            />
          </Row>

          <Row>
            <TableContainer component={Paper}>
              <Table>
                <CustomTableHead
                  headBackground={COLORS.PRIMARY.MAIN}
                  tableHeaders={tableHeader}
                />

                {isPending ? (
                  <CustomTableLoading
                    width={"100%"}
                    height={60}
                    numOfCells={7}
                  />
                ) : (
                  <>
                    {tableData?.data?.data?.length > 0 ? (
                      <>
                        {tableData?.data?.data?.map((cell, index) => (
                          <CustomTableRow
                            key={cell.id}
                            page={page}
                            index={index}
                            name={cell.name}
                            phone={cell.phone}
                            email={cell.email}
                            parent_phone={cell.parent_phone}
                            city={cell.city}
                            id={cell.id}
                            refetch={refetch}
                            banned_until={cell.banned_until}
                            money={cell.money}
                          />
                        ))}
                      </>
                    ) : (
                      <CustomNoData
                        rowText={
                          lang == "ar"
                            ? "لا يوجد طلاب"
                            : "There are no students"
                        }
                        numOfCells={7}
                      />
                    )}
                  </>
                )}
              </Table>
            </TableContainer>
          </Row>
        </Column>
      </Row>
    </>
  );
}
