import { COLORS } from "@/constants/Colors/colors";
import { FONT_SIZES } from "@/constants/Fonts/font_sizes";
import {
  Box,
  Button,
  Grid,
  Stack,
  Table,
  TableContainer,
  Typography,
} from "@mui/material";
import useCustomQuery from "@/hooks/useCustomQuery";
import Column from "@/components/Layout/Column/Column";
import CustomNoData from "@/components/Layout/NoDataTableRow/CustomNoData";
import Row from "@/components/Layout/Row/Row";
import CustomTableHead from "@/components/Layout/TableHead/CustomTableHead";
import CustomTableRowSupervisor from "@/components/Layout/TableRow/CustomTableRowSupervisor";
import CustomTableLoading from "@/components/Layout/TableRowLoading/CustomTableLoading";
import Paginator from "@/components/Pagination/Paginator";
import { useState } from "react";
import AddSupervisor from "@/modules/supervisor/components/AddSupervisor";
import { useSelector } from "react-redux";

export default function SupervisorsPage() {
  const globalState = useSelector((state) => state?.global);
  const lang = useSelector((state) => state?.global?.lang);
  const tableHeader = [
    lang == "ar" ? "الرقم" : "Number",
    lang == "ar" ? "الاسم" : "Name",
    lang == "ar" ? "رقم التليفون" : "Phone number",
    lang == "ar" ? "الدور" : "The role",
    lang == "ar" ? "الإعدادات" : "Settings",
  ];
  const [page, setPage] = useState(1);

  const {
    data: tableData,

    isLoading,
    refetch,
  } = useCustomQuery({
    url: `supervisors/getAll?page=${page}&limit=10`,
    key: ["supervisors", lang],
    page: page,
    lang,
  });

  const [openModal, setOpenModal] = useState(false);

  return (
    <Box sx={{ height: "77vh" }}>
      <AddSupervisor
        refetch={refetch}
        closeModal={() => setOpenModal(false)}
        modal={openModal}
      />
      <Grid item={true} xs={12} sm={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "20px",
            marginBottom: "20px",
            alignItems: "center",
          }}
        >
          <Box>
            <Stack spacing={2} direction="row">
              <Typography
                fontFamily={"Noor-bold"}
                fontSize={FONT_SIZES.LARGE}
                sx={{ color: COLORS.DARK_GRAY }}
              >
                {lang == "ar" ? "المسؤولين" : "Supervisors"}
              </Typography>
            </Stack>
          </Box>

          <Box>
            <Button
              onClick={() => setOpenModal(true)}
              sx={{
                background: COLORS.PRIMARY.MAIN,
                width: "200px",
                padding: "10px 25px",
                color: "white",
                cursor: "pointer",
                transition: "0.4s",
                "&:hover": {
                  background: "white",
                  color: COLORS.PRIMARY.MAIN,
                },
                position: "relative",
              }}
            >
              {lang == "ar" ? "انشاء مشرف جديد" : "Create supervisors"}
            </Button>
          </Box>
        </Box>
        <Row>
          <Column
            gap={1}
            spacing={"space-between"}
            align={"flex-start"}
            width={"100%"}
            marginBottom={"20px"}
          >
            <Paginator
              page={page}
              totalPages={tableData?.last_page}
              setPage={setPage}
            />
          </Column>
        </Row>
        <Row>
          <Column
            gap={1}
            spacing={"space-between"}
            align={"flex-start"}
            width={"100%"}
          >
            <TableContainer>
              <Table>
                <CustomTableHead
                  headBackground={COLORS.PRIMARY.MAIN}
                  tableHeaders={tableHeader}
                />

                {isLoading ? (
                  <CustomTableLoading
                    width={"100%"}
                    height={60}
                    numOfCells={7}
                  />
                ) : (
                  <>
                    {tableData?.data?.data.length > 0 ? (
                      <>
                        {tableData?.data?.data.map((supervisor, i) => (
                          <CustomTableRowSupervisor
                            refetch={refetch}
                            id={supervisor.id}
                            key={supervisor.id || "-"}
                            number={supervisor?.id || "-"}
                            name={supervisor?.name}
                            phone={supervisor?.phone}
                            role={
                              (supervisor.pivot.role_id =
                                lang == "ar" ? "مشرف" : "Supervisors")
                            }
                          />
                        ))}
                      </>
                    ) : (
                      <CustomNoData
                        rowText={
                          lang == "ar"
                            ? "لا يوجد مشرفين"
                            : "There are no moderators"
                        }
                        numOfCells={7}
                      />
                    )}
                  </>
                )}
              </Table>
            </TableContainer>
          </Column>
        </Row>
      </Grid>
    </Box>
  );
}
