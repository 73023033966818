import { COLORS } from "@/constants/Colors/colors";
import { FONTS } from "@/constants/Fonts/fonts";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import { useNavigate, useLocation } from "react-router-dom";

export default function MyListItem({
  navigatePath,
  locationPath,
  img,
  text,
  roles,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const { role } = currentUser();

  const inLocation = Array.isArray(locationPath)
    ? locationPath?.includes(location.pathname)
    : locationPath === location.pathname;

  return (
    roles.includes(role) && (
      <ListItem
        disablePadding
        onClick={() => navigate(navigatePath)}
        sx={{
          borderRadius: "20px",
          background: `${inLocation && COLORS.PRIMARY.LIGHT}`,
          color: `${inLocation && COLORS.PRIMARY.MAIN}`,
          fontFamily: FONTS.REGULAR,
          "&:hover": {
            color: COLORS.WHITE,
            bgcolor: COLORS.SECONDARY.MAIN,
            transition: "background-color 0.3s",
          },
        }}
      >
        <ListItemButton
          sx={{
            width: "100%",
            paddingY: "4px",
          }}
        >
          <ListItemIcon sx={{ paddingRight: 3 }}>
            <img src={img} alt="" width={25} />
          </ListItemIcon>

          <ListItemText
            disableTypography
            primary={text}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "start",
              paddingX: 1,
            }}
          />
        </ListItemButton>
      </ListItem>
    )
  );
}
