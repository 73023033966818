import { Box, Typography } from "@mui/material";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import loginImage from "@assets/images/Exams-bro.png";
import logo from "@assets/images/logo.png";
import UnstyledLink from "@shared/Links/UnstyledLink";
import TextButton from "@/components/Buttons/TextButton/TextButton";
import ColorButton from "@/components/Buttons/ColorButton/ColorButton";
import { COLORS } from "@/constants/Colors/colors";
import { FONT_SIZES } from "@/constants/Fonts/font_sizes";
import { FONTS } from "@/constants/Fonts/fonts";
import BorderedInput from "@/components/Form/BorderedInput/BorderedInput";
import { useForm, FormProvider } from "react-hook-form";
import ColorCheckBox from "@/components/Form/ColorCheckBox/ColorCheckBox";
import { zodResolver } from "@hookform/resolvers/zod";
import { loginSchema } from "../../../shema/login.schema";
import { useLogin } from "../../../hooks/login.hook";
import Column from "@/components/Layout/Column/Column";
import Row from "@/components/Layout/Row/Row";
import { features } from "@config/features";
import { useSelector } from "react-redux";
export default function Login() {
  const { isPending, onSubmit } = useLogin();
  const globalState = useSelector((state) => state.global);
  const lang = globalState?.lang;

  const methods = useForm({
    resolver: zodResolver(loginSchema),
  });
  const { handleSubmit } = methods;

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-around"}
        alignItems={"center"}
        position={"relative"}
        overflow={"hidden"}
        height={"100vh"}
        sx={{
          bgcolor: globalState?.isDarkMode ? COLORS.SOFT_DARK : COLORS.WHITE,
        }}
      >
        <Box width={"100%"} position={"absolute"} top={"40px"} right={"60px"}>
          <img
            src={logo}
            alt="logo"
            style={{
              width: "10%",
              padding: "15px",
            }}
          />
        </Box>

        <img
          src={loginImage}
          style={{
            zIndex: 2,
            width: "35%",
            paddingBottom: 20,
          }}
        />

        <Column spacing={"space-between"} width={"50%"} gap={4}>
          <Typography
            fontSize={50}
            textAlign={"center"}
            fontFamily={FONTS.REGULAR}
            // color={COLORS.DARK_GRAY}
          >
            {lang == "ar" ? "تسجيل الدخول" : "Login"}
          </Typography>

          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                width: "60%",
                margin: "auto",
                display: "flex",
                flexDirection: "column",
                gap: 20,
              }}
            >
              <BorderedInput
                placeholder={lang == "ar" ? "رقم الهاتف" : "Phone number"}
                registerName="phone"
                Icon={PhoneInTalkOutlinedIcon}
                lang={lang}
              />
              <BorderedInput
                placeholder={lang == "ar" ? "كلمة المرور" : "Password"}
                registerName="password"
                type="password"
                Icon={LockOutlinedIcon}
                lang={lang}
              />
              <Row
                spacing={"space-between"}
                width={"100%"}
                sx={{ direction: lang == "ar" ? "rtl" : "ltr" }}
              >
                <ColorCheckBox
                  label={lang == "ar" ? "تذكرني دائما" : "Remember me"}
                />

                <Typography
                  fontFamily={FONTS.BOLD}
                  fontSize={FONT_SIZES.SMALL}
                  // color={COLORS.DARK_GRAY}
                >
                  <UnstyledLink to={"/enter-phone-number"}>
                    {lang == "ar"
                      ? "نسيت كلمة المرور؟"
                      : "Forgot your password?"}
                  </UnstyledLink>
                </Typography>
              </Row>

              <ColorButton
                width={"100%"}
                colorType="submit"
                type="submit"
                isLoading={isPending}
                sx={{ color: "white" , bgcolor:`${COLORS.PRIMARY.MAIN}` ,"&:hover": {
                  backgroundColor: `${COLORS.SECONDARY.MAIN}`,
                  color: "white",
                },}}
              >
                {lang == "ar" ? "تسجيل الدخول" : "Login"}
              </ColorButton>
              {/* <ColorButton
                onClick={() => {
                  // throw new Error("error");
                  // try {
                  //   throw new Error("error");
                  // } catch (err) {
                  //   Sentry.captureException(err);
                  // }
                }}
                width={"100%"}
                isLoading={isPending}
              >
                error
              </ColorButton> */}
              {features.student && (
                <TextButton sx={{ color: "white " }}>
                  <UnstyledLink to={"/signup"}>
                    {lang == "ar" ? "او اشترك الان" : "Subscribe now"}
                  </UnstyledLink>
                </TextButton>
              )}
            </form>
          </FormProvider>
        </Column>
      </Box>
    </>
  );
}
