import { COLORS } from "@/constants/Colors/colors";
import {
  Box,
  Button,
  IconButton,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";

import InputAdornment from "@mui/material/InputAdornment";
import React, { useEffect, useRef, useState } from "react";
import { notifySuccess, notifyError } from "@shared/Toast/toast";
import axios from "axios";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import { api_url } from "@/constants/base_url";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import Modal from "@shared/Modal/Modal";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

export default function AddSupervisor({ modal, closeModal, refetch }) {
 const lang = useSelector((state) => state?.global?.lang);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const globalState = useSelector((state) => state?.global);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const timerRef = useRef();
  const {
    register,
    handleSubmit,
    formState,
    control,
    setError,
    clearErrors,
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      name: "",
      phone: "",
      password: "",
      rePassword: "",
    },
  });

  useEffect(() => {
    const timer = timerRef.current;

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      const { name, phone, password, rePassword } = getValues();

      if (name.length <= 4) {
        setError("name", {
          type: "manual",
          message:
            lang == "ar"
              ? "الحروف يجب أن تكون أكثر من 4"
              : "Letters must be more than 4",
        });
        return;
      } else {
        clearErrors("name");
      }

      if (!phone.startsWith("01")) {
        setError("phone", {
          type: "manual",
          message:
            lang == "ar" ? "رقم الهاتف غير صحيح" : "Phone number is incorrect",
        });
        return;
      } else {
        clearErrors("phone");
      }

      if (password.length <= 8 || password !== rePassword) {
        setError("password", {
          type: "manual",
          message:
            lang == "ar"
              ? "كلمة المرور غير صحيحة أو يجب أن تكون أكثر من 8 أحرف"
              : "The password is incorrect or must be more than 8 characters",
        });
        setError("rePassword", {
          type: "manual",
          message:
            lang == "ar"
              ? "كلمة المرور غير صحيحة أو يجب أن تكون أكثر من 8 أحرف"
              : "The password is incorrect or must be more than 8 characters",
        });
        return;
      } else {
        clearErrors("password");
        clearErrors("rePassword");
      }

      const formData = {
        name,
        phone,
        password,
        password_confirmation: rePassword,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${currentUser()?.token}`,
          "Content-Type": "application/json",
          lang: lang,
        },
      };

      const response = await axios.post(
        `${api_url}supervisors/add`,
        formData,
        config
      );
      console.log(response);
      notifySuccess(lang == "ar" ? "تمت الاضافه بنجاح" : "Added successfully");
      closeModal();
      reset();
      refetch();
      setIsLoading(false);
    } catch (error) {
      console.error("Error sending notification:", error);
      notifyError(
        lang == "ar"
          ? "حدث خطا اثناء التنفيذ"
          : "An error occurred while executing the task"
      );
      setIsLoading(false);
    }
  };
  return (
    <Modal maxWidth={"sm"} open={modal} closeFn={closeModal}>
      <Typography
        fontFamily={"Noor-bold"}
        fontSize={30}
        sx={{
          textShadow: "0px 3px 0px #2f8bc8",
          color: globalState?.isDarkMode ? COLORS.WHITE : COLORS.BLACK,
        }}
      >
        {lang == "ar" ? "انشاء مشرف جديد" : "Create Supervisor"}
      </Typography>

      <form
        onClick={handleSubmit(onSubmit)}
        sx={{ width: "85%", margin: "auto" }}
      >
        <Box sx={{ marginY: "20px" }}>
          <TextField
            inputProps={{
              style: {
                WebkitBoxShadow: `0 0 0 1000px ${
                  globalState?.isDarkMode ? "transparent" : COLORS.WHITE
                } inset`,
                WebkitTextFillColor: `${
                  globalState?.isDarkMode ? COLORS.WHITE : "#2a2a2a"
                }`,
              },
            }}
            placeholder={
              lang == "ar" ? "ادخل الاسم بالكامل " : "Enter full name"
            }
            label={lang == "ar" ? " الاسم بالكامل" : "Full name"}
            size="small"
            fullWidth
            {...register("name", {
              required:
                lang == "ar" ? "هذا الحقل مطلوب" : "This field is required",
            })}
            sx={{
              "& .MuiFormLabel-root.Mui-focused": {
                color: globalState?.isDarkMode
                  ? COLORS.WHITE
                  : COLORS.DARK_GRAY,
              },
              "& label": {
                left: lang == "ar" ? "unset" : "0",
                right: lang == "ar" ? "1.75rem" : "unset",
                transformOrigin: lang == "ar" ? "right" : "left",
                fontSize: "0.8rem",
                color: "#2f8bc8",
                bgcolor: COLORS.DARK_GRAY,
              },
              "& legend": {
                textAlign: lang == "ar" ? "right" : "left",
                fontSize: "0.8rem",
              },
              direction: lang == "ar" ? "rtl" : "ltr",
            }}
            InputProps={{
              startAdornment: <InputAdornment position="end"></InputAdornment>,
            }}
          />
          <span
            style={{
              color: "red",
              fontSize: "14px",
              my: "0px",
              textAlign: "end",
            }}
          >
            {formState.errors.name?.message}
          </span>
        </Box>

        <Box sx={{ marginTop: "30px", marginBottom: "20px" }}>
          <TextField
            inputProps={{
              style: {
                WebkitBoxShadow: `0 0 0 1000px ${
                  globalState?.isDarkMode ? "transparent" : COLORS.WHITE
                } inset`,
                WebkitTextFillColor: `${
                  globalState?.isDarkMode ? COLORS.WHITE : "#2a2a2a"
                }`,
              },
            }}
            placeholder={
              lang == "ar" ? "ادخل رقم الهاتف " : "Enter phone number"
            }
            label={lang == "ar" ? " رقم الهاتف" : "Phone number"}
            fullWidth
            multiline
            {...register("phone", {
              required:
                lang == "ar" ? "هذا الحقل مطلوب" : "This field is required",
            })}
            sx={{
              "& .MuiFormLabel-root.Mui-focused": {
                color: globalState?.isDarkMode
                  ? COLORS.WHITE
                  : COLORS.DARK_GRAY,
              },
              "& label": {
                left: lang == "ar" ? "unset" : "0",
                right: lang == "ar" ? "1.75rem" : "unset",
                transformOrigin: lang == "ar" ? "right" : "left",
                fontSize: "0.8rem",
                color: "#2f8bc8",
                bgcolor: COLORS.DARK_GRAY,
              },
              "& legend": {
                textAlign: lang == "ar" ? "right" : "left",
                fontSize: "0.8rem",
              },
              direction: lang == "ar" ? "rtl" : "ltr",
            }}
            InputProps={{
              startAdornment: <InputAdornment position="end"></InputAdornment>,
            }}
          />
          <span
            style={{
              color: "red",
              fontSize: "14px",
              my: "0px",
              textAlign: "end",
            }}
          >
            {formState.errors.phone?.message}
          </span>
        </Box>
        <Box sx={{ display: "flex", gap: "20px", marginTop: "0" }}>
          <Box>
            <InputLabel
              htmlFor="outlined-adornment-password"
              sx={{
                right: "unset",
                left: "-1.5rem",
                top: "0.50rem",
                transformOrigin: "right",
                fontSize: "0.8rem",
                color: globalState?.isDarkMode
                  ? COLORS.WHITE
                  : COLORS.DARK_GRAY,
                backgroundColor: globalState?.isDarkMode
                  ? "transparent"
                  : COLORS.WHITE,
                zIndex: "2",
                width: "80px",
              }}
            >
              {lang == "ar" ? "كلمه السر" : "Password"}
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              placeholder={lang == "ar" ? "ادخل كلمة السر " : "Enter password"}
              fullWidth
              {...register("password", {
                required:
                  lang == "ar" ? "هذا الحقل مطلوب" : "This field is required",
              })}
              sx={{
                "& input": {
                  WebkitBoxShadow: `0 0 0 1000px ${
                    globalState?.isDarkMode ? "transparent" : COLORS.WHITE
                  } inset`,
                  WebkitTextFillColor: globalState?.isDarkMode
                    ? COLORS.WHITE
                    : "#2a2a2a",
                },
                "& .MuiInput-root.Mui-focused": {
                  color: COLORS.DARK_GRAY,
                },
                "& label": {
                  left: lang == "ar" ? "unset" : "0",
                  right: lang == "ar" ? "1.75rem" : "unset",
                  transformOrigin: lang == "ar" ? "right" : "left",
                  fontSize: "0.8rem",
                  color: "#2f8bc8",
                  bgcolor: COLORS.DARK_GRAY,
                },
                "& legend": {
                  textAlign: lang == "ar" ? "right" : "left",
                  fontSize: "0.8rem",
                },
                direction: lang == "ar" ? "rtl" : "ltr",
              }}
              endAdornment={
                <InputAdornment position={lang == "ar" ? "end" : "start"}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <span
              style={{
                color: "red",
                fontSize: "14px",
                my: "0px",
                textAlign: "end",
              }}
            >
              {formState.errors.password?.message}
            </span>
          </Box>
          <Box>
            <InputLabel
              htmlFor="outlined-adornment-password"
              sx={{
                right: "unset",
                left: "-1.9rem",
                top: "0.50rem",
                transformOrigin: "right",
                fontSize: "0.8rem",
                color: globalState?.isDarkMode
                  ? COLORS.WHITE
                  : COLORS.DARK_GRAY,
                backgroundColor: globalState?.isDarkMode
                  ? "transparent"
                  : COLORS.WHITE,
                zIndex: "2",
                width: "90px",
              }}
            >
              {lang == "ar" ? "تاكيد كلمه السر" : "Re-Password"}
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password2"
              type={showPassword ? "text" : "password"}
              placeholder={lang == "ar" ? "ادخل كلمة السر " : "Enter password"}
              fullWidth
              {...register("rePassword", {
                required:
                  lang == "ar" ? "هذا الحقل مطلوب" : "This field is required",
              })}
              sx={{
                "& input": {
                  WebkitBoxShadow: `0 0 0 1000px ${
                    globalState?.isDarkMode ? "transparent" : COLORS.WHITE
                  } inset`,
                  WebkitTextFillColor: globalState?.isDarkMode
                    ? COLORS.WHITE
                    : "#2a2a2a",
                },
                "& .MuiInput-root.Mui-focused": {
                  color: COLORS.DARK_GRAY,
                },
                "& label": {
                  left: lang == "ar" ? "unset" : "0",
                  right: lang == "ar" ? "1.75rem" : "unset",
                  transformOrigin: lang == "ar" ? "right" : "left",
                  fontSize: "0.8rem",
                  color: "#2f8bc8",
                  bgcolor: COLORS.DARK_GRAY,
                },
                "& legend": {
                  textAlign: lang == "ar" ? "right" : "left",
                  fontSize: "0.8rem",
                },
                direction: lang == "ar" ? "rtl" : "ltr",
              }}
              endAdornment={
                <InputAdornment position={lang == "ar" ? "end" : "start"}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <span
              style={{
                color: "red",
                fontSize: "14px",
                my: "0px",
                textAlign: "end",
              }}
            >
              {formState.errors.rePassword?.message}
            </span>
          </Box>
        </Box>

        <Box
          sx={{
            marginTop: "50px",
            marginBottom: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            type="submit"
            sx={{
              background: COLORS.PRIMARY.MAIN,
              width: "250px",
              padding: "10px 25px",
              color: "white",
              cursor: "pointer",
              transition: "0.4s",
              "&:hover": {
                background: "white",
                color: COLORS.PRIMARY.MAIN,
              },
            }}
          >
            {lang == "ar" ? "انشاء" : "Create"}
            {/* {isLoading ? <HashLoader color="#ffffff" size={24} /> : "انشاء"} */}
          </Button>
        </Box>
      </form>
    </Modal>
  );
}
AddSupervisor.propTypes = {
  modal: PropTypes.bool,
  closeModal: PropTypes.func,
  refetch: PropTypes.func,
};
