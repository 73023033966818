import { useRouteError } from "react-router-dom";

import { Box, Typography } from "@mui/material";

export default function Error() {
  const error = useRouteError();

  const { errorMsg, lines } = prettyError(error);
  // console.log("error", errorMsg);
  // lines.forEach((line, i) => console.log(i + 1, "-line: ", line));

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"end"}
        flexDirection={"column"}
        color={"black"}
        px={8}
        mt={4}
      >
        <Typography mb={4} fontSize={30} fontFamily={"Montserrat"}>
          {errorMsg}
        </Typography>
        {lines.map((line) => (
          <Typography
            sx={{
              direction: "ltr",
            }}
            key={line}
            fontSize={20}
            fontFamily={"Montserrat"}
          >
            {line}
          </Typography>
        ))}
      </Box>
    </>
  );
}

function prettyError(errorArg) {
  const stack = errorArg?.stack;
  if (!stack)
    return {
      errorMsg: errorArg?.data,
      lines: ["error", "stack is undefined"],
    };

  const error = stack.split("\n").map((x) => x.trim());

  const errorMsg = error[0];

  const lines = error.slice(1, 3).map((line) => {
    const componentName = line.split(" ").slice(0, 2).join(" ");
    const componentPath = line.split("/").slice(3).join("/").split("?")[0];
    const lineNumber = line.split(":").slice(-2).join(":");

    return `${componentName} → ${componentPath} line: ${lineNumber}`;
  });

  return {
    errorMsg,
    lines,
  };
}
