import { Close } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import { notifySuccess } from "../../../Admin/Shared/Toast/toast";
import { notifyError } from "@shared/Toast/toast";
import { api_url } from "@/constants/base_url";
import { COLORS } from "@/constants/Colors/colors";
import { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from 'react-redux';

function DeleteSupervisor({ noteBook, supervisorId, closeNote, refetch }) {
 const lang = useSelector((state) => state?.global?.lang);
  const [isLoading, setIsLoading] = useState(false);

  const { mutate, isPending } = useMutation({
    mutationFn: (formData) =>
      axios.post(`${api_url}supervisors/delete`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${currentUser()?.token}`,
          lang: lang,
        },
      }),
    onSuccess: () => {
      closeNote();
      refetch();
      notifySuccess(
        lang == "ar" ? "تم حذف المشرف" : "Supervisor has been deleted"
      );
    },
    onError: (err) => {
      console.log(supervisorId);
      console.log(err);
      notifyError(
        lang == "ar"
          ? "حدث خطأ اثناء تنفيذ المهمة"
          : "An error occurred while executing the task"
      );
    },
  });

  const handleDelete = () => {
    if (supervisorId) {
      const formData = {
        supervisor_id: supervisorId.toString(),
      };
      mutate(formData);
    } else {
      console.error("User id is missing or invalid.");
    }
  };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          bgcolor: "#000000ab",
          zIndex: "1501",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "0.3s",
          opacity: noteBook === "del-book" ? "1" : "0",
          visibility: noteBook === "del-book" ? "visible" : "hidden",
        }}
      >
        <Box
          sx={{
            bgcolor: "white",
            padding: "30px",
            borderRadius: "10px",
            width: "400px",
            height: "auto",
            textAlign: "center",
            position: "relative",
            transition: "0.3s",
            opacity: noteBook === "del-book" ? "1" : "0",
            transform: noteBook === "del-book" ? "scale(1)" : "scale(0)",
          }}
        >
          <div
            className="close-btn"
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              zIndex: 100,
            }}
          >
            <Close
              onClick={closeNote}
              sx={{
                color: "black",
                width: 30,
                height: 30,
                transition: "0.4s",
                cursor: "pointer",
                "&:hover": {
                  color: COLORS.PRIMARY.MAIN,
                },
              }}
            />
          </div>

          <Typography
            fontFamily={"Noor-bold"}
            fontSize={30}
            sx={{ textShadow: `0px 3px 0px ${COLORS.PRIMARY.MAIN}` }}
          >
            {lang == "ar"
              ? "هل تريد حذف هذا المشرف"
              : "Do you want to delete this supervisor?"}
          </Typography>

          <Box
            mt={5}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alidngItems: "center",
            }}
          >
            <Button
              onClick={handleDelete}
              sx={{
                padding: "7px 20px",
                width: "49%",
                bgcolor: "darkred",
                color: "white",
                trnsition: "0.4s",
                "&:hover": {
                  bgcolor: "red",
                },
              }}
            >
              {lang == "ar" ? "أوافق" : "Accept"}
              {/* {isPending ? <HashLoader color="#ffffff" size={24} /> : "أوافق"} */}
            </Button>
            <Button
              onClick={closeNote}
              sx={{
                padding: "7px 20px",
                width: "49%",
                bgcolor: "gray",
                color: "white",
                trnsition: "0.4s",
                "&:hover": {
                  bgcolor: "lightgray",
                },
              }}
            >
              {lang == "ar" ? "الغاء" : "Cancel"}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

DeleteSupervisor.propTypes = {
  noteBook: PropTypes.bool,
  closeNote: PropTypes.func,
  refetch: PropTypes.func,
  supervisorId: PropTypes.number,
};

export default DeleteSupervisor;
