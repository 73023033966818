import { CourseHeader } from "../components/CourseHeader";
import { Box } from "@mui/material";
import { CourseModules } from "../components/CourseModules";
import { CourseFiles } from "../components/CourseFiles";
import { CourseExams } from "../components/CourseExams";
import { CourseStudents } from "../components/CourseStudents";
import useCustomQuery from "@/hooks/useCustomQuery";
import { useParams } from "react-router-dom";

export const Course = () => {
  const { id } = useParams();
  const { data, isPending } = useCustomQuery({
    url: `course/courseData?id=${id}`,
    key: ["course", id],
    method: "post",
  });
  return (
    <Box sx={{ width: "100%", minHeight: "100vh" }}>
      <CourseHeader data={data} isPending={isPending} />
      <Box
        sx={{
          width: "100%",
          minHeight: 400,
          display: "flex",
          alignItems: "center",
          gap: 5,
        }}
      >
        <CourseModules course={data} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 5,
            width: "50%",
            alignItems: "center",
          }}
        >
          <CourseFiles />
          <CourseExams />
        </Box>
      </Box>
      <CourseStudents courseData={data} />
    </Box>
  );
};
