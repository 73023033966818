import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Pagination,
  Skeleton,
  Typography,
} from "@mui/material";
import { BookCard } from "@shared/Cards/BookCard/BookCard";
import { api_url } from "@/constants/base_url";
import DeleteBook from "../components/DeleteBook";
import CreateBook from "../components/CreateBook";
import EditBook from "../components/EditBook";
import useCustomQuery from "@/hooks/useCustomQuery";
import { COLORS } from "@/constants/Colors/colors";
import { useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";

export const BookStorePage = () => {
  // all books
 const lang = useSelector((state) => state?.global?.lang);
  const globalState = useSelector((state) => state?.global);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const { data, error, isLoading, isError, refetch } = useCustomQuery({
    url: `${api_url}${
      selectedTeacher === null || selectedTeacher === ""
        ? `book/getAllBooks?page=${page}`
        : `book/getAllBooks?instructor_name=${selectedTeacher}&page=${page}`
    }`,
    key: ["books", page, selectedTeacher, lang],
    page: page,
    lang,
    select: (data) => data,
  });

  if (isError) console.log("my subs error", error);
  if (!isLoading && data?.data?.data?.length === 0);

  useEffect(() => {
    if (
      data?.data?.data?.last_page &&
      data?.request?.responseURL
      // data?.request?.responseURL.indexOf("teacher_name")
    ) {
      const totalCount = data?.data?.data?.last_page;
      setTotalPages(totalCount);
    }
  }, [data]);

  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.refetchQueries(["books", page]);
  }, [queryClient]);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const [noteBook, setNoteBook] = useState("");
  const [bookId, setBookId] = useState();

  const getBookId = (id) => {
    setBookId(id);
  };

  const openNote = (note) => {
    setNoteBook(note);
  };

  const closeNote = () => {
    setNoteBook("");
  };

  const getSelectedTeacher = (name) => {
    setSelectedTeacher(name);
  };

  const [title, setTitle] = useState();
  const [price, setPrice] = useState();
  const [teacher, setTeacher] = useState();
  const [num, setNum] = useState();
  const [img, setImg] = useState();
  const [isOpenForReservationState, setIsOpenForReservationState] = useState();
  const [availableAmount, setAvailableAmount] = useState();

  const getData = (title, price, teacher, num, img, state, availableAmount) => {
    setTitle(title);
    setPrice(price);
    setTeacher(teacher);
    setNum(num);
    setImg(img);
    setIsOpenForReservationState(state ? "1" : "0");
    setAvailableAmount(availableAmount);
  };

  return (
    <>
      <Box>
        <DeleteBook
          bookId={bookId}
          closeNote={closeNote}
          noteBook={noteBook}
          refetch={refetch}
        />
        <CreateBook
          closeNote={closeNote}
          noteBook={noteBook}
          refetch={refetch}
        />
        <EditBook
          closeNote={closeNote}
          noteBook={noteBook}
          refetch={refetch}
          title={title}
          price={price}
          teacher={teacher}
          num={num}
          img={img}
          bookId={bookId}
          isOpenForReservation={isOpenForReservationState}
          availableAmount={availableAmount}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginY: "30px",
          }}
        >
          <Typography
            fontFamily={"Noor-bold"}
            fontSize={32}
            sx={{ textShadow: `0px 2px 0px ${COLORS.PRIMARY.MAIN}` }}
          >
            {lang == "ar" ? "متجر الكتب" : "Book store"}
          </Typography>

          <Button
            onClick={() => openNote("create-book")}
            sx={{
              background: COLORS.PRIMARY.MAIN,
              width: "200px",
              padding: "10px 25px",
              color: "white",
              cursor: "pointer",
              transition: "0.4s",
              "&:hover": {
                background: "white",
                color: COLORS.PRIMARY.MAIN,
              },
              // zIndex:0,
              position: "relative",
            }}
          >
            {lang == "ar" ? "انشاء كتاب جديد" : "Create New Book"}
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
            margin: "30px 0px",
          }}
        >
          {/* <BookTeachers
            getSelectedTeacher={getSelectedTeacher}
            selectedTeacher={selectedTeacher}
          /> */}

          <Pagination
            count={totalPages}
            color="primary"
            shape="rounded"
            onChange={handlePageChange}
          />
        </Box>

        <Box>
          {isLoading ? (
            <Grid
              container
              // spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 6, lg: 12, xl: 16 }}
            >
              {Array(4)
                .fill(0)
                .map((i, index) => (
                  <Grid
                    key={index}
                    item
                    xs={2}
                    sm={3}
                    lg={3}
                    xl={4}
                    marginBottom={3}
                  >
                    <Skeleton
                      width={"95%"}
                      height={500}
                      sx={{ marginY: "-100px" }}
                    />
                  </Grid>
                ))}
            </Grid>
          ) : (
            <>
              {data?.data?.data?.data?.length != 0 ? (
                <Grid
                  container
                  // spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 6, lg: 12, xl: 16 }}
                >
                  {data?.data?.data?.data?.map((book, index) => {
                    return (
                      <Grid
                        key={book.id}
                        item
                        xs={2}
                        sm={3}
                        lg={4}
                        marginBottom={3}
                      >
                        <BookCard
                          id={book.id}
                          img={`${api_url.slice(0, -7)}${book.image}`}
                          title={book.title}
                          price={book.price}
                          teacherName={book.instructor_name}
                          reserveNum={book.reservation_number}
                          availableAmount={book?.available_amount}
                          isOpenForReservation={book.isOpenForReservation}
                          bookLink={`/dashboard/book-store/order/${book.id}`}
                          delFunc={openNote}
                          getIdFunc={getBookId}
                          editFunc={openNote}
                          editData={getData}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    padding: " 20px",
                    borderRadius: "10px",
                    bgcolor: "white",
                  }}
                >
                  <Typography
                    fontFamily={"Noor-bold"}
                    fontSize={32}
                    sx={{
                      textShadow: `0px 2px 0px ${COLORS.PRIMARY.MAIN}`,
                      color: globalState?.isDarkMode
                        ? COLORS.WHITE
                        : COLORS.DARK_GRAY,
                    }}
                  >
                    لا توجد كتب
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};
