import { atom } from "recoil";
import CryptoJS from "crypto-js";
import { api_url } from "@/constants/base_url";

export function getImageUrl(image) {
  if (!image) return "";
  if (typeof image !== "string") return image[0]?.path || "";
  if (image.startsWith("http")) return image;
  return `${api_url.slice(0, -8)}${image}`;
}

const SECRETE_KEY = "EYAD_ELKREEZA";

export function isTodayAfterDate(pastDate) {
  const pastDateObj = new Date(pastDate);
  const today = new Date();
  return today > pastDateObj;
}

export function decrypt(data) {
  if (!data) return null;
  return JSON.parse(
    CryptoJS.AES.decrypt(data, SECRETE_KEY).toString(CryptoJS.enc.Utf8)
  );
}

export function currentDashboardUser() {
  return decrypt(localStorage.getItem("first-step-admin-token"));
}

export const deleteCourseAtom = atom({
  key: "deleteCourse",
  default: 0,
});

export const activeComponentAtom = atom({
  key: "activeComponent",
  default: "Component1",
});

export function trimLongText(text, length = 10) {
  if (!text) return "undefined";
  return text.length > length ? text.slice(0, length) + "..." : text;
}
