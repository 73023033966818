import { Box, Typography } from "@mui/material";
import learning from "@assets/images/Learning-bro.png";
import { COLORS } from "@/constants/Colors/colors";
import { useSelector } from "react-redux";

export default function Introduction() {
  const globalState = useSelector((state) => state?.global);

  return (
    <>
      <Box position={"relative"} sx={{ overflowX: "hidden", color: "white" }}>
        <div
          style={
            window.innerWidth > 600
              ? { display: "flex", width: "100%" }
              : {
                  display: "flex",
                  flexDirection: "column-reverse",
                  width: "100%",
                }
          }
        >
          <Box
            sx={
              window.innerWidth > 600
                ? {
                    width: "60%",
                    bgcolor: COLORS.SECONDARY.MAIN,
                    padding: "40px",
                  }
                : { bgcolor: COLORS.SECONDARY.MAIN, padding: "10px" }
            }
          >
            <Typography fontSize={window.innerWidth > 600 ? 60 : 40} mb={6}>
              مرحبًا بك في  Trucks Diagnosis
            </Typography>
            <Typography
              fontSize={window.innerWidth > 600 ? 18 : 16}
              lineHeight={2.5}
            >شرح بأسلوب علمى و عملى عن كيفية استخدام الاجهزة المتطورة فى كشف اعطال و برمجة الشاحنات الحديثة
            و ذلك بأسلوب سهل و بسيط يناسب جميع الفئات المهتمه بهذا المجال
            </Typography>
          </Box>
          <Box
            sx={
              window.innerWidth > 600
                ? {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "40%",
                    bgcolor: globalState?.isDarkMode
                      ? COLORS.SOFT_DARK
                      : "white",
                  }
                : {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: globalState?.isDarkMode
                      ? COLORS.SOFT_DARK
                      : "white",
                  }
            }
          >
            <img src={learning} width={window.innerWidth > 600 ? 400 : 300} />
          </Box>
        </div>
      </Box>
    </>
  );
}
