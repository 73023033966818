import { initialSwapState } from "./utils";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import { useSelector } from "react-redux";

export const ChangeOrderButton = ({ state, setState, isLoading }) => {
  const language = useSelector((state) => state?.global);
  const lang = language?.lang;
  return (
    <ColorButton
      variant="contained"
      sx={{
        color: state.active ? "#2F8BC8" : "#fff",
        bgcolor: state.active ? "#fff" : "#2F8BC8",
        "&:hover": {
          color: state.active ? "#2F8BC8" : "#fff",
          bgcolor: state.active ? "#fff" : "#2F8BC8",
        },
      }}
      disabled={isLoading}
      onClick={() => {
        if (!state.active) {
          setState((s) => {
            return { ...s, active: true };
          });
        } else {
          setState(initialSwapState);
        }
      }}
    >
      {lang == "ar" ? "تغيير الترتيب" : "Change the order"}
    </ColorButton>
  );
};
