import CryptoJS from "crypto-js";
import { USER_TOKEN } from "@/constants/tokens";

const SECRETE_KEY = "EYAD_ELKREEZA";

export function encrypt(data) {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRETE_KEY).toString();
}

export function decrypt(data) {
  if (!data) return null;
  return JSON.parse(
    CryptoJS.AES.decrypt(data, SECRETE_KEY).toString(CryptoJS.enc.Utf8)
  );
}

export function currentUser() {
  return decrypt(localStorage.getItem(USER_TOKEN));
}

export function updateCurrentUser(data) {
  window.localStorage.setItem(
    USER_TOKEN,
    encrypt({ ...currentUser(), ...data })
  );
}
