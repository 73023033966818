import useCustomQuery from "@/hooks/useCustomQuery";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const UserSubsContext = createContext();

export const useUserSubsContext = () => {
  return useContext(UserSubsContext);
};

export const UserSubsContextProvider = ({ children }) => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const [page, setPage] = useState(1);
  const [ids, setIds] = useState([]);
  const { data, isPending, isSuccess } = useCustomQuery({
    key: ["mySubscription", page, lang],
    url: `subscription/getUserSubscriptions?page=${page}`,
    lang,
    select: (data) => data,
  });
  useEffect(() => {
    if (isSuccess) {
      setIds(
        ids.concat(data?.data?.data?.data?.map((item) => item?.course_id))
      );
    }
  }, [isSuccess]);

  // You can include additional state or functions related to the context here

  const contextValue = {
    data,
    isPending,
    ids: ids.concat(data?.data?.data?.data?.map((item) => item?.course_id)),
    setPage,
    page,
    totalPages: data?.data?.data?.last_page,
    // Add any other values or functions you want to expose
  };

  return (
    <UserSubsContext.Provider value={contextValue}>
      {children}
    </UserSubsContext.Provider>
  );
};
