import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { COLORS } from "@/constants/Colors/colors";
import CloseIcon from "@mui/icons-material/Close";
import useCustomMutation from "@/hooks/useCustomMutation";
import { notifySuccess } from "@shared/Toast/toast";
import { useQueryClient } from "@tanstack/react-query";
import { HashLoader } from "react-spinners";

export const RemoveStudentSubscription = ({ id }) => {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const { mutate, isPending } = useCustomMutation({
    url: `subscription/deleteUserSubscriptions?subscription_id=${id}`,
    onSuccess: () => {
      setOpen(false);
      queryClient.invalidateQueries(["course"]);
      notifySuccess("تم حذف اشتراك الطالب بنجاح");
    },
    method: "delete",
  });
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [open]);
  return (
    <>
      <IconButton onClick={() => setOpen(true)} sx={{ bgcolor: COLORS.DANGER }}>
        <DeleteIcon fontSize="small" />
      </IconButton>
      {open && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100vh",
            position: "fixed",
            top: "0",
            left: "0",
            zIndex: 10000,
            bgcolor: "#11111177",
          }}
        >
          <Box
            sx={{
              width: 500,
              height: 300,
              borderRadius: 3,
              bgcolor: COLORS.WHITE,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 5,
            }}
          >
            <Typography fontSize={30} fontFamily={"Noor-bold"}>
              هل تريد حذف اشتراك الطالب
            </Typography>
            <Box sx={{ display: "flex", aligmItems: "center", gap: 5 }}>
              <Button
                disabled={isPending}
                onClick={() => mutate()}
                sx={{
                  color: COLORS.WHITE,
                  bgcolor: COLORS.DANGER,
                  "&:hover": { bgcolor: COLORS.DANGER },
                }}
              >
                {isPending ? (
                  <HashLoader size={20} color={COLORS.WHITE} />
                ) : (
                  "حذف"
                )}
              </Button>
              <Button
                onClick={() => setOpen(false)}
                sx={{
                  color: COLORS.WHITE,
                  bgcolor: COLORS.PRIMARY.MAIN,
                  "&:hover": { color: COLORS.PRIMARY.MAIN },
                }}
              >
                الغاء
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
