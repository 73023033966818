import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PropTypes from "prop-types";

export default function SelectBox({ label, options, id, name, ...props }) {
  return (
    <div>
      <FormControl variant="standard" sx={{ minWidth: 80, zIndex: 9999 }}>
        <InputLabel sx={{}} id="demo-simple-select-standard-label">
          {label}
        </InputLabel>
        <Select
          sx={{
            height: 50,
          }}
          labelId="demo-simple-select-standard-label"
          id={id}
          name={name}
          autoWidth
          label={label}
          {...props}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {options.map((option) => {
            return (
              <MenuItem key={option?.value} value={option?.value}>
                {option?.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}

SelectBox.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.any,
};
