import { Box } from "@mui/material";

export default function Row(props) {
  const { children, gap, spacing, align, sx, ...rest } = props;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: spacing || "center",
        alignItems: align || "center",
        gap: gap || 0,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}
