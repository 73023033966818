import { useEffect, useState } from "react";

import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import { Box } from "@mui/material";

export default function AreaChartComp({ data, color }) {
  const [numbersData, setNumbersData] = useState();
  const [labels, setLabels] = useState();

  useEffect(() => {
    const extractedValues = data?.map((item) => item?.value);
    const extractedLabels = data?.map((item) => item?.label);
    setNumbersData(extractedValues);
    setLabels(extractedLabels);
  }, [data]);

  const [state, setState] = useState({
    // options: {
    chart: {
      width: 800,
      type: "donut",
      dropShadow: {
        enabled: true,
        color: "#111",
        top: -1,
        left: 3,
        blur: 3,
        opacity: 0.2,
      },
    },
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
            },
          },
        },
      },
    },
    labels: labels,
    dataLabels: {
      dropShadow: {
        blur: 3,
        opacity: 0.8,
      },
    },
    fill: {
      type: "pattern",
      opacity: 1,
      pattern: {
        enabled: true,
        style: [
          "verticalLines",
          "squares",
          "horizontalLines",
          "circles",
          "slantedLines",
        ],
      },
    },
    states: {
      hover: {
        filter: "none",
      },
    },
    theme: {
      palette: "palette2",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });
  useEffect(() => {
    setState((prev) => {
      return {
        ...prev,
        labels,
      };
    });
  }, [labels]);

  return (
    <Box sx={{ width: "90%", display: "flex", justifyContent: "center" }}>
      <ReactApexChart
        options={state}
        series={numbersData}
        type="donut"
        width={480}
      />
    </Box>
  );
}

AreaChartComp.propTypes = {
  data: PropTypes.array,
  color: PropTypes.string,
};
