export const formatter = new Intl.NumberFormat("en", {
  notation: "compact",
  maximumFractionDigits: 2,
});

export function formatMonthDayAndHours(dateString) {
  const date = new Date(dateString);
  const month = date.toLocaleDateString(undefined, { month: "short" });
  const day = date.getDate();
  const hours = date.getHours();
  return `${month} ${day}, ${hours}h`;
}
