import useCustomMutation from "@/hooks/useCustomMutation";
import { queryClient } from "@config/queryClient.config";
import { notifyError, notifySuccess } from "@shared/Toast/toast";

export default function useDeleteVideo({ id, onSettled, lang }) {
  const { mutate: deleteVideoMutation, isPending: isLoading } =
    useCustomMutation({
      url: `lesson/delete?id=${id}`,
      invalidate: ["lesson", id],
      method: "get",
      onSettled,
      lang,
      onSuccess: () => {
        notifySuccess(
          lang == "ar"
            ? "تم حذف الفيديو بنجاح"
            : "The video has been successfully deleted"
        );
        queryClient.invalidateQueries(["lesson", id]);
      },
      onError: (error) => {
        notifyError(error?.response?.data?.message);
      },
    });

  function deleteVideo() {
    deleteVideoMutation();
  }

  return { deleteVideo, isLoading };
}
