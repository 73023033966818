import { COLORS } from "@/constants/Colors/colors";
import { Box, Typography, Card, CardMedia } from "@mui/material";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

export default function CourseCard({
  name,
  teacher,
  price,
  img,
  type,
  link,
  width,
  description,
  bought,
}) {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  return (
    <>
      <Card
        sx={{
          marginBottom: 2,
          height: 425,
          overflow: "hidden",
          width: width,
          borderRadius: "20px",
          position: "relative",
          bgcolor: globalState?.isDarkMode
            ? COLORS.DARK_GRAY
            : COLORS.PRIMARY.MAIN,
          "&:hover > div": {
            transform: "translateY(-270px)",
          },
          transitionDuration: "0.5s",
          transitionProperty: "transform",
        }}
      >
        <Box
          sx={{
            transitionDuration: "0.5s",
            transitionProperty: "transform",
          }}
        >
          <CardMedia
            sx={{
              height: "346px",
              backgroundPosition: "center",
              backgroundSize: "contain",
              bgcolor: globalState?.isDarkMode
                ? COLORS.SOFT_DARK
                : COLORS.WHITE,
            }}
            image={img}
            title="course"
          />
          <Box
            mt={1}
            mb={5}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            px={2}
            bgcolor={
              globalState?.isDarkMode ? COLORS.SOFT_DARK : COLORS.PRIMARY.MAIN
            }
            sx={{ width: "100%" }}
          >
            <Box
              color={"white"}
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                with: "100%",
                alignItems: "space-between",
              }}
            >
              {/* <Typography fontFamily={"Noor-bold"} fontWeight={'bold'}>{name}</Typography> */}
              <Typography
                fontFamily={"Noor-bold"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  // alignItems: lang == "ar" ? "start" : "end",
                }}
              >
                {name}
              </Typography>
              <Typography
                fontFamily={"Noor-bold"}
                fontSize={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  // alignItems: lang == "ar" ? "start" : "end",
                }}
              >
                {description}
              </Typography>
            </Box>
            {!bought && (
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                color={"white"}
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  with: "100%",
                  alignItems: "end",
                  textAlign: "center",
                }}
              >
                <Typography
                  fontSize={18}
                  fontFamily={"Noor-bold"}
                  // color={color}
                  mb={-0.5}
                >
                  {price == 0 ? (lang == "ar" ? "مجاني" : "Free") : `${price}`}
                </Typography>
                {price != 0 && (
                  <Typography
                    fontSize={18}
                    fontFamily={"Noor-bold"}
                    // color={color}
                  >
                    {lang == "ar" ? "جنيه" : "EGP"}
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            backgroundColor: COLORS.PRIMARY.MAIN,
            width: "100%",
            height: "440px",
            transitionDuration: "0.5s",
            transitionProperty: "transform",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link
            to={link}
            style={{
              textDecoration: "none",
              background: globalState?.isDarkMode
                ? COLORS.SOFT_DARK
                : COLORS.WHITE,
              padding: "7px 20px",
              marginBottom: "20px",
              borderRadius: "5px",
            }}
          >
            <Typography
              fontSize={24}
              fontFamily={"Noor-bold"}
              color={
                globalState?.isDarkMode ? COLORS.WHITE : COLORS.PRIMARY.MAIN
              }
            >
              {bought
                ? lang == "ar"
                  ? "استكمال الكورس"
                  : "Complete the course"
                : type === "بالكورس"
                ? lang == "ar"
                  ? "احجز الكورس"
                  : "Book the course"
                : lang == "ar"
                ? "اشترك في حصة"
                : "Subscribe to share"}
            </Typography>
          </Link>
        </Box>
      </Card>
    </>
  );
}
CourseCard.propTypes = {
  name: PropTypes.string,
  price: PropTypes.string,
  img: PropTypes.string,
  type: PropTypes.string,
  link: PropTypes.string,
  width: PropTypes.string,
  description: PropTypes.string,
  bought: PropTypes.bool,
};
