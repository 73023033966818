import { COLORS } from "@/constants/Colors/colors";
import { Box, Typography, IconButton } from "@mui/material";
import useCustomQuery from "@/hooks/useCustomQuery";
import { useParams } from "react-router-dom";
import { HashLoader } from "react-spinners";
import ModuleDetailsCollapse from "../../../Admin/Pages/Course/CourseModules/ModuleDetailsCollapse/ModuleDetailsCollapse";
import { AddModule } from "../../../Admin/Pages/Course/CourseModules/AddModule/AddModule";
import useSwapOrder from "../../../hooks/useSwapOrder";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useSelector } from "react-redux";

export const CourseModules = ({ course }) => {
  const lang = useSelector((state) => state?.global?.lang);
  // --------------- hooks ---------------
  const { id } = useParams();
  const { data: courseModules, isPending } = useCustomQuery({
    url: `course/${+id}`,
    key: ["course modules", id, lang],
    enabled: !isNaN(+id),
    lang,
  });

  const { mutate: swapOrder, isLoading: isSwapOrderLoading } = useSwapOrder();

  if (isPending) {
    return (
      <Box
        sx={{
          width: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <HashLoader color={COLORS.PRIMARY.MAIN} />
      </Box>
    );
  }

  return (
    <Box sx={{ width: "50%", minHeight: 400, paddingY: 2 }}>
      <AddModule price={courseModules?.price} />
      {courseModules?.modules?.length === 0 ? (
        <Typography
          sx={{
            height: 300,
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
          fontFamily={"Noor-bold"}
          textAlign={"center"}
          fontSize={30}
          color={COLORS.DARK_GRAY}
        >
          {lang == "ar" ? "  لم يتم إضافة حصص بعد" : "No classes added yet."}
        </Typography>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {courseModules?.modules.map((module, i) => (
            <Box
              key={module?.id}
              sx={{
                display: "flex",
                alignItems: "start",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: "10%",
                }}
              >
                {i !== 0 ? (
                  <IconButton
                    disabled={isSwapOrderLoading}
                    onClick={() =>
                      swapOrder({
                        first_lesson_module_id:
                          courseModules?.modules[i - 1]?.id,
                        second_lesson_module_id: module?.id,
                      })
                    }
                    sx={{
                      padding: 0.5,
                      margin: 0,
                    }}
                  >
                    <ArrowDropUpIcon />
                  </IconButton>
                ) : null}

                {i !== courseModules?.modules?.length - 1 ? (
                  <IconButton
                    disabled={isSwapOrderLoading}
                    onClick={() =>
                      swapOrder({
                        first_lesson_module_id: module?.id,
                        second_lesson_module_id:
                          courseModules?.modules[i + 1]?.id,
                      })
                    }
                    sx={{
                      padding: 0.5,
                      margin: 0,
                    }}
                  >
                    <ArrowDropDownIcon />
                  </IconButton>
                ) : null}
              </Box>
              <ModuleDetailsCollapse
                course={course}
                module={module}
                index={i}
              />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
