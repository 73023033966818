import { useEffect, useState } from "react";
import { decrypt } from "../../Helpers/encryptionHelpers";
export default function useLocalStorageToken() {
  const [token, setToken] = useState(
    decrypt(localStorage.getItem("mark-lms-user"))
  );

  useEffect(() => {
    function getStorageToken() {
      setToken(decrypt(localStorage.getItem("mark-lms-user")));
      window.dispatchEvent(new Event("storage"));
    }

    window.addEventListener("storage", getStorageToken);
    return () => {
      window.removeEventListener("storage", getStorageToken);
    };
  }, []);

  return token;
}
