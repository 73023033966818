import {
  Box,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ROLES } from "@/constants/roles";
import DeleteIcon from "@mui/icons-material/Delete";
import useLocalStorageToken from "@shared/Helpers/axios/useLocalStorageToken";
import { useRecoilState } from "recoil";
import { activeCodeAtom } from "@/modules/codes/services/codeAtoms/RecoilCourseTypeAtom";
import useDeleteCode from "@/hooks/useDeleteCode";
import Modal from "@shared/Modal/Modal";
import { FONTS } from "@/constants/Fonts/fonts";
import { FONT_SIZES } from "@/constants/Fonts/font_sizes";
import Row from "../Row/Row";
import ColorButton from "@/components/Buttons/ColorButton/ColorButton";
import dayjs from "dayjs";
import { useSelector } from 'react-redux';

export default function CustomTableRowCodes({
  studentNumber,
  index,
  code,
  createdAt,
  dateActivated,
  expiresAt,
  days,
  banned_until,
  codeData,
  handleCourseCodeDetailsOpen,
  refetch,
}) {
 const lang = useSelector((state) => state?.global?.lang);
  const User = useLocalStorageToken();
  const [activeCode, setActiveCode] = useRecoilState(activeCodeAtom);
  const [open, setOpen] = useState(false);

  function DeleteCode({ courseId }) {
    const { deleteCode, isPending } = useDeleteCode({
      id: courseId,
      onSettled: () => {
        setOpen(false);
        refetch();
        setActiveCode(null);
      },
      lang,
    });

    return (
      <Modal open={open} closeFn={() => setOpen(false)} maxWidth={"sm"}>
        <Typography fontFamily={FONTS.BOLD} fontSize={FONT_SIZES.LARGE}>
          {lang == "ar"
            ? "هل انت متأكد من حذف الكود؟"
            : "Are you sure to delete the code?"}
        </Typography>
        <Row mt={2} gap={2}>
          <ColorButton
            onClick={() => deleteCode()}
            isLoading={isPending}
            colorType="danger"
            width="100px"
          >
            {lang == "ar" ? "حذف" : "Delete"}
          </ColorButton>
          <ColorButton
            colorType="secondary"
            width="100px"
            onClick={() => setOpen(false)}
          >
            {lang == "ar" ? "الغاء" : "Cancel"}
          </ColorButton>
        </Row>
      </Modal>
    );
  }
  const formattedActiveDate = dayjs(dateActivated).isValid()
    ? dayjs(dateActivated).format("YYYY-MM-DD HH h")
    : "-";
  const formattedCreateAtDate = dayjs(createdAt).isValid()
    ? dayjs(createdAt).format("YYYY-MM-DD HH : M")
    : "-";
  const formattedExpireDate = dayjs(expiresAt).isValid()
    ? dayjs(expiresAt).format(`YYYY-MM-DD HH : M`)
    : "-";
  console.log(typeof updateDate);
  return (
    <TableBody>
      <DeleteCode courseId={activeCode?.id} />

      <TableRow
        sx={{ cursor: "pointer" }}
        onClick={() => {
          setActiveCode(codeData);
          handleCourseCodeDetailsOpen(codeData);
        }}
      >
        <TableCell align="center">{studentNumber}</TableCell>
        <TableCell align="center" component="th" scope="row">
          <div>{code}</div>
        </TableCell>
        <TableCell align="center">{formattedCreateAtDate}</TableCell>
        <TableCell align="center">{formattedActiveDate}</TableCell>
        <TableCell align="center">{formattedExpireDate}</TableCell>
        <TableCell align="center">{days}</TableCell>

        <TableCell align="center">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {ROLES.ADMIN === User?.role && (
              <>
                <IconButton
                  onClick={() => {
                    setOpen(true);
                  }}
                  className="settingIcon"
                  aria-label="delete"
                  size="small"
                  sx={{
                    bgcolor: "#db3a3c",
                    position: "relative",
                    "&:hover": {
                      bgcolor: "#db3a3c",
                    },
                  }}
                >
                  <span className="settingHover">
                    {lang == "ar" ? "حذف" : "Delete"}
                  </span>
                  <DeleteIcon fontSize="inherit" sx={{ color: "#fff" }} />
                </IconButton>
              </>
            )}
          </Box>
        </TableCell>
      </TableRow>
    </TableBody>
  );
}
