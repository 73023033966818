import { COLORS } from "@/constants/Colors/colors";
import { Box, Button, TextField, InputAdornment } from "@mui/material";
import useCustomMutation from "@/hooks/useCustomMutation";
import { notifySuccess } from "@shared/Toast/toast";
import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { HashLoader } from "react-spinners";
import { useSelector } from "react-redux";

export const EditCourseHeader = ({ id, setEditing, course }) => {
  const language = useSelector((state) => state?.global);
  const lang = language?.lang;
  const [submitted, setSubmitted] = useState(false);
  const [courseData, setCourseData] = useState({
    name: course?.name,
    description: course?.description,
    price: +course?.price,
  });
  const queryClient = useQueryClient();
  const { mutate, isPending } = useCustomMutation({
    url: `course/update`,
    onSuccess: () => {
      notifySuccess(
        lang == "ar" ? "تم تعديل الكورس بنجاح" : "Edit course succefully"
      );
      setEditing(false);
      queryClient.invalidateQueries(["course", "courses", lang]);
    },
  });
  const submit = () => {
    if (!courseData.name) return;
    if (!courseData.description) return;
    if (!courseData.price && courseData.price != 0) return;
    mutate({
      ...courseData,
      id: id,
    });
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
        flexDirection: "column",
        width: "40%",
      }}
    >
      <TextField
        type="text"
        label={lang == "ar" ? "الإسم" : "Name"}
        value={courseData?.name}
        onChange={(e) => {
          setCourseData({ ...courseData, name: e.target.value });
        }}
        error={!courseData.name && submitted}
        helperText={!courseData.name && submitted && "يجب إضافة اسم للكورس"}
        fullWidth
        sx={{
          color: COLORS.WHITE,
          "& .MuiFormLabel-root.Mui-focused": {
            color: "#2f8bc8",
          },
          "& .MuiOutlinedInput-root": {
            color: COLORS.WHITE,
            "& .Mui-focused": {
              borderColor: "#2f8bc8",
              color: "white", // Border color when focused
            },
            "&:hover fieldset": {
              borderColor: COLORS.WHITE, // Border color on hover
            },
          },
          "& label": {
            left: lang == "ar" ? "unset" : "0",
            right: lang == "ar" ? "1.75rem" : "unset",
            transformOrigin: "right",
            fontSize: "0.8rem",
            color: "#2f8bc8",
            bgcolor: COLORS.DARK_GRAY,
          },
          "& legend": {
            textAlign: lang == "ar" ? "right" : "left",
            fontSize: "0.8rem",
          },
          direction: lang == "ar" ? "rtl" : "ltr",
        }}
        InputProps={{
          startAdornment: <InputAdornment position="end"></InputAdornment>,
        }}
      />
      <TextField
        type="text"
        label={lang == "ar" ? "الوصف" : "Description"}
        value={courseData?.description}
        onChange={(e) => {
          setCourseData({ ...courseData, description: e.target.value });
        }}
        error={!courseData.description && submitted}
        helperText={
          !courseData.description && submitted && "يجب إضافة وصف للكورس"
        }
        fullWidth
        sx={{
          color: COLORS.WHITE,
          "& .MuiFormLabel-root.Mui-focused": {
            color: "#2f8bc8",
          },
          "& .MuiOutlinedInput-root": {
            color: COLORS.WHITE,
            "& .Mui-focused": {
              borderColor: "#2f8bc8",
              color: "white", // Border color when focused
            },
            "&:hover fieldset": {
              borderColor: COLORS.WHITE, // Border color on hover
            },
          },
          "& label": {
            left: lang == "ar" ? "unset" : "0",
            right: lang == "ar" ? "1.75rem" : "unset",
            transformOrigin: "right",
            fontSize: "0.8rem",
            color: "#2f8bc8",
            bgcolor: COLORS.DARK_GRAY,
          },
          "& legend": {
            textAlign: lang == "ar" ? "right" : "left",
            fontSize: "0.8rem",
          },
          direction: "rtl",
        }}
        InputProps={{
          startAdornment: <InputAdornment position="end"></InputAdornment>,
        }}
      />
      <TextField
        type="number"
        label={lang == "ar" ? "السعر" : "Price"}
        value={courseData?.price}
        onChange={(e) => {
          if (e.target.value >= 0) {
            setCourseData({ ...courseData, price: e.target.value });
          }
        }}
        error={!courseData.price && submitted}
        helperText={!courseData.price && submitted && "يجب إضافة سعر للكورس"}
        fullWidth
        sx={{
          color: COLORS.WHITE,
          "& .MuiFormLabel-root.Mui-focused": {
            color: "#2f8bc8",
          },
          "& .MuiOutlinedInput-root": {
            color: COLORS.WHITE,
            "& .Mui-focused": {
              borderColor: "#2f8bc8",
              color: "white", // Border color when focused
            },
            "&:hover fieldset": {
              borderColor: COLORS.WHITE, // Border color on hover
            },
          },
          "& label": {
            left: lang == "ar" ? "unset" : "0",
            right: lang == "ar" ? "1.75rem" : "unset",
            transformOrigin: "right",
            fontSize: "0.8rem",
            color: "#2f8bc8",
            bgcolor: COLORS.DARK_GRAY,
          },
          "& legend": {
            textAlign: lang == "ar" ? "right" : "left",
            fontSize: "0.8rem",
          },
          direction: "rtl",
        }}
        InputProps={{
          startAdornment: <InputAdornment position="end"></InputAdornment>,
        }}
      />
      <Box display={"flex"} gap={2}>
        <Button
          sx={{
            color: COLORS.WHITE,
            bgcolor: COLORS.PRIMARY.MAIN,
            "&:hover": { bgcolor: COLORS.PRIMARY.MAIN },
          }}
          onClick={() => {
            setSubmitted(true);
            submit();
          }}
        >
          {isPending ? (
            <HashLoader size={20} color={COLORS.WHITE} />
          ) : lang == "ar" ? (
            "تعديل"
          ) : (
            "Edit"
          )}
        </Button>
        <Button
          sx={{
            color: COLORS.WHITE,
            bgcolor: COLORS.PRIMARY.MAIN,
            "&:hover": { bgcolor: COLORS.PRIMARY.MAIN },
          }}
          onClick={() =>
            setCourseData({
              name: course?.name,
              description: course?.description,
              price: +course.price,
            })
          }
        >
          {lang == "ar" ? "استعادة" : "Resset"}
        </Button>
        <Button
          sx={{
            color: COLORS.WHITE,
            bgcolor: COLORS.PRIMARY.MAIN,
            "&:hover": { bgcolor: COLORS.PRIMARY.MAIN },
          }}
          onClick={() => setEditing(false)}
        >
          {lang == "ar" ? " الغاء" : "Cancel"}
        </Button>
      </Box>
    </Box>
  );
};
