import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Typography,
} from "@mui/material";
import { COLORS } from "@/constants/Colors/colors";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const TopStudents = ({ data }) => {
  const navigate = useNavigate();
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;

  return (
    <Box
      sx={{
        width: "100%",
        padding: "20px",
        bgcolor: globalState?.isDarkMode ? COLORS.SOFT_DARK : COLORS.WHITE,
        fontFamily: "Noor-regular",
        borderRadius: 2,
        boxShadow: "0 0 8px rgba(0,0,0,0.1)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          mb: 2,
        }}
      >
        <Typography
          sx={{
            fontSize: "28px",
            fontFamily: "Noor-regular",
            color: COLORS.PRIMARY.MAIN,
          }}
        >
          {lang == "ar" ? "الطلاب الجدد" : "New students"}
        </Typography>
        <Button
          onClick={() => {
            navigate("students");
          }}
          sx={{
            fontFamily: "Noor-regular",
            color: "white",
            bgcolor: COLORS.PRIMARY.MAIN,
            fontSize: "18px",
            "&:hover": { bgcolor: COLORS.SECONDARY.MAIN },
          }}
        >
          {lang == "ar" ? "عرض المزيد" : "Show more"}
        </Button>
      </Box>
      <Table>
        <TableHead>
          <TableRow
            sx={{
              display: "flex",

              justifyContent: "space-evenly",
              width: "100%",
            }}
          >
            <TableCell sx={{ flex: 0.1, textAlign: lang=="ar" ?"right":"left" }}>#</TableCell>
            <TableCell sx={{ flex: 2, textAlign: lang=="ar" ?"right":"left" }}>
              {lang == "ar" ? "اسم الطالب" : "Student name"}
            </TableCell>
            <TableCell sx={{ flex: 1, textAlign: lang=="ar" ?"right":"left" }}>
              {lang == "ar" ? "رقم التليفون" : "Phone number"}
            </TableCell>
            <TableCell sx={{ flex: 1, textAlign: lang=="ar" ?"right":"left" }}>
              {lang == "ar" ? "الايميل" : "Email"}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item, index) => {
            return (
              <TableRow
                key={item?.id}
                sx={{
                  display: "flex",

                  justifyContent: "space-evenly",
                  width: "100%",
                }}
              >
                <TableCell sx={{ flex: 0.1, textAlign: lang=="ar"? "right":"left" }}>
                  {index + 1}
                </TableCell>
                <TableCell sx={{ flex: 2, textAlign: lang=="ar"? "right":"left" }}>
                  {item?.name}
                </TableCell>
                <TableCell sx={{ flex: 1, textAlign: lang=="ar"? "right":"left" }}>
                  {item?.phone}
                </TableCell>
                <TableCell sx={{ flex: 1, textAlign: lang=="ar"? "right":"left" }}>
                  {item?.email}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

TopStudents.propTypes = {
  data: PropTypes.array,
};
