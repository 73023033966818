import { api_url } from "@/constants/base_url";
import axios from "axios";
import useLocalStorageToken from "./useLocalStorageToken";
export default function useAxiosConfig(lang = "ar") {
  const token = useLocalStorageToken();
  const instance = axios.create({ baseURL: api_url });
  instance.defaults.headers.common["Authorization"] = `Bearer ${token?.token}`;
  instance.defaults.headers.common["lang"] = lang;
  return instance;
}
