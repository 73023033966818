export function dropzoneConfig({ setValue, setImageSrc, clearErrors }) {
  return {
    maxFiles: 1,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg"],
    },
    onDrop: (acceptedFile) => {
      setValue("image", acceptedFile);
      clearErrors("image");
      setImageSrc({
        url: URL?.createObjectURL(acceptedFile[0]),
        file: acceptedFile[0],
      });
    },
  };
}
