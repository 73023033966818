import { Grid, Skeleton } from "@mui/material";

export default function CustomGrid(props) {
  const { children, spacing = 1, isLoading, childCols = 4, ...rest } = props;

  return (
    <Grid container spacing={spacing} {...rest}>
      {isLoading
        ? Array(3)
            .fill("_")
            .map((_, index) => (
              <Grid key={index} item xs={childCols} height={"40vh"}>
                <Skeleton variant="rounded" width={"100%"} height={"100%"} />
              </Grid>
            ))
        : children.map((child, index) => (
            <Grid key={child?.id || index} item xs={childCols}>
              {child}
            </Grid>
          ))}
    </Grid>
  );
}
