import { z } from "zod";

export const editVideoSchema = z.object({
    name: z.string().min(1, "اسم الفيديو مطلوب"),
    link: z
      .string()
      .min(1, "لينك الفيديو مطلوب")
      .url("اللينك غير صحيح")
      .optional(),
  });
 export const newVideoSchema = z.object({
    name: z.string().min(1, "اسم الفيديو مطلوب"),
    link: z
      .string()
      .min(1, "لينك الفيديو مطلوب")
      .url("اللينك غير صحيح")
      .optional(),
  });
  