import { ClipLoader } from "react-spinners";
import { ColorButton } from "../ColorButton";
import { useSelector } from 'react-redux';

export const ConfirmSwapBtn = ({ state, isLoading, swapFn }) => {
  const language = useSelector((state) => state?.global);
  const lang = language?.lang;
  return (
    state?.first &&
    state?.second && (
      <ColorButton disabled={isLoading} onClick={swapFn}>
        {isLoading ? (
          <ClipLoader color="#fff" size={20} />
        ) : lang == "ar" ? (
          "تأكيد"
        ) : (
          "Confirm"
        )}
      </ColorButton>
    )
  );
};
