import * as zod from "zod";

const addFileSchema = zod.object({
  name: zod.string().refine({
    message: "يجب ادخال اسم الملف",
    async validate(value) {
      return /^[a-zA-Z\s\u0621-\u064A]+$/.test(value);
    },
  }),
  file: zod.string().refine({
    message: "يجب رفع الملف اولا",
    async validate(value) {
      return isGoogleDriveLink(value);
    },
  }),
});

function isGoogleDriveLink(value) {
  const googleDriveRegex =
    /^https?:\/\/drive\.google\.com\/(?:file\/d\/|open\?id=)([\w-]+)(?:\/.+)?$/;
  return googleDriveRegex.test(value);
}

export default addFileSchema;
