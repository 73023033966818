import { Skeleton, TableBody, TableCell, TableRow, Typography } from "@mui/material"
import React from "react"


export default function CustomTableLoading({
  width,
  height,
  numOfCells
}) {

  return (
    <TableBody>
      <TableRow>
        <TableCell
          colSpan={numOfCells}
          style={{ textAlign: "center", padding: "0px  20px" }}
        >
          <Skeleton height={height} width={width} />
        </TableCell>
      </TableRow>
    </TableBody>
  )
}