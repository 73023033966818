import useCustomMutation from "@/hooks/useCustomMutation";
import { queryClient } from "@config/queryClient.config";
import { notifyError, notifySuccess } from "@shared/Toast/toast";

export default function useDeleteExam({ id, onSettled, lang }) {
  const { mutate: deleteExamMutation, isPending: isExamLoading } =
    useCustomMutation({
      url: `exam/deleteExam?exam_id=${id}`,
      invalidate: ["module exam", id],
      method: "delete",
      onSettled,
      lang,
      onSuccess: () => {
        notifySuccess(
          lang == "ar"
            ? "تم حذف الامتحان بنجاح"
            : "The Exam has been successfully deleted"
        );
        queryClient.invalidateQueries(["module exam", id]);
      },
      onError: (error) => {
        notifyError(error?.response?.data?.message);
      },
    });

  function deleteExam() {
    deleteExamMutation();
  }

  return { deleteExam, isExamLoading };
}
