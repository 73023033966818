import { z } from "zod";

export const createCourseSchema = z.object({
  name: z.string().min(1, "اسم الكورس مطلوب"),
  description: z.string().min(1, "وصف الكورس مطلوب"),
  duration: z.coerce.number().min(1, "مدة الكورس مطلوبة"),
  price: z.string().min(1, "سعر الكورس مطلوب"),
  subscription_days: z.coerce
    .number()
    .transform((val) => {
      return parseInt(val);
    })
    .refine((val) => Number(val) > 0, {
      message: "ايام الاشتراك يجب ان تكون اكبر من الصفر",
    })
    .optional(),
  image: z
    .array(z.instanceof(File), {
      required_error: "صورة الكورس مطلوبة",
    })
    .nonempty("صورة الكورس مطلوبة"),
});
