import useCustomMutation from "@/hooks/useCustomMutation";
import { notifySuccess } from "@shared/Toast/toast";
import { useQueryClient } from "@tanstack/react-query";

export default function useAddVideo({ lang, onSuccess }) {
  const queryClient = useQueryClient();
  const { mutate, isPending, refetch } = useCustomMutation({
    url: "lesson/store",
    invalidate: ["lesson"],
    method: "post",
    successMsg:
      lang == "ar"
        ? "تم اضافة الفيديو بنجاح"
        : "The video has been Added successfully ",
    notifySuccess:
      lang == "ar"
        ? "تم اضافة الفيديو بنجاح"
        : "The video has been Added successfully ",
    lang,
    // onSettled,
    onSuccess: () => {
      onSuccess();
      queryClient.invalidateQueries(["lesson"]);
    },
  });

  function addModuleVideo(data) {
    const formData = new FormData();
    Object.entries(data).forEach((key) => {
      formData.append(key[0], data[key[0]]);
    });

    mutate(formData);
  }

  return { addModuleVideo, isPending };
}
